<template>
  <main class="px-2 md:px-6 py-6 flex flex-col gap-5">
    <h4
      class="uppercase md:uppercase md:text-sm md:text-left text-xs font-bold text-headerText"
    >
      Tuckshop By Eazipay
    </h4>

    <div
      class="bg-white border border-newGrey3 rounded-4xl space-y-3 overflow-hidden"
    >
      <div class="p-4 md:p-6 space-y-4 md:space-y-6">
        <div class="flex-shrink-0 w-full">
          <img src="@/assets/img/tuck_web.png" alt="" />
        </div>

        <div class="flex flex-col gap-2 text-sm md:text-base">
          <h4 class="text-primary font-semibold uppercase">
            Welcome to tuckshop by eazipay
          </h4>
          <p class="">
            Imagine your employees shopping for groceries and essentials from
            the comfort of their desks, with flexible payment plans. Our
            Tuckshop offers competitive prices and convenient instalment
            options, making life easier for both employers and employees
          </p>
        </div>
      </div>

      <div class="tuck_shadow p-4 md:p-6 rounded-4xl space-y-6">
        <div class="flex items-center justify-between">
          <h1 class="text-xl md:text-2xl font-bold text-darkColor">
            How it Works
          </h1>
          <span class="text-xs">Terms and Conditions Apply</span>
        </div>

        <div class="flex flex-col md:flex-row items-center gap-4">
          <div
            v-for="(item, index) in steps"
            :key="item.title"
            :class="[
              index == 0
                ? 'step_shadow bg-highlight_2 border-transparent'
                : 'bg-newGrey3 border-lighterGray',
            ]"
            class="w-full md:w-auto p-4 rounded-3xl border space-y-2"
          >
            <div class="flex items-center gap-2">
              <img :src="item.icon" alt="" />
              <span class="font-bold text-lg">{{ item.title }}</span>
            </div>
            <p class="text-sm">{{ item.description }}</p>
          </div>
        </div>

        <div
          class="flex flex-wrap gap-4 items-center justify-center sm:justify-between"
        >
          <easiButton @click="openLink" class="gap-2">
            Get started now
            <i class="pi pi-arrow-right text-sm" />
          </easiButton>

          <div
            style="background: #f3f6f5"
            class="rounded-full text-sm flex items-center gap-2 pl-4"
          >
            <div class="truncate">{{ link }}</div>
            <button
              @click="copyText(link)"
              style="background: #cfdad8"
              class="px-6 py-3 rounded-full flex items-center gap-2"
            >
              <span class="text-primary"> Copy Link</span>
              <i class="pi pi-clone" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import step_1 from "@/assets/icons/step_1.svg";
import step_2 from "@/assets/icons/step_2.svg";
import step_3 from "@/assets/icons/step_3.svg";

import { helperFunctions } from "@/composable/helperFunctions";
const { copyText } = helperFunctions;
import { ref } from "vue";

const steps = ref([
  {
    title: "Step 1",
    description: `Copy the link or click the button below.`,
    icon: step_1,
  },
  {
    title: "Step 2",
    description: `Apply for your company or share with your employees.`,
    icon: step_2,
  },
  {
    title: "Step 3",
    description: `⁠⁠Orders are received from Friday to Tuesday and delivered within 48-72 hours.`,
    icon: step_3,
  },
]);

const link = ref("https://forms.gle/MfycpBubNvvb14yG7");

const openLink = () => {
  window.open(link.value);
};
</script>

<style scoped>
.tuck_shadow {
  box-shadow: 0px -9px 42px 0px #aaaaaa26;
}
.step_shadow {
  box-shadow: 0px 4px 8px 0px #aaaaaa14;
}
</style>
