<template>
  <easiRoundButton @click="handleGoBack" class="absolute z-20 top-4 left-4">
    <i class="pi pi-chevron-left text-sm" />
  </easiRoundButton>

  <div v-if="!isPreviewLoan" class="p-4 md:p-6 flex flex-col gap-5">
    <h4 class="text-center font-semibold mb-4">Get operational loan</h4>
    <div class="mt-4">
      <h2 class="font-semibold mb-1">
        {{ progress === 1 ? "Loan Information" : "Upload relevant documents" }}
      </h2>
      <LoanProgress :progress="progress" :length="2" />
    </div>
    <LoanInfo
      v-if="progress === 1"
      :data="args"
      :error="errorRules"
      @cancel="handleClose"
      @proceed="handleProceed"
    />

    <RelevantDocument
      v-if="progress === 2"
      :data="args"
      :error="errorRules"
      @back="progress = 1"
      @preview="handlePreview"
      :eligibilityData="eligibilityData"
      :loading="loading"
    />
  </div>

  <PreviewLoan
    v-else
    :isOpen="isPreviewLoan"
    @submit="submit"
    :data="previewData"
    type="operational"
    :loading="loading"
  />

  <Teleport to="#loan-success-alert">
    <easiAlert
      v-if="isAlert"
      :caption="`Your application has been received. 
    A member of our team will be in touch.`"
    >
      <div class="pb-4 px-6">
        <easiButton
          size="medium"
          variant="text"
          background="#11453B1A"
          @click="closeAlert"
          block
          >Dismiss!</easiButton
        >
      </div>
    </easiAlert>

    <easiLoading v-if="loading" />
  </Teleport>
</template>

<script setup>
import LoanProgress from "../LoanProgress.vue";
import LoanInfo from "./LoanInfo.vue";
import RelevantDocument from "./RelevantDocument.vue";
import PreviewLoan from "../PreviewLoan.vue";

import { previewLoan, createLoan } from "@/services/loan";
import { helperFunctions } from "@/composable/helperFunctions";

import { computed, ref, reactive, watch } from "vue";
const { formatDate, formatAmount, uploadFileToServer } = helperFunctions;
import { useToast } from "vue-toastification";
const toast = useToast();

const props = defineProps({
  eligibilityData: Object,
});
const emit = defineEmits(["close", "goBack"]);

const loading = ref(false);
const progress = ref(1);

const previewData = ref(null);
const isPreviewLoan = ref(false);
const isAlert = ref(false);

const uploadedDocs = ref({
  collateral: null,
  financialAudit: null,
  sixMonthsBankStatement: null,
});

const args = ref({
  loanAmount: "",
  duration: "",
  repaymentType: "",
  durationPeriod: "MONTH",
  purpose: "",
  documents: {
    collateral: null,
    financialAudit: null,
    sixMonthsBankStatement: null,
  },
  termsAndConditionsAccepted: true,
  type: "OPERATIONAL_LOANS",
});

const errorRules = reactive({
  loanAmount: false,
  duration: false,
  repaymentType: false,
  purpose: false,
  sixMonthsBankStatement: false,
  collateral: false,
  financialAudit: false,
});

const checkAmount = () => {
  const { minBorrowableAmount, eligibleAmount } = props.eligibilityData;

  if (args.value.loanAmount < minBorrowableAmount) {
    errorRules.loanAmount = `Amount must not be less than ${formatAmount(
      minBorrowableAmount
    )}`;
    return true;
  } else if (args.value.loanAmount > eligibleAmount) {
    errorRules.loanAmount = `Amount must not exceed ${formatAmount(
      eligibleAmount
    )}`;
    return false;
  }
  errorRules.loanAmount = false;
  return true;
};

watch(() => args.value.loanAmount, checkAmount);

const validateFields = () => {
  resetError();
  if (!checkAmount()) return false;
  if (!args.value.loanAmount) {
    errorRules.loanAmount = "Amount is required";
    return false;
  } else if (!args.value.duration) {
    errorRules.duration = "Duration  is required";
    return false;
  } else if (!args.value.repaymentType) {
    errorRules.repaymentType = "Repayment type is required";
    return false;
  } else if (
    !args.value.documents.sixMonthsBankStatement &&
    progress.value === 2
  ) {
    errorRules.sixMonthsBankStatement = "Bank statement  is required";
    return false;
  } else if (!args.value.documents.financialAudit && progress.value === 2) {
    errorRules.financialAudit = "Audited financial statement  is required";
    return false;
  } else if (!args.value.documents.collateral && progress.value === 2) {
    errorRules.collateral = "Collateral/Security cover  is required";
    return false;
  }

  resetError();
  return true;
};

function resetError() {
  Object.keys(errorRules).map((key) => {
    errorRules[key] = false;
  });
}

function clearFields() {
  args.value.loanAmount = "";
  args.value.duration = "";
  args.value.repaymentType = "";
  args.value.purpose = "";
  args.value.documents = {
    collateral: null,
    financialAudit: null,
    sixMonthsBankStatement: null,
  };
  args.value.termsAndConditionsAccepted = true;
}

function handleClose() {
  isPreviewLoan.value = false;
  emit("close");
  clearFields();
  resetError();
  progress.value = 1;
}

function closeAlert() {
  isAlert.value = false;
  handleClose();
}

function handleGoBack() {
  if (isPreviewLoan.value) return (isPreviewLoan.value = false);
  if (progress.value === 2) return (progress.value = 1);
  emit("goBack");
}

const handleProceed = () => {
  if (!validateFields()) return;
  progress.value = 2;
};

const handlePreview = async () => {
  if (!validateFields()) return;
  const payload = { ...args.value };
  payload.duration = +payload.duration.split(" ")[0];

  try {
    loading.value = true;
    //// upload documents //
    payload.documents.sixMonthsBankStatement = await uploadFileToServer(
      payload.documents.sixMonthsBankStatement
    );
    payload.documents.financialAudit = await uploadFileToServer(
      payload.documents.financialAudit
    );
    payload.documents.collateral = await uploadFileToServer(
      payload.documents.collateral
    );

    //save the uploaded docs so it can be used when creating loan
    uploadedDocs.value = payload.documents;

    const res = await previewLoan(payload);
    console.log(res);
    if (res.success) {
      previewData.value = { ...res.data, duration: args.value.duration };
      console.log(previewData.value);
      isPreviewLoan.value = true;
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    toast.error(error.message);
  } finally {
    loading.value = false;
  }
};

const submit = async () => {
  if (!validateFields()) return;
  const payload = { ...args.value };
  payload.duration = +payload.duration.split(" ")[0];
  payload.documents = uploadedDocs.value;

  try {
    loading.value = true;

    console.log(payload);
    const res = await createLoan(payload);
    if (res.success) {
      isAlert.value = true;
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    toast.error(error.message);
  } finally {
    loading.value = false;
  }
};
</script>

<style></style>
