<template>
  <main class="w-full">
    <h4 class="uppercase md:mt-3 md:text-sm md:text-left text-xs text-headerText">
      COMPLIANCE / ALL HISTORY / TRANSACTION DETAILS
    </h4>
    <div class="text-left my-6">
      <easiBackButton></easiBackButton>
    </div>

    <div class="w-full rounded-3xl bg-white p-4">
      <div class="flex flex-col gap-2 md:flex-row md:items-center justify-between text-grey">
        <h2 class="font-bold">Transaction Details</h2>
        <div class="flex items-center md:w-auto w-full gap-3">
          <easiButton @click="viewSingleRecord($route.query.createdAt)" size="small" variant="outlined">
            <span class="text-xs">
              <img class="w-4 -mt-1 hidden md:inline-block" src="@/assets/icons/redirect.svg" alt="" />
              View in Payroll History</span
            ></easiButton
          >
          <easiButton size="small">
            <span class="text-xs">
              <img class="w-4 -mt-1 hidden md:inline-block" src="@/assets/icons/white-download.svg" alt="" />
              Download Receipt</span
            ></easiButton
          >
        </div>
      </div>

      <div class="w-full grid md:grid-cols-3 grid-cols-2 mt-5 gap-4">
        <div v-for="total in totalDetails" :key="total.top" class="bg-newGrey w-full rounded-2xl p-2 flex gap-3">
          <div class="bg-white flex flex-col items-center justify-center rounded-xl w-9 h-9 md:w-12 md:h-12">
            <img class="w-6 contain" :src="total.icon" alt="" />
          </div>

          <div class="flex flex-col justify-center gap-1 md:gap-3">
            <p class="md:uppercase capitalize text-newGray text-xs">
              {{ total.top }}
            </p>
            <span v-if="total.bottom" class="text-dark-800 font-medium text-xs md:text-sm">{{
              typeof total.bottom == "number" ? formatAmount(total.bottom) : total.bottom
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="md:my-8 my-4 w-full bg-white rounded-2xl border border-outlineGray px-4 py-3">
      <h2 class="font-bold text-sm mt-1 mb-3">List of employees paid</h2>

      <div v-if="loading" class="w-full">
        <easiLoader />
      </div>
      <div
        class="flex text-center w-full mb-3 rounded-xl tracking-wide justify-start gap-x-2 p-1 text-xs font-medium cursor-pointer bg-newGrey"
      >
        <span
          v-for="opt in registrationFilterOpts"
          :key="opt.value"
          @click="filterRegHistory(opt.value)"
          class="w-full text-xs capitalize md:uppercase rounded-lg md:px-5 md:py-2 px-3 py-2"
          :class="activeRegFilter === opt.value ? 'bg-white shadow-2xl text-grey' : ''"
          >{{ opt.label }}
          <span class="hidden md:inline-block">
            ({{ getFilterData(opt.value) ? getFilterData(opt.value).length : 0 }})</span
          >
        </span>
      </div>
      <div class="flex w-full gap-x-3">
        <div class="w-10/12">
          <ComplianceSearch
            placeholder="Search employee by name, amount..."
            v-model="searchText"
            @search="searchData"
          />
        </div>

        <!-- /////// Filter Component Start /////// -->
        <!-- <div class="md:ml-0 mb-4 z-20">
          <Filter @update="filterFN" ref="viewEmployeeFilterRef" />
        </div> -->

        <div class="md:ml-0 mb-4 z-20">
          <ComplianceActions @act="takeAction($event)" :payment="true" />
        </div>
      </div>
      <easiActiveFilter @close="removeFilter" :filters="activeFilters" />

      <hr class="border-1 border-outlineGray mt-3" />
      <div class="w-full">
        <NewComplianceTable
          :data="filteredData ? filteredData : []"
          @selected="selectedEmployees = $event"
          class="w-full"
        />
      </div>

      <!-- <hr class="border-0.5 border-dividerColor w-full mt-4 mb-2" /> -->
      <div class="flex w-full justify-end mt-2 items-center">
        <!-- <easiPagin
            :data="complianceData.length"
            @page:update="updatePage"
            :currentPage="currentPage"
            :visibleData="visibleData"
            :pageSize="pageSize"
            class="w-full md:mt-4"
          /> -->
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute, useRouter } from "vue-router";

// import Filter from "@/components/Filters/ComplianceFilter.vue";
import { helperFunctions } from "@/composable/helperFunctions";

import ComplianceSearch from "@/components/Search/ComplianceSearch.vue";
import ComplianceActions from "@/components/Compliance/ComplianceActions.vue";
import NewComplianceTable from "@/components/Compliance/NewComplianceTable.vue";

import smallPaye from "@/assets/icons/paye-small-icon.svg";
import smallHmo from "@/assets/icons/hmo-small-icon.svg";
import smallPension from "@/assets/icons/pension-small-icon.svg";
import smallNhf from "@/assets/icons/smallNhf.svg";
import smallItf from "@/assets/icons/smallItf.svg";
import smallNsitf from "@/assets/icons/smallNsitf.svg";

import coins from "@/assets/icons/coins.svg";
import staff from "@/assets/icons/staff.svg";
import successful from "@/assets/icons/successful.svg";
import failed from "@/assets/icons/failed.svg";
import calendar from "@/assets/icons/small-calendar.svg";

const route = useRoute();
const router = useRouter();

const { formatDateString, formatAmount, getDateText } = helperFunctions;

const store = useDataStore();
const loading = ref(false);
const { query } = store;

const transDetails = computed(() =>
  store.getsingleComplianceTransactionDetails && store.getsingleComplianceTransactionDetails.data
    ? store.getsingleComplianceTransactionDetails.data
    : {}
);

const employeeCompliance = computed(() => (store.getEmployeeCompliance ? store.getEmployeeCompliance : []));
const complianceData = computed(() => {
  const data =
    employeeCompliance.value &&
    employeeCompliance.value.length &&
    employeeCompliance.value.map((comp) => {
      return {
        dateOfRegistration: comp.createdAt,
        typeOfRegistration: comp.registrationType,
        employee: comp.employee,
        employer: comp.employer,
        status: "pending",
      };
    });
  return data;
});

const visibleData = ref([]);
const selectedEmployees = ref([]);

watch(complianceData, (val) => {
  visibleData.value = val;
});

const activeFilter = ref("transaction");
const activeRegFilter = ref("all");
const filterOpts = ref([
  {
    label: "All transaction history",
    value: "transaction",
  },
  {
    label: "All registration history",
    value: "registration",
  },
]);

const registrationFilterOpts = ref([
  {
    label: "All",
    value: "all",
  },
  {
    label: "success",
    value: "success",
  },

  {
    label: "processing",
    value: "processing",
  },
  {
    label: "failed",
    value: "failed",
  },
]);
const filteredData = ref([]);
function getFilterData(filterKey) {
  let data = [];
  if (transDetails.value && transDetails.value.employeeDetails) {
    if (filterKey === "all") {
      return transDetails.value.employeeDetails;
    }
    data = transDetails.value.employeeDetails.filter((data) => data.status === filterKey);
  }

  return data;
}

async function filterHistory(stat) {
  activeFilter.value = stat;
}

async function filterRegHistory(stat) {
  activeRegFilter.value = stat;
  filteredData.value = getFilterData(stat);
}

const viewEmployeeFilterRef = ref(null);

const activeFilters = ref([]);
const filterFN = async ({ filter, active }) => {
  // console.log(filter, active);
  if (active.length) {
    const isSortActive = Object.keys(filter.sort) && Object.keys(filter.sort).length ? true : false;
    activeFilters.value = active;
    if (isSortActive) {
      visibleData.value = sortArray(visibleData.value, filter.sort.firstName);
    }

    if (filter && filter.filter && filter.filter.length) {
      visibleData.value = complianceData.value;
      visibleData.value = filterByType(visibleData.value, filter.filter);
    }
  } else {
    visibleData.value = complianceData.value;
  }
};

const removeFilter = (arg) => {
  console.log(viewEmployeeFilterRef.value);
  if (viewEmployeeFilterRef.value && viewEmployeeFilterRef.value.removeFilter) {
    viewEmployeeFilterRef.value.removeFilter(arg);
  }
};

const searchText = ref("");
function searchEmployees(e) {
  console.log(e, "SS");
  searchText.value = e.toLowerCase();
  if (searchText.value && searchText.value !== undefined && searchText.value.length) {
    visibleData.value = complianceData.value;
    visibleData.value = searchArrayObjects(visibleData.value, searchText.value);
  } else {
    visibleData.value = complianceData.value;
  }
}

function returnDetails(type) {
  const data = {
    paye: {
      icon: smallPaye,
      text: "PAYE",
    },
    pension: {
      icon: smallPension,
      text: "Pension",
    },
    hmo: {
      icon: smallHmo,
      text: "HMO",
    },
    itf: {
      icon: smallItf,
      text: "ITF",
    },
    nsitf: {
      icon: smallNsitf,
      text: "NSITF",
    },
    nhf: {
      icon: smallNhf,
      text: "NHF",
    },
  };
  return data[type];
}

const totalDetails = computed(() => [
  {
    top: "Service Type",
    bottom: returnDetails(route.query.type).text,
    icon: returnDetails(route.query.type).icon,
  },
  {
    top: "Total Amount Paid",
    bottom: transDetails.value.totalAmount,
    icon: coins,
  },
  {
    top: "Date Initiated",
    bottom: getDateText(transDetails.value.dateInitiated),
    icon: calendar,
  },
  {
    top: "Total Staff Paid",
    bottom: String(transDetails.value.totalStaffPaid),
    icon: staff,
  },

  {
    top: "Total Successful",
    bottom: String(transDetails.value.totalSuccessful),
    icon: successful,
  },
  {
    top: "Total Failed",
    bottom: String(transDetails.value.totalFailed),
    icon: failed,
  },
]);

const dedsTable = {
  paye: "PAYE",
  pension: "Pension",
  nhf: "NHF",
  nsitf: "NSITF",
  itf: "ITF",
  wht: "WHT",
  lifeAssurance: "Employee_Life_Assurance",
  hmo: "HMO",
};

//I need the employeeDetails array to be populated so I know how to handle the headers
const queryComplianceDetails = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "GetComplianceTxnDetails",
      payload: {
        input: {
          name: dedsTable[route.query.type],
          createdAt: route.query.createdAt.split("T")[0],
        },
      },
      service: "PAYROLL",
      storeKey: "singleComplianceTransactionDetails",
    });
    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};

function analyzeSearchText(searchText) {
  if (!isNaN(Number(searchText))) {
    console.log("number");
    return "number";
  }

  // If it's not a partial month or date, consider it as regular text
  return "Regular Text";
}

function searchArrayObjects(arr, searchString) {
  if (analyzeSearchText(searchString) === "Regular Text") {
    return arr.filter((item) => {
      return (
        item.fullName.toLowerCase().includes(searchString.toLowerCase()) ||
        item.fullName.toLowerCase().indexOf(searchString.toLowerCase()) > -1
      );
    });
  }
  return arr.filter((item) => {
    return (
      String(item.amountPaid).includes(String(searchString)) ||
      formatAmount(item.amountPaid).includes(String(searchString))
    );
  });
}

function searchData(e) {
  searchText.value = e.toLowerCase();
  if (searchText.value && searchText.value !== undefined && searchText.value.length) {
    filteredData.value = transDetails.value.employeeDetails;
    filteredData.value = searchArrayObjects(transDetails.value.employeeDetails, searchText.value);
  } else {
    filteredData.value = transDetails.value.employeeDetails;
  }
}

const viewSingleRecord = (dt) => {
  const dateT = dt.split("T")[0].split("-");
  const month = Number(dateT[1]) - 1;
  const year = dateT[0];
  const timeline = "MONTHLY";
  let payloadDate = "";

  payloadDate = payloadDate = `${month},${year},${timeline}`;

  router.push({
    name: "PayrollHistoryDetails",
    params: {
      date: payloadDate,
    },
  });
};
function toRunPayroll() {
  const payrollDate = route.query.createdAt.split("T")[0].split("-");
  const month = parseInt(payrollDate[1]) - 1;
  const year = parseInt(payrollDate[0]);
  const date = `${month}-${year}`;
  store.$patch({
    employeeArrayForPayroll: selectedEmployees.value,
  });
  router.push({ name: "PaySalary", params: { id: date } });
}

function takeAction(action) {
  console.log(action);
  if (action === "retry") {
    toRunPayroll();
  }
}
onMounted(async () => {
  await queryComplianceDetails();
  filteredData.value = getFilterData("all");
});
</script>

<style></style>
