<template>
  <div class="w-full flex flex-col justify-center mx-auto">
    <form
      class="md:grid flex flex-col md:grid-cols-2 md:items-center md:gap-y-2 gap-y-2 md:gap-x-4"
      @submit.prevent="checkArgs"
    >
      <div>
        <easiTextInput
          placeholder="Position/Title in the company"
          type="text"
          name="position"
          required
          v-model="argument.title"
        ></easiTextInput>
      </div>

      <div class="mt-4">
        <easiSelectInput2
          :options="businessTypeOptions"
          :value="argument.businessType"
          @update="argument.businessType = $event"
          placeholder="Business Type"
        ></easiSelectInput2>
      </div>
      <div class="col-span-2">
        <easiTextInput
          placeholder="Residential Address"
          type="text"
          name="address"
          v-model="argument.residentialAddress"
          required
        ></easiTextInput>
      </div>

      <div class="mt-6">
        <easiSelectInput2
          :value="argument.businessOwnership"
          @update="argument.businessOwnership = $event"
          :options="ownershipOptions"
          placeholder="Is your business premises owned or rented?"
        ></easiSelectInput2>
      </div>
      <div class="justify-center">
        <UploadComponent
          label="Upload evidence (land use bill, rent reciept, utility bill, etc)"
          @fileUrl="evidenceFile = $event"
          :imageLink="argument.evidenceOfOwnershipUrl"
          :dotted="true"
        />
        <!-- <FileLoader v-if="evidenceFile != null" /> -->
      </div>

      <div class="md:mt-3">
        <!-- <easiSelectInput2
          :value="argument.yearsInLocation"
          @update="argument.yearsInLocation = $event"
          :options="numberOfYearsOption"
          placeholder="No. of years in location"
        ></easiSelectInput2> -->

        <easiTextInput
          placeholder="No. of years in location"
          v-model="argument.yearsInLocation"
          type="number"
          name="years"
          required
        ></easiTextInput>
      </div>

      <div class="mt-4">
        <easiSelectInput2
          :options="receivedLoansOptions"
          @update="argument.receivedLoansInNGbefore = $event"
          :value="argument.receivedLoansInNGbefore"
          hint="This includes any active directors of your company"
          placeholder="Received loans in Nigeria before?"
          class="-mb-3"
        ></easiSelectInput2>
      </div>

      <div class="md:mt-4 mt-12">
        <easiSelectInput2
          :options="receivedLoansOptions"
          @update="argument.ongoingLoanFacility = $event"
          :value="argument.ongoingLoanFacility"
          placeholder="Are you servicing an ongoing loan facility?"
        ></easiSelectInput2>
      </div>
      <div
        :class="
          argument.ongoingLoanFacility
            ? undefined
            : 'hidden md:block md:invisible'
        "
        class="md:mt-3"
      >
        <datePicker
          :max="today"
          @update="argument.dateReceived = $event"
          :value="argument.dateReceived"
          placeholder="When was it collected"
        />
      </div>
      <div
        :class="
          argument.ongoingLoanFacility
            ? undefined
            : 'hidden md:block md:invisible'
        "
        class="-mt-9"
      >
        <easiTextInput
          placeholder="Amount collected"
          v-model="argument.loanAmountReceived"
          type="text"
          name="amount"
          :format="true"
          required
        ></easiTextInput>
      </div>
      <div
        :class="
          argument.ongoingLoanFacility
            ? undefined
            : 'hidden md:block md:invisible'
        "
        class="-mt-4"
      >
        <datePicker
          :min="receivedDate"
          :value="argument.endDate"
          @update="argument.endDate = $event"
          placeholder="Loan end date"
        />
      </div>

      <div class="-mt-2 md:-mt-9">
        <easiTextInput
          placeholder="Company registered name"
          v-model="argument.companyRegisteredName"
          type="text"
          name="name"
          required
        ></easiTextInput>
      </div>
      <div class="-mt-1 md:-mt-4">
        <datePicker
          :max="today"
          @update="argument.monthAndYearOppCommenced = $event"
          :value="argument.monthAndYearOppCommenced"
          placeholder="Month and year operation commenced"
        />
      </div>

      <div class="md:mt-0 -mt-5">
        <easiTextInput
          placeholder="Total number of staff"
          v-model="argument.totalEmployees"
          type="text"
          name="total"
          required
        ></easiTextInput>
      </div>
      <!-- <div class="justify-center">
        <UploadComponent
          label="Upload last 6 months monthly revenue"
          @fileUrl="sixMonthRevFile = $event"
          :imageLink="argument.sixMonthsRevenueUrl"
          :dotted="true"
        />
      </div>
      <div class="justify-center">
        <UploadComponent
          label="Upload last 6 months monthly expenses"
          @fileUrl="sixMonthExpFile = $event"
          :imageLink="argument.sixMonthsExpensesUrl"
          :dotted="true"
        />
      </div> -->
      <div class="justify-center">
        <UploadComponent
          label="Upload projected next 6 months monthly gross revenue"
          @fileUrl="sixMonthsProjectedRevenueFile = $event"
          :imageLink="argument.sixMonthsProjectedRevenueUrl"
          :dotted="true"
        />
      </div>

      <div
        class="flex items-end col-span-2 justify-end self-end gap-4 mb-4 mt-8"
      >
        <easiButton
          class="md:w-32 w-40"
          type="button"
          size="medium"
          variant="outlined"
          @click="$router.go(-1)"
          >Cancel</easiButton
        >
        <easiButton size="medium" type="submit" class="md:w-32 w-40"
          >Next</easiButton
        >
      </div>
    </form>
    <easiRoundLoader v-if="loading" />
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch } from "vue";
import UploadComponent from "@/components/global/UploadComponent";
import datePicker from "@/components/global/DatePicker.vue";
import FileLoader from "@/components/global/FileLoader.vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";

const { uploadFileToServer, processDate } = helperFunctions;
const toast = useToast();
const store = useDataStore();

const emit = defineEmits(["business"]);
const evidenceFile = ref(null);
const sixMonthRevFile = ref(null);
const sixMonthExpFile = ref(null);
const sixMonthsProjectedRevenueFile = ref(null);
const numberOfYearsOption = ref([]);

const loanArgsInStore = computed(() => store.getLoanArgs);

const today = processDate();

const businessTypeOptions = ref([
  {
    label: "Sole Proprietorship",
    value: "SOLE_PROPIETORSHIP",
  },
  {
    label: "Partnership",
    value: "PARTNERSHIP",
  },
  {
    label: "Corporation",
    value: "CORPORATION",
  },
]);

const ownershipOptions = ref([
  {
    label: "Owned",
    value: "owned",
  },
  {
    label: "Rented",
    value: "rented",
  },
]);

const receivedLoansOptions = ref([
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
]);
const argument = ref({
  yearsInLocation: "",
  totalEmployees: "",
  title: "",
  sixMonthsRevenueUrl: "",
  sixMonthsProjectedRevenueUrl: "",
  sixMonthsExpensesUrl: "",
  residentialAddress: "",
  receivedLoansInNGbefore: false,
  ongoingLoanFacility: false,
  monthAndYearOppCommenced: "",
  loanAmountReceived: "",
  lastMonthRevenue: "0",

  lastMonthExpenses: "0",

  evidenceOfRevAndExpUrl: "",
  endDate: "",
  evidenceOfOwnershipUrl: "",
  dateReceived: "",
  companyRegisteredName: "",
  businessType: "",
  businessOwnership: false,
});

const dateReceived = computed(() => argument.value.dateReceived);
const receivedDate = ref("");
watch(
  dateReceived,
  (val) => {
    receivedDate.value = val;
  }
  // { deep: true }
);
const loading = ref(false);
async function checkArgs() {
  loading.value = true;
  try {
    if (sixMonthsProjectedRevenueFile.value) {
      // argument.value.sixMonthsRevenueUrl = await uploadFileToServer(
      //   sixMonthRevFile.value
      // );
      argument.value.sixMonthsProjectedRevenueUrl = await uploadFileToServer(
        sixMonthsProjectedRevenueFile.value
      );
    }
    // argument.value.sixMonthsExpensesUrl = await uploadFileToServer(
    //   sixMonthExpFile.value
    // );
    if (evidenceFile.value) {
      argument.value.evidenceOfOwnershipUrl = await uploadFileToServer(
        evidenceFile.value
      );
    }
    if (
      !argument.value.sixMonthsProjectedRevenueUrl.length ||
      !argument.value.evidenceOfOwnershipUrl.length
    ) {
      toast.error("Upload all documents");
      return;
    }
    emit("business", argument.value);
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  for (let i = 1; i <= 20; i++) {
    numberOfYearsOption.value.push({
      label: i,
      value: i,
    });
  }
  console.log(loanArgsInStore.value);
  if (loanArgsInStore.value && loanArgsInStore.value.businessInformation) {
    argument.value = Object.assign(
      argument.value,
      loanArgsInStore.value.businessInformation
    );
  }
});
</script>

<style scoped>
.custom-date-input {
  display: inline-block;
  position: relative;
}

.custom-date-input input[type="text"] {
  padding: 5px;
  width: 150px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.custom-date-input::before {
  content: attr(placeholder);
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #999;
}
</style>
