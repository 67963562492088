import { useDataStore } from "@/stores/data.js";
const store = useDataStore();
const { query, mutate } = store;
//// QUERIES /////

const service = "LOAN";

export const getRecentLoan = async () => {
  try {
    const res = await query({
      endpoint: "GetRecentLoanStatus",
      service,
    });

    if (res.success && res.data) {
      //check if loan staus is APPROVED and get the installment details
      const isApproved = res.data.status === "APPROVED";

      if (isApproved) {
        const loanId = res.data._id;
        const loanDetails = await getLoanDetails(loanId);

        if (loanDetails.success && loanDetails.data) {
          res.data.installment = loanDetails.data.installment;
        }
        console.log(res);
      }

      store.$patch({
        currentLoan: res.data,
      });
    }
    return res;
  } catch (error) {
    throw error;
  }
};

export const getLoanDetails = async (loanId) => {
  try {
    const res = await query({
      endpoint: "GetLoanDetails",
      payload: {
        loanId,
      },
      service,
    });

    return res;
  } catch (error) {
    console.log("Error fetching credit details", error.message);
    throw error;
  }
};

//// MUTATIONS /////
export const previewLoan = async (payload) => {
  try {
    const res = await mutate({
      endpoint: "PreviewLoan",
      data: {
        input: payload,
      },
      service,
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const createLoan = async (payload) => {
  try {
    const res = await mutate({
      endpoint: "CreateLoan",
      data: {
        input: payload,
      },
      service,
    });

    if (res.success) {
      await getRecentLoan();
    }
    return res;
  } catch (error) {
    throw error;
  }
};

export const checkEligibility = async (type) => {
  try {
    const res = await mutate({
      endpoint: "CheckEligibility",
      data: { type },
      service,
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export const payLoan = async (payload) => {
  try {
    const res = await mutate({
      endpoint: "PayLoan",
      data: { input: payload },
      service,
    });

    if (res.success) {
      await getRecentLoan();
      queryWalletBalance();
    }
    return res;
  } catch (error) {
    throw error;
  }
};

async function queryWalletBalance() {
  try {
    await query({
      endpoint: "ViewWalletBalance",
      payload: {
        input: {
          companyId: "",
        },
      },
      service: "PAYROLL",
      storeKey: "walletBalance",
    });
  } catch (err) {
    console.log(err);
  }
}
