<template>
  <main :class="type ? 'py-3' : ''">
    <span @click="closeEvent" class="absolute top-4 right-4 cursor-pointer">
      <img src="@/assets/icons/close.svg" alt="" />
    </span>

    <h4 class="text-xl md:text-3xl text-center font-medium pt-5 pb-2">
      {{ type ? "Add" : "Edit" }} Department
    </h4>

    <hr class="border border-newLightgrey my-4 mx-8" />

    <form
      @submit.prevent="submit"
      class="flex flex-col gap-4"
      :class="type ? 'px-8' : undefined"
    >
      <easiTextInput
        placeholder="Department Name"
        type="text"
        name="departmentName"
        class="mt-1 mb-5 capitalize"
        required
        v-model="args.name"
        :error="errorRules.name"
      />

      <easiEmployeeBonus
        v-if="allEmployees && allEmployees.length"
        @update="departmentHead = $event"
        :value="departmentHead"
        :options="createEmpList()"
        :clearAll="clear"
        :single="true"
        :label="'Select Head of Department'"
        :placeHolder="'Select Head of Department'"
        class="mb-2"
      />

      <hr class="border border-newLightgrey mt-0 mb-5" />

      <easiEmployeeBonus
        v-if="allEmployees && allEmployees.length"
        @update="employees = $event"
        :value="employees"
        :label="'Select Employees'"
        :options="createEmpList()"
        :clearAll="clear"
        class="mb-3"
      />
      <hr class="border border-newLightgrey mt-0 mb-5" />

      <!-- <hr v-if="type" class="border border-newLightgrey mt-5 mb-7" /> -->
      <div
        v-if="type"
        class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <easiButton
          type="button"
          @click="save"
          variant="outlined"
          color="primary"
          class="rounded-full w-80 md:w-48"
          :loading="loading.save"
        >
          <span class="text-sm"> Save And Add More </span>
        </easiButton>
        <easiButton
          :loading="loading.add"
          type="submit"
          color="primary"
          class="rounded-full w-80 md:w-48"
        >
          <span class="text-sm"> Submit </span>
        </easiButton>
      </div>

      <div
        v-else
        class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <span
          @click="closeEvent"
          class="md:w-48 w-full font-medium cursor-pointer text-center bg-white rounded-full text-dark-800 border border-primary px-3 text-sm py-3"
          >Cancel</span
        >
        <div class="w-full md:w-auto">
          <easiButton
            type="submit"
            :loading="loading.edit"
            class="w-full md:w-48"
          >
            Update
          </easiButton>
        </div>
      </div>
    </form>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Department {{ type ? "Created" : "Updated" }} successfully</span>
      </template>
    </easiSuccess>
  </main>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
// import UploadComponent from "@/components/global/UploadComponent";
// import BankDetail from "@/components/Employee/Edit/Benefits/BankDetails.vue";
// import NigerianStates from "@/utils/states";
// import IndustryList from "@/utils/Industry";

const store = useDataStore();
const { query, mutate } = store;
const toast = useToast();
const { getAllEmployees, getAllDepartments } = storeToRefs(store);

const props = defineProps({
  type: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["close"]);

const { processDate, validatePhone, validateEmail, resetObj, log } =
  helperFunctions;
const visibleRef = ref(false);
const clear = ref(false);
const imgsRef = ref("");
const allEmployees = computed(() =>
  getAllEmployees.value ? getAllEmployees.value.data : []
);

const Department = ref(true);
const departmentHead = ref([]);
const employees = ref([]);

const deleteWarning = ref(false);
const updateSuccess = ref(false);
const addBank = ref(false);
const show = ref(false);
const loading = ref({
  add: false,
  edit: false,
  save: false,
});
const isEdit = ref(false);
// const companyData = computed(() => store.getCompanyDetails);
const errorRules = ref({
  name: "",
  departmentHead: [],
  employees: [],
});

const imgLink = ref("@/assets/img/cert.png");
// const industryData = IndustryList.map((el) => el.name);

let args = reactive({
  name: "",
  departmentHead: [],
  employees: [],
});

const profileArgsFromStore = computed(() => store.profileData);

function closeEvent() {
  clearFields();
  emit("close");
}
const argsArray = ref([]);

const departmentId = window.localStorage.getItem("departmentId");

function getDepartmentId(id) {
  let department = [];
  if (departmentId) {
    department = getAllDepartments.value.data.filter((el) => el._id === id);
  }
  return department;
}

const editDepartment = getDepartmentId(departmentId);

onMounted(() => {
  assignValues();
  store.$patch({
    profileArr: [],
  });
});

function assignValues() {
  if (!props.type) {
    if (editDepartment.length) {
      const department = editDepartment[0];
      args.name = department.name;
      args.employees = getEmpIds(department.employees);
      args.departmentHead = [department.departmentHead._id];
      args.address = department.address;
      args._id = department._id;
      employees.value = getEmpIds(department.employees);
      departmentHead.value = [department.departmentHead._id];
    }
  }

  function getEmpIds(arr) {
    return arr.map((el) => el._id);
  }
}
assignValues();

function addMore(arg) {
  args.departmentHead = departmentHead.value[0];
  args.employees = employees.value.map((emp) => emp); //map to ceate a new array with a different reference
  const newObj = Object.assign({}, args);

  argsArray.value.push(newObj);

  store.$patch((state) => {
    state.profileArr.push(newObj);
  });
  // departmentHead.value = departmentHead.value.splice(0);
  // employees.value = employees.value.splice(0);

  clear.value = false;
}

async function save() {
  addMore(args);
  await createDepartment(false);
  // args.employees = args.employees.splice(0);
  // Object.assign(args, resetObj(args, "args"));
}
// const statesData = computed(() => {
//   let stateArray = [];
//   stateArray = NigerianStates.map((state) => {
//     return {
//       label: state,
//       value: state,
//     };
//   });
//   return stateArray;
// });

const clearFields = () => {
  // args = resetObj(args, "args");
  args.name = "";
  args.departmentHead = [];
  args.employees = [];
  // Object.assign(args, resetObj(args, "args"));
  departmentHead.value = departmentHead.value.splice(0);
  employees.value = employees.value.splice(0);
  store.$patch({
    profileArr: [],
  });
};

const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};

const submit = () => {
  if (props.type) {
    createDepartment(true);
  } else {
    updateDepartment();
  }
};

const createDepartment = async (final) => {
  if (final) {
    loading.value.add = true;
    args.departmentHead = departmentHead.value[0];
    args.employees = employees.value.map((emp) => emp);
    // addMore(args);
  } else {
    loading.value.save = true;
  }

  try {
    let res = await mutate({
      endpoint: "CreateDepartment",
      data: {
        input: final ? args : profileArgsFromStore.value,
      },
      service: "SETTINGS",
    });

    loading.value.add = false;
    // log(res);

    await queryDepartment();
    clearFields();
    if (final) {
      updateSuccess.value = true;
      setTimeout(() => {
        updateSuccess.value = false;
        closeEvent();
      }, 500);
    } else {
      toast.success("Details saved!");
    }
  } catch (e) {
    console.log(e);
    // args = resetObj(args, "args");
    store.$patch({
      profileArr: [],
    });
  } finally {
    loading.value.add = false;
    loading.value.edit = false;
    loading.value.save = false;
  }
};
const updateDepartment = () => {
  loading.value.edit = true;

  const payload = {
    name: args.name,
    departmentHead: departmentHead.value[0],
    employees: employees.value,
  };

  mutate({
    endpoint: "UpdateDepartment",
    data: {
      departmentId: args._id,
      input: payload,
    },
    service: "SETTINGS",
  })
    .then((res) => {
      loading.value.edit = false;
      updateSuccess.value = true;
      // log(res);

      clearFields();
      queryDepartment();
      setTimeout(() => {
        updateSuccess.value = false;
        closeEvent();
      }, 500);
    })
    .catch((e) => {
      loading.value.edit = false;
      console.log(e);
      args = resetObj(args, "args");
      store.$patch({
        profileArr: [],
      });
    });
};
// const getAvatar = (employee) => {
//   let name = "",
//     color;
//   // Get color
//   const colors = [
//     "#D32F2F",
//     "#189F00",
//     "#0A244F",
//     "#E4572E",
//     "#184EA8",
//     "#F39C36",
//     "#4f46e5",
//   ];

//   const randomNumber = Math.floor(Math.random() * 5);
//   color = colors[randomNumber + 1];

//   // Get Shortend Name
//   employee
//     ? (name = `${employee.profile && employee.profile.firstName.charAt(0)}${
//         employee.profile && employee.profile.lastName.charAt(0)
//       }`)
//     : "";

//   return {
//     color,
//     name,
//   };
// };

const queryDepartment = async () => {
  try {
    await query({
      endpoint: "ListDepartments",
      service: "SETTINGS",
      storeKey: "listDepartments",
    });
    console.log("Departments");
  } catch (err) {
    console.log(err);
  }
};

const onPreview = (img) => {
  imgsRef.value = img;
  visibleRef.value = true;
};

const onHide = () => (visibleRef.value = false);

const reloadPage = () => {
  store.$patch({
    pageIndex: 0,
  });
  window.location.reload();
};
</script>

<style></style>
