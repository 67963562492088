<template>
  <div
    class="p-4 bg-white shadow-lg cursor-pointer border hover:border-2 hover:shadow-md rounded-xl grid grid-cols-3 md:grid-cols-6 gap-2 sm:gap-4"
  >
    <div class="text-left flex flex-col gap-1 text-xs font-medium items-start">
      <span :class="loan && getColor(loan.status, 'text')">Loan Amount</span>
      <span class="text-xs sm:text-sm">
        {{
          loan && loan.loanAmountApplied
            ? formatAmount(loan.loanAmountApplied)
            : "N/A"
        }}
      </span>
    </div>
    <div
      class="text-left hidden md:flex flex-col gap-1 text-xs font-medium items-start"
    >
      <span :class="loan && getColor(loan.status, 'text')">Purpose</span>
      <span class="text-xs md:text-sm">
        {{ loan && loan.purpose ? loan.purpose : "N/A" }}
      </span>
    </div>
    <div
      class="text-left hidden md:flex flex-col gap-1 text-xs font-medium items-start"
    >
      <span :class="loan && getColor(loan.status, 'text')">Date Applied</span>
      <span class="text-xs md:text-sm">
        {{ loan && loan.createdAt ? formatDateString(loan.createdAt) : "N/A" }}
      </span>
    </div>
    <div
      class="text-left hidden md:flex flex-col gap-1 text-xs font-medium items-start"
    >
      <span :class="loan && getColor(loan.status, 'text')">Due Date</span>
      <span class="text-xs md:text-sm">
        {{
          loan && loan.durationEndedAt
            ? formatDateString(loan.durationEndedAt)
            : "N/A"
        }}
      </span>
    </div>
    <div class="text-left flex flex-col gap-1 text-xs font-medium items-start">
      <span :class="loan && getColor(loan.status, 'text')">Next Repayment</span>
      <span class="text-xs md:text-sm">
        {{
          loan && loan.repaymentBreakDown
            ? formatDateString(getEarliestDate(loan.repaymentBreakDown))
            : "N/A"
        }}
      </span>
    </div>
    <!-- <easiButton
      rounded="full"
      size="small"
      class="w-full md:py-3"
      :whiteSpace="false"
      :color="loan && getColor(loan.status, 'btn')"
    >
      <span class="text-xs md:text-sm">{{
        loan && getColor(loan.status, "status")
      }}</span>
    </easiButton> -->

    <div
      :class="loan && getColor(loan.status, 'bg')"
      class="w-full border rounded-full text-xs md:text-sm text-center p-3 md:p-3"
    >
      {{ loan && getColor(loan.status, "status") }}
    </div>
  </div>
</template>

<script setup>
import { helperFunctions } from "@/composable/helperFunctions";
const { formatAmount } = helperFunctions;

const props = defineProps({
  loan: {
    type: Object,
    required: true,
  },
});
console.log(props.loan, "LOAN PROPS");
const getColor = (status, type) => {
  const colors = {
    PENDING: {
      bg: "border-lightBorder pending",
      text: "text-newGray",
      btn: "secondary",
      status: "Pending",
    },
    ACTIVE: {
      bg: "border-info bg-blueHoverColor",
      text: "text-newGray",
      btn: "primary",
      status: "Pay Now",
    },
    COMPLETED: {
      bg: "border-easiGreen bg-paleGreen",
      text: "text-newGray",
      btn: "easiGreen",
      status: "Completed",
    },
    CUSTOMER_CANCELED: {
      bg: "border-secondary bg-lightSec",
      text: "text-newGray",
      btn: "secondary",
      status: "Cancelled",
    },
    REJECTED: {
      bg: "border-secondary bg-lightSec",
      text: "text-newGray",
      btn: "secondary",
      status: "Rejected",
    },
    DECLINED: {
      bg: "border-secondary bg-lightSec",
      text: "text-newGray",
      btn: "secondary",
      status: "Declined",
    },
  };

  if (status && colors[status] && colors[status][type]) {
    return colors[status][type];
  }
};

function formatDateString(dateStr) {
  if (dateStr) {
    const date = new Date(dateStr);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  }
  return "";
}

function getEarliestDate(arr) {
  if (arr && arr.length) {
    const currentDate = new Date(); // Get the current date
    const dueDates = arr;

    const earliestDate = dueDates
      .filter((dueDate) => new Date(dueDate.dueDate) > currentDate) // Filter out dates earlier than or equal to the current date
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))[0];
    console.log(arr, earliestDate);
    return earliestDate ? earliestDate.dueDate : undefined;
  }
}
</script>

<style scoped>
.pending {
  background: rgba(245, 158, 11, 0.12);
  color: #f59e0b;
}
</style>
