<template>
  <div class="overflow-x-auto flex flex-col gap-3">
    <table
      v-if="bonusOrDedDataHistory.length"
      class="table-auto w-full border-collapse text-sm"
    >
      <thead>
        <tr class="bg-outlineGray">
          <th class="px-4 py-2">EMPLOYEE NAME</th>
          <th class="px-4 py-2 uppercase">{{ route.query.type }} NAME</th>
          <th class="px-4 py-2">AMOUNT</th>
          <th class="px-4 py-2">BANK NAME</th>
          <th class="px-4 py-2">DATE PAID</th>
          <th class="px-4 py-2">PAYMENT STATUS</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(row, index) in bonusOrDedDataHistory" :key="index">
          <tr
            :class="index % 2 === 1 ? 'bg-landingGrey' : 'bg-white'"
            class="py-3 text-center capitalize"
          >
            <td class="px-4 py-3">{{ row.employeeName }}</td>
            <td class="px-4 py-3">{{ row.bonusOrDeductionName }}</td>
            <td class="px-4 py-3">{{ formatAmount(row.amountPaid) }}</td>
            <td class="px-4 py-3">{{ row.bankName }}</td>
            <td class="px-4 py-3">{{ formatDateString(row.datePaid) }}</td>
            <td class="px-4 py-3">{{ row.statusOfPayment }}</td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-else class="text-center w-full">No Employees found</div>

    <easiPaginate2
      :totalRecords="totalRecords"
      :pageSize="pageSize"
      :currentPage="currentPage"
      @page:update="$emit('page:update', $event + 1)"
      @update:pageSize="$emit('update:pageSize', $event)"
    />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";
import { helperFunctions } from "@/composable/helperFunctions";

import TaxPagination from "@/components/TaxFiling/TaxPagination.vue";

const props = defineProps({
  currentPage: {
    type: Number,
    default: 0,
  },
  pageSize: {
    type: Number,
    default: 5,
  },
  totalRecords: {
    type: Number,
    default: 0,
  },
});
const store = useDataStore();
const { formatAmount, formatDateString } = helperFunctions;

const route = useRoute();

const { getMostRecentBonusOrDeduction } = storeToRefs(store);
const bonusOrDedDataHistory = computed(
  () =>
    getMostRecentBonusOrDeduction.value &&
    getMostRecentBonusOrDeduction.value.bonusOrDeductionPayments &&
    getMostRecentBonusOrDeduction.value.bonusOrDeductionPayments.length &&
    getMostRecentBonusOrDeduction.value.bonusOrDeductionPayments
);
const tableData = ref([
  {
    employeeName: "Jane Smith",
    bonusName: "Year-end",
    amount: "3468.23",
    bankName: "GTBank",
    datePaid: "20 - 6 - 2023",
    paymentStatus: "Paid",
  },
  {
    employeeName: "Bob Brown",
    bonusName: "Holiday",
    amount: "7246.17",
    bankName: "First Bank",
    datePaid: "15 - 12 - 2023",
    paymentStatus: "Pending",
  },
  {
    employeeName: "Alice Johnson",
    bonusName: "Christmas",
    amount: "5031.12",
    bankName: "Access Bank",
    datePaid: "8 - 3 - 2023",
    paymentStatus: "Paid",
  },
  {
    employeeName: "John Doe",
    bonusName: "Sales",
    amount: "1278.45",
    bankName: "GTBank",
    datePaid: "14 - 11 - 2023",
    paymentStatus: "Pending",
  },
  {
    employeeName: "Jane Smith",
    bonusName: "Performance",
    amount: "6123.84",
    bankName: "UBA",
    datePaid: "25 - 2 - 2023",
    paymentStatus: "Paid",
  },
  {
    employeeName: "Bob Brown",
    bonusName: "Christmas",
    amount: "895.32",
    bankName: "Zenith Bank",
    datePaid: "9 - 6 - 2023",
    paymentStatus: "Paid",
  },
  {
    employeeName: "John Doe",
    bonusName: "Year-end",
    amount: "4417.76",
    bankName: "First Bank",
    datePaid: "5 - 8 - 2023",
    paymentStatus: "Pending",
  },
  {
    employeeName: "Emma Wilson",
    bonusName: "Sales",
    amount: "9672.51",
    bankName: "GTBank",
    datePaid: "12 - 4 - 2023",
    paymentStatus: "Paid",
  },
  {
    employeeName: "Alice Johnson",
    bonusName: "Holiday",
    amount: "2253.87",
    bankName: "Zenith Bank",
    datePaid: "27 - 1 - 2023",
    paymentStatus: "Pending",
  },
  {
    employeeName: "Bob Brown",
    bonusName: "Performance",
    amount: "4198.29",
    bankName: "Access Bank",
    datePaid: "10 - 9 - 2023",
    paymentStatus: "Paid",
  },
]);
const handlePageChange = (page) => {
  console.log(page);
};
</script>

<style>
/* Tailwind classes */
</style>
