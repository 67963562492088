<template>
  <main class="">
    <div class="relative">
      <div
        @click="toggleFilter = true"
        class="relative border bg-red-200 text-white border-dark-300 rounded-xl md:rounded-2xl p-2 md:px-4 md:py-4 cursor-pointer flex items-center gap-2 bg-primary w-full md:w-auto"
      >
        <img
          v-if="!payment"
          class="w-6 block ml-auto md:w-4"
          src="@/assets/img/downloadSvg.svg"
          alt=""
        />

        <span class="font-medium flex text-xs text-left">
          {{ payment ? "Actions" : "Export" }}
        </span>

        <div
          class="pointer-events-none hidden md:flex items-center px-2 text-dark-500"
        >
          <svg
            class="h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="white"
          >
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>
      <div
        v-show="toggleFilter"
        class="absolute h-auto w-52 right-0 rounded-xl top-16 shadow-lg bg-white flex flex-col text-sm py-2"
        style="z-index: 2"
      >
        <span v-if="!payment" class="capitalize text-left px-4 mb-2"
          >Choose Option</span
        >
        <div
          v-for="option in sortOptions"
          :key="option.name"
          class="flex items-center gap-3 cursor-pointer px-4 py-2 hover:bg-newLimeGreen"
          @click="action(option)"
        >
          <img v-if="option.icon" :src="option.icon" alt="" />
          <span class="capitalize text-sm">{{ option.name }}</span>
        </div>
      </div>
    </div>
    <div
      @click="toggleFilter = false"
      :class="!toggleFilter ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>
  </main>
</template>

<script>
import { ref, reactive, onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";

import print from "@/assets/icons/print-icon.svg";
import download from "@/assets/img/newDownload.svg";

const store = useDataStore();
const { query } = store;
const { checkLoading, getAllBranches, getAllDepartments } = storeToRefs(store);

export default {
  props: {
    payment: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update", "removeFilter", "act"],

  data() {
    return {
      dropdownStyle: "top-0",
    };
  },
  setup(props, { emit }) {
    // const emit = defineEmits(["update"]);

    const toggleFilter = ref(false);

    const sortOptions = computed(() => {
      if (props.payment) {
        return [
          {
            name: "Retry Payment",
            value: "retry",
          },
          {
            name: "Download Receipt",
            value: "download",
          },
        ];
      } else {
        return [
          {
            name: "Print",
            icon: print,
          },

          {
            name: "Download",
            icon: download,
          },
        ];
      }
    });

    // Functions

    const action = (acti) => {
      emit("act", acti.name);
      toggleFilter.value = !toggleFilter.value;
    };

    return {
      toggleFilter,
      sortOptions,
      action,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.checkDropdownPosition);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkDropdownPosition);
  },
  methods: {
    checkDropdownPosition() {
      let dropdown = this.$el;
      let windowHeight = window.innerHeight;
      let dropdownRect = dropdown.getBoundingClientRect();
      let dropdownHeight = dropdownRect.height;
      let dropdownTop = dropdownRect.top;
      let dropdownBottom = dropdownRect.bottom;

      if (dropdownTop < 0) {
        this.dropdownStyle = "top-0";
      } else if (dropdownBottom > 210) {
        this.dropdownStyle = "bottom-0";
      } else {
        this.dropdownStyle = "top-0";
      }
    },
  },
};
</script>

<style></style>
