<template>
  <Sidebar
    v-model:visible="toggle"
    :dismissable="false"
    position="full"
    class="relative"
  >
    <template #container>
      <div
        class="w-full px-4 md:w-10/12 mx-auto py-4 h-full overflow-auto no-scrollbar"
      >
        <div
          @click="emit('close')"
          class="bg-newGrey3 w-8 h-8 z-10 rounded-lg flex items-center justify-center cursor-pointer absolute right-4 md:right-28 top-4"
        >
          <i class="pi pi-times text-sm" />
        </div>
        <h4
          class="text-center font-semibold top-0 left-0 mb-5 pb-4 pt-5 w-full bg-white absolute"
        >
          Terms and Conditions
        </h4>

        <div
          class="flex flex-col gap-6 overflow-auto pb-16 mt-14 text-dark-900"
        >
          <div class="rounded-2xl bg-newGrey3 p-4">
            <img src="@/assets/icons/eazipay.svg" alt="" class="mx-auto" />
          </div>

          <p class="text-center text-sm text-dark-800">
            For the purpose of this T&Cs, (client’s name) would be referred to
            as ‘Borrower’ while ‘Eazipay’ would be referred to as ‘Lender’.
          </p>

          <div
            class="grid grid-cols-1 gap-1 rounded-lg border border-outline p-2"
          >
            <div
              v-for="(item, i) in content"
              :key="i"
              class="grid grid-cols-6 gap-1 overflow-hidden rounded bg-white text-xs"
            >
              <div class="col-span-2 bg-newGrey3 p-2">{{ item.title }}</div>
              <div
                class="col-span-4 grid grid-cols-1 gap-1 bg-newGrey3 p-2 font-semibold leading-4 text-dark-900"
                v-html="item.text"
              ></div>
            </div>
          </div>

          <div class="text-sm text-dark-800">
            <p>
              Not withstanding the terms and agreement of this offer, Eazipay
              reserves the right to demand immediate repayment and/or alter the
              terms at any time at its discretion but with notice to the
              borrower.
            </p>
          </div>
          <div class="grid grid-cols-1 gap-4 text-sm text-dark-800">
            <h2 class="text-base font-semibold text-dark-900">APPENDIX (I)</h2>
            <p>
              Repayment Schedule: Loan repayment must be on/or before the due
              date of every month, or when salary is paid. In cases where a
              customer's salary is delayed, the customer should make repayment
              on the day salary is received or risk a penal charge.
            </p>
            <p>
              If a customer leaves the company, he/she should inform the lender
              about the new repayment date if it differs from the previously
              agreed date.
            </p>
            <p>
              Kindly signify your acceptance of this offer by checking the Terms
              and Conditions box on the application page of the Eazipay app.
            </p>
          </div>
        </div>
      </div>
    </template>
  </Sidebar>
</template>

<script setup>
import { ref, computed } from "vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["close"]);

const toggle = computed({
  get() {
    return props.isOpen;
  },
  set(val) {
    emit("close");
  },
});

const content = [
  {
    title: "Repayment schedule",
    text: `
        <p>1. The Facility shall be repaid in full in accordance with the repayment schedule attached to this app. See Appendix (i).</p>
        <p>2. Monthly repayments shall be made on the 25th day of each month and/or will be deducted from the customer’s monthly salary paid into the Facility Account.</p>
        `,
  },
  {
    title: "Condition Precedent",
    text: `
      <p>The facility shall become available for utilization upon satisfying the following conditions:</p>
      <p>1. Receipt of an actual copy of your last 6 months' payslip</p>
      <p>2. Receipt of a duplicate of your employment confirmation letter.</p>
      <p>3. Receipt of a valid identity document</p>
      <p>4. Receipt of bank statement showing the last 6 months. (Preferably statement of account where salary is paid into)</p>
      <p>5. Receipt of Bank Verification Number (BVN)</p>
      <p>6. Receipt of a valid document that carries the house address (utility bill, house rent receipt, etc.)</p>
      <p>6. Completed the application form through the app.</p>
      <p>7. Receipt of a  signed letter from your employer or HR confirming your good standing.</p>
       `,
  },
  {
    title: "Prepayment",
    text: `<p>The borrower may prepay the capital in part at any time without penalty, but full repayment of capital before maturity will attract 1%. The borrower shall pay, in addition to the respective prepaid payment the accrued interest on the loan.</p>`,
  },
  {
    title: "Authority to Debitpayment",
    text: `<p>If you do not pay the admin fees due by the due date, your loan will accrue additional interest at a daily rate of 0.5% until it is fully repaid. The lender has the right to initiate legal action against you if the payment is not made.`,
  },
  {
    title: "Default Interest",
    text: `<p>If you do not pay the admin fees due by the due date, your loan will accrue additional interest at a daily rate of 0.5% until it is fully repaid. The lender has the right to initiate legal action against you if the payment is not made.</p>`,
  },
  {
    title: "Default",
    text: `<p>A. The Borrower shall ensure the Facility Account is duly funded on each payment due date to accommodate the Repayment Instalment on the Facility or in payment of any other sum due and payable under this Agreement. Provided that if the Borrower’s Facility Account is not fully funded as of the repayment due date, then the Lender shall become entitled to:</p>
            <p> 1. recall/cancel the facility and immediately request that the outstanding sum become due and payable; </p>
            <p> 2. send the Borrower’s name to private credit bureaux and the credit risk management system prescribed by the Central Bank of Nigeria;</p>
            <p> 3. take legal action to recover the outstanding amount of the loan. All fees and expenses related to recouping the unpaid balance shall be covered by the borrower.</p>
            <p>B. Where fraud is detected to have been committed by you in connection with your loan application at any time during the lifespan of the loan.</p>
            <p>C. If the borrower breaches any of the terms, conditions, or covenants set forth in this agreement and does not make good on the breach or default after receiving notice of such for twenty-one days. Eazipay reserves the right to call back the facility. </p>
            <p>D. Eazipay also reserves the right to call back the facility in the event that the borrower's creditor receives a court order or injunction affecting the obligor's credit balances with Eazipay or any other financial institution.</p>
            `,
  },
  {
    title: "Security",
    text: `<p>Documents as evidence of rightful ownership of credible personal assets  and/or a Guarantor confirming willingness to guarantee the Facility amount</p>`,
  },
  {
    title: "Late Capital Payment",
    text: `<p>In the event that the borrower fails to make the capital repayment as at when due, a penalty fee of 1% will be charged.</p>`,
  },
  {
    title: "Binding Effect",
    text: `<p>This loan agreement shall be binding upon and inure to the benefit of the parties hereto and their respective heirs, successors, and assigns.</p>`,
  },
  {
    title: "Admission/acknowledgment",
    text: `<p>A.Any written submission or acceptance by the borrower, or by any individual properly authorised on the borrower's behalf, regarding the amount of the borrower's debt to Eazipay, shall be enforceable and conclusive against the borrower. Each account statement provided by Eazipay as an accurate copy taken from its books shall serve as conclusive proof of the borrower's debt to Eazipay.</p>
      <p>B. Eazipay reserves the right to submit, share, and exchange credit information on the borrower with the Credit Risk Management System (CRMS) and other CBN licensed credit bureaus, regulatory and law enforcement agencies, reference bodies, financial institutions, and other relevant third parties, whether local or foreign. Such information shall include, but not be limited to, those on the borrower and his varying business interests, the status of the borrower’s credit facilities with Eazipay, and any other information that may be required to assist in an investigation or in making the overall lending decision about the borrower.</p>`,
  },
  {
    title: "Severability",
    text: `<p>A. If any provision of this Agreement is held to be illegal, invalid or unenforceable in whole or in part, the legality, validity and enforceability of the remaining provisions shall not in any way be affected or impaired thereby. </p>
      <p>B. Any term or provision found by a court of competent jurisdiction to be invalid, illegal or otherwise unenforceable shall be deemed modified to the extent necessary to render such term or provision enforceable, and the rights and obligations of the Parties shall be construed and enforced accordingly, preserving to the fullest permissible extent the rights and obligations of the Parties.</p>`,
  },
  {
    title: "Assignment",
    text: `<p>This loan offer is binding for both Eazipay and the borrower, as well as their successors and those to whom they transfer their rights or obligations. However, the borrower cannot transfer their rights or obligations without getting Eazipay's written permission first. Eazipay cannot unreasonably withhold that permission.</p>`,
  },
  {
    title: "Governing Law",
    text: `<p>The laws of Nigeria shall govern this loan agreement and be applied in its interpretation.</p>`,
  },
];
</script>

<style></style>
