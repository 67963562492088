<template>
  <main>
    <div class="w-full banner mt-9 rounded-t-2xl">
      <!-- <img src="./assets/store_banner.webp" alt="" /> -->
    </div>
    <div class="box bg-white">
      <div class="task-bar mt-3">
        <h1 class="tag">Hot and Trending</h1>

        <button
          style="
            /* Button/Button */
            font-family: Aeonik;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            letter-spacing: 0.1px;
          "
          class="flex items-center gap-2 text-primary focus:outline-none bg-none"
          @click="openWindow('https://hrstores.eazipay.ng')"
        >
          <span> See all products</span>
          <i class="pi pi-chevron-right"></i>
        </button>
      </div>

      <div
        class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-6 gap-y-8"
      >
        <div
          v-for="(item, i) in merged"
          :key="i"
          class="product-layout cursor-pointer"
          :class="
            showOnHover[`${item.name}-${item.model}-${item.specs}`]
              ? 'box-style'
              : undefined
          "
          @click="openWindow(item.link)"
          @mouseenter="handleEnter(`${item.name}-${item.model}-${item.specs}`)"
        >
          <div class="image overflow-hidden">
            <img
              v-if="itemImages[`${item.name}-${item.model}-${item.specs}`]"
              :src="itemImages[`${item.name}-${item.model}-${item.specs}`]"
              :alt="item.name"
              class="contain"
            />
          </div>

          <h3 class="category-text">CATEGORY</h3>
          <p class="model-text">
            {{ `${item.name} ${item.model}` }} <br />
            {{ `${item.specs}` }}
          </p>

          <div
            v-show="showOnHover[`${item.name}-${item.model}-${item.specs}`]"
            class="w-full mt-3"
          >
            <easiButton size="small" class="w-full">Shop Now</easiButton>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import merged from "@/utils/hr_stores.js";
import { ref, onMounted } from "vue";
const itemImages = ref({});
const showOnHover = ref({});

const loadImages = async () => {
  const imagePromises = merged.map(async (item) => {
    try {
      // Ensure a unique key, using `name` + `model`
      const uniqueKey = `${item.name}-${item.model}-${item.specs}`;
      showOnHover.value[uniqueKey] = false;
      const imageModule = await item.image();
      itemImages.value[uniqueKey] = imageModule.default; // Store image URL
    } catch (error) {
      console.error(
        `Error loading image for ${item.name} ${item.model}:`,
        error
      );
    }
  });

  await Promise.all(imagePromises); // Ensure all images are loaded
};
function openWindow(link) {
  window.open(link, "_blank");
}

function handleEnter(key) {
  for (const k in showOnHover.value) {
    showOnHover.value[k] = false;
  }
  showOnHover.value[key] = true;
}
onMounted(async () => {
  await loadImages();
});
</script>

<style scoped>
.banner {
  background-color: linear-gradient(
    90deg,
    #053d32 0.25%,
    #198c76 49.6%,
    #053d32 98.95%
  );
  background-image: url(./assets/store_banner.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 187px;
  overflow: hidden;
}

.box {
  display: flex;
  padding: var(--spacing-xl, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.task-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.tag {
  color: var(--New-Styles-Text-Heading, #292a29);
  text-align: center;

  /* Caption/Sub Header/H5/Bold */
  font-family: Aeonik;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}

.product-layout {
  display: flex;
  padding: var(--spacing-xl, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 24px;
  background: var(--Shade-White, #fff);
}

.image {
  height: 184px;
  object-fit: contain;
}

.category-text {
  color: var(--New-Styles-Text-Body-Normal, #8d8e8d);

  /* Caption/Small/CAP */
  font-family: Aeonik;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.model-text {
  overflow: hidden;
  color: var(--New-Styles-Text-Body-dark, #515251);
  text-overflow: ellipsis;

  /* Body/Small/1/Regular */
  font-family: Aeonik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.contain {
  width: 100%; /* Ensures the image width matches the container width */
  height: 100%; /* Ensures the image height matches the container height */
  object-fit: contain; /* Ensures the image scales to fit without being cut off */
  max-width: 100%; /* Prevents the image from stretching beyond container */
  max-height: 100%;
}

.box-style {
  background: #ffffff;
  /* width: 100%;

  height: 240px; */
  border-radius: 16px;
  /* border: 1px solid #f8f8f8; */
  box-shadow: 0px 6px 12px 0px rgba(170, 170, 170, 0.08);

  padding: 8px;
  /* display: flex; */
  /* gap: 8px; */
}
</style>
