<template>
  <div
    class="flex flex-col gap-3.5 p-4 bg-newGrey3 border border-outlineGray rounded-2xl"
  >
    <h4 class="text-base font-semibold">Your next payment</h4>
    <div
      style="box-shadow: 0px 9px 18px 0px #aaaaaa26"
      class="bg-white p-4 rounded-2xl text-sm flex items-center justify-between"
    >
      <div class="flex flex-col gap-0.5">
        <span class="text-xs text-dark-600">Amount:</span>
        <strong>{{ formatAmount(amount) }}</strong>
      </div>
      <div class="flex flex-col gap-0.5">
        <span class="text-xs text-dark-600">Due date:</span>
        <span>{{ formatDate(dueDate) }}</span>
      </div>
    </div>

    <div
      @click="emit('click')"
      style="box-shadow: 0px 9px 18px 0px #aaaaaa26"
      class="cursor-pointer text-primary py-1.5 font-medium px-4 w-full rounded-full bg-white text-xs text-center"
    >
      Tap to make payment now
    </div>
  </div>
</template>

<script setup>
import { helperFunctions } from "@/composable/helperFunctions";
const { formatAmount, formatDate } = helperFunctions;

const props = defineProps({
  amount: Number,
  dueDate: String,
});

const emit = defineEmits(["click"]);
</script>

<style></style>
