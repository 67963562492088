<template>
  <main v-if="!viewLoan" class="md:p-4 p-1">
    <div class="flex justify-end sm:justify-start items-center">
      <h4
        class="md:mt-3 md:text-sm md:text-left text-xs font-medium text-headerText capitalize sm:uppercase"
      >
        Quick Loans / Manage Loans
      </h4>
    </div>

    <h2 class="text-left mt-4 mb-2 md:mb-8 font-bold text-lg md:text-2xl">
      Manage Loans
    </h2>

    <!-- <div
      class="text-left flex flex-col items-center justify-center gap-6 mb-8"
      v-if="(allLoans && !allLoans.length) || !allLoans"
    >
      <div
        class="flex md:-mt-2 justify-center md:justify-end md:pr-20 my-10 md:my-0"
      >
        <img
          style="width: 600px; max-width: 100%"
          src="@/assets/img/moneybag.png"
          alt=""
        />
      </div>
      <h4 class="mb-4 text-headerText md:text-xl md:font-medium">
        Still empty! You have not applied for any loan
      </h4>
      <easiButton
        @click="router.push('/loan/application/view')"
        rounded="xl"
        size="large"
        class="text-xl hidden md:block"
        >Apply For New Loan</easiButton
      >
      <easiButton
        @click="router.push('/loan/application/view')"
        block
        class="block md:hidden mt-5"
        >Apply For New Loan</easiButton
      >
    </div> -->

    <div>
      <div
        class="flex items-center md:justify-between md:gap-8 gap-2 w-full w-full"
      >
        <div class="md:w-full w-3/5">
          <easiCard
            text="small"
            title="Repayment Balance"
            :amount="repaymentBalance || 0"
            :loan="true"
            class="p-20"
          ></easiCard>
        </div>

        <div
          v-if="getDateFromDay(earliestDate && earliestDate)"
          class="md:w-7/12 w-2/5 flex flex-col bg-secondary md:mx-auto border border-secondary shadow-md rounded-2xl overflow-hidden bg-white"
        >
          <div
            class="text-white p-2 bg-secondary md:text-center text-left font-medium text-xs md:text-sm shadow-md"
          >
            Next Repayment Date
          </div>

          <div
            class="w-full bg-white border-r lg:border-r-0 border-secondary flex flex-col gap-2 items-center justify-center px-1 lg:px-4 py-6"
          >
            <div class="flex items-center justify-center gap-2">
              <img src="@/assets/icons/calendar.svg" alt="" />
              <span class="text-sm">{{
                getDateFromDay(earliestDate && earliestDate).dateString || "N/A"
              }}</span>
            </div>
            <h4 class="text-info font-bold textlg">
              {{
                getDateFromDay(earliestDate && earliestDate).formattedDate ||
                "N/A"
              }}
            </h4>
          </div>
        </div>
      </div>

      <hr class="my-6 border-0.5 border-dividerColor text-dividerColor" />

      <div
        class="hidden md:flex text-center w-full mt-6 border border-outlineGray rounded-xl tracking-wide justify-start gap-x-2 p-2 text-xs font-medium cursor-pointer bg-white"
      >
        <span
          v-for="opt in filterOpts"
          :key="opt.value"
          @click="filterLoans(opt.value)"
          class="w-full text-XS rounded-lg md:px-5 md:py-2 px-3 py-2"
          :class="
            activeFilter === opt.value
              ? 'bg-primary shadow-2xl text-white'
              : 'bg-newGrey'
          "
          >{{ opt.label }} ({{ opt.length }})</span
        >
      </div>

      <div class="w-52">
        <easiSelectInput2
          class="md:hidden font-bold"
          :options="filterOpts"
          :value="activeFilter"
          @update="filterLoans($event)"
        ></easiSelectInput2>
      </div>

      <div v-if="allLoans && allLoans.length" class="flex flex-col gap-4 mt-5">
        <div v-for="(loan, i) in allLoans" :key="i">
          <TransactionCard
            @click="router.push({ query: { id: loan._id } }), (viewLoan = true)"
            :loan="loan"
          />
          <hr
            class="mt-4 last:hidden border-0.5 border-dividerColor text-dividerColor"
          />
        </div>
      </div>

      <div
        v-else
        class="container mx-auto items-center justify-center w-full sm:w-11/12 md:w-10/12 my-6 grid grid-cols-2 gap-2 mt-12"
      >
        <div class="flex flex-col text-left col-span-2 md:col-span-1">
          <h1 class="text-secondary text-5xl md:text-7xl font-semibold mb-4">
            Oops!
          </h1>
          <div class="text-sm md:text-lg">
            Sorry we could not find a result for your search
          </div>
          <p class="hidden text-xs md:text-sm mt-2">
            Check your spelling or try a different name. Make sure you search by
            <span class="text-secondary font-medium">Name</span>,
            <span class="text-secondary font-medium">Employee ID</span> or
            <span class="text-secondary font-medium">Department</span>
          </p>
        </div>
        <div class="col-span-2 md:col-span-1">
          <img src="@/assets/icons/search_null.svg" alt="" />
        </div>
      </div>
    </div>
  </main>

  <LoanDetails v-else />

  <easiLoading v-if="loading" />
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";
import { useRoute, useRouter } from "vue-router";

import LoanDetails from "@/components/loan/loanDetails";
import TransactionCard from "@/components/loan/TransactionCard";

const store = useDataStore();
const toast = useToast();
const route = useRoute();
const router = useRouter();
const { formatAmount, formatDateString } = helperFunctions;

const { query } = store;

const allLoans = computed(() => store.getAllLoans && store.getAllLoans.data);
const loading = ref(false);
const filterOpts = ref([
  {
    label: "All Loans",
    value: null,
    length: allLoans.value && allLoans.value.length ? allLoans.value.length : 0,
  },
  {
    label: "Active Loans",
    value: "APPROVED",
    length:
      allLoans.value && allLoans.value.length
        ? allLoans.value.filter((loan) => loan.status === "ACTIVE").length
        : 0,
  },
  {
    label: "Pending Approval",
    value: "PENDING",
    length:
      allLoans.value && allLoans.value.length
        ? allLoans.value.filter((loan) => loan.status === "PENDING").length
        : 0,
  },
  {
    label: "Completed Loans",
    value: "COMPLETED",
    length:
      allLoans.value && allLoans.value.length
        ? allLoans.value.filter((loan) => loan.status === "COMPLETED").length
        : 0,
  },
  {
    label: "Canceled Loans",
    value: "CUSTOMER_CANCELED",
    length:
      allLoans.value && allLoans.value.length
        ? allLoans.value.filter((loan) => loan.status === "CUSTOMER_CANCELED")
            .length
        : 0,
  },
  {
    label: "Declined Loans",
    value: "DECLINED",
    length:
      allLoans.value && allLoans.value.length
        ? allLoans.value.filter((loan) => loan.status === "DECLINED").length
        : 0,
  },
]);
const activeFilter = ref(null);

async function queryLoan(status) {
  loading.value = true;
  try {
    await query({
      endpoint: "ListLoans",
      payload: {
        paging: {
          limit: 1000,
          skip: 0,
          search: "",
        },
        filter: {
          type: null,
          status,
        },
        sort: {
          createdAt: -1,
        },
      },
      service: "LOAN",
      storeKey: "loans",
    });
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
}

// queryLoan();

async function filterLoans(stat) {
  activeFilter.value = stat;
  await queryLoan(activeFilter.value);
}

const repaymentBalance = computed(() => {
  if (allLoans.value) {
    let amt = 0;
    amt = allLoans.value.reduce((acc, curr) => {
      if (curr.status === "APPROVED") {
        return acc + curr.totalRepaymentAmount;
      }
      return acc;
    }, 0);
    return amt;
  }
  return 0;
});

const viewLoan = computed(() => {
  return route.query && route.query.id ? true : false;
});

onMounted(async () => {
  await queryLoan(null);
  if (route.query && route.query.id) {
    viewLoan.value = true;
  } else {
    viewLoan.value = false;
  }
});

function getDateFromDay(d) {
  const ds = d;
  const dateObj = new Date(ds);
  const dateString = dateObj.toLocaleString("en-us", { weekday: "long" });

  const formattedDate = formatDateString(d);
  return { dateString, formattedDate };
}

function getEarliestDate(arr) {
  if (arr && arr.length) {
    const currentDate = new Date(); // Get the current date
    const dueDates = arr;

    const earliestDate = dueDates
      .filter((dueDate) => new Date(dueDate.dueDate) > currentDate) // Filter out dates earlier than or equal to the current date
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))[0];
    // console.log(arr, earliestDate.dueDate);
    return earliestDate ? earliestDate.dueDate : null;
  }
}

const earliestDate = computed(() => {
  const breakdownArrs =
    allLoans.value && allLoans.value.length
      ? allLoans.value.map((obj) => {
          return obj.repaymentBreakDown.flat();
        })
      : [];
  const dt = getEarliestDate(breakdownArrs.flat());
  console.log(dt, "dd");

  return dt ? dt : null;
});
</script>

<style></style>
