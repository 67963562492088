<template>
  <easiRoundButton @click="handleGoBack" class="absolute z-20 top-4 left-4">
    <i class="pi pi-chevron-left text-sm" />
  </easiRoundButton>
  <div
    v-if="!isPreviewLoan"
    class="p-4 md:p-6 flex flex-col gap-5 h-full overflow-auto"
  >
    <h4 class="text-center font-semibold mb-6 mt-2">Get Business Loan</h4>

    <div>
      <h4 class="text-sm text-dark-800 font-medium">How much do you need?</h4>
      <easiTextInput
        v-model="args.loanAmount"
        :error="errorRules.loanAmount"
        format
        label="Enter amount"
      />
    </div>
    <div class="grid grid-cols-1 gap-2">
      <h4 class="text-sm text-dark-800 font-medium">
        Select repayment duration
      </h4>
      <easiSelectInput2
        placeholder="Select repayment duration"
        required
        hideCheckBox
        :options="durationOptions"
        @update="args.duration = $event"
        :error="errorRules.duration"
        :value="args.duration"
      />

      <LoanAmountCard
        small
        plain
        title="Monthly repayment <br/> amount "
        :value="monthlyRepaymentAmount"
      />
    </div>

    <div>
      <h4 class="text-sm text-dark-800 font-medium mb-2">
        Select loan purpose (optional)
      </h4>

      <easiSelectInput2
        placeholder="Select loan purpose"
        required
        hideCheckBox
        showOthers
        :options="purposeOptions"
        @update="args.purpose = $event"
        :value="args.purpose"
        :error="errorRules.purpose"
      />
    </div>

    <div>
      <h4 class="text-sm text-dark-800 font-medium mb-2">
        Upload 6 months bank statement of active account
      </h4>
      <UploadComponent
        classic
        @fileUrl="args.documents.sixMonthsBankStatement = $event"
        :allowedTypes="['pdf']"
        label="2mb Max"
        :size="2000000"
        :error="errorRules.sixMonthsBankStatement"
      />
    </div>

    <div class="grid grid-cols-2 gap-3">
      <easiButton
        @click="handleClose"
        variant="text"
        background="#11453B1A"
        block
        >Cancel</easiButton
      >
      <easiButton :loading="loading" @click="handlePreview" block>
        <div class="flex items-center gap-2">
          Proceed
          <i class="pi pi-arrow-right" />
        </div>
      </easiButton>
    </div>
  </div>

  <PreviewLoan
    v-else
    :isOpen="isPreviewLoan"
    @submit="submit"
    :data="previewData"
    type="business"
    :loading="loading"
  />

  <Teleport to="#loan-success-alert">
    <easiAlert
      v-if="isAlert"
      :caption="`Your application has been received. 
    A member of our team will be in touch.`"
    >
      <div class="pb-4 px-6">
        <easiButton
          size="medium"
          variant="text"
          background="#11453B1A"
          @click="closeAlert"
          block
          >Dismiss!</easiButton
        >
      </div>
    </easiAlert>

    <easiLoading v-if="loading" />
  </Teleport>
</template>

<script setup>
import LoanAmountCard from "../LoanAmountCard.vue";
import UploadComponent from "@/components/global/UploadComponent";
import PreviewLoan from "../PreviewLoan.vue";

import { previewLoan, createLoan } from "@/services/loan";
import { computed, reactive, ref, watch } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";

const { formatDate, formatAmount, uploadFileToServer } = helperFunctions;
const toast = useToast();
const props = defineProps({
  eligibilityData: Object,
});
const emit = defineEmits(["close", "goBack"]);

const durationOptions = computed(() => {
  const durations = [];
  const length = props.eligibilityData ? props.eligibilityData.maxDuration : 12;
  for (let i = 1; i <= length; i++) {
    const str = `${i} month${i > 1 ? "s" : ""}`;
    durations.push({
      label: str,
      value: str,
    });
  }
  return durations;
});

const purposeOptions = computed(() => {
  const purposes = [
    "Hire talents",
    "Business expansion",
    "Asset purchase",
    "Invoice financing",
  ];

  return purposes.map((el) => {
    return {
      label: el,
      value: el,
    };
  });
});

const loading = ref(false);
const previewData = ref(null);
const isPreviewLoan = ref(false);
const isAlert = ref(false);

const uploadedDocs = ref({
  sixMonthsBankStatement: null,
});

const interestRate = 5.5;

const monthlyRepaymentAmount = computed(() => {
  const { loanAmount, duration } = args.value;
  if (!loanAmount || !duration) return 0;

  const interestAmount = (interestRate * loanAmount) / 100;
  const durationInt = +duration.split(" ")[0];

  return (interestAmount + loanAmount) / durationInt;
});

const args = ref({
  loanAmount: "",
  duration: `${props.eligibilityData.maxDuration} months`,
  durationPeriod: "MONTH",
  purpose: "",
  documents: {
    sixMonthsBankStatement: null,
  },
  termsAndConditionsAccepted: true,
  type: "SMALL_BUSINESS_LOAN",
});

const errorRules = reactive({
  loanAmount: false,
  duration: false,
  purpose: false,
  sixMonthsBankStatement: false,
});

const checkAmount = () => {
  const { minBorrowableAmount, eligibleAmount } = props.eligibilityData;

  if (args.value.loanAmount < minBorrowableAmount) {
    errorRules.loanAmount = `Amount must not be less than ${formatAmount(
      minBorrowableAmount
    )}`;
    return true;
  } else if (args.value.loanAmount > eligibleAmount) {
    errorRules.loanAmount = `Amount must not exceed ${formatAmount(
      eligibleAmount
    )}`;
    return false;
  }
  errorRules.loanAmount = false;
  return true;
};

watch(() => args.value.loanAmount, checkAmount);

const validateFields = () => {
  resetError();
  if (!checkAmount()) return false;
  if (!args.value.loanAmount) {
    errorRules.amount = "Amount is required";
    return false;
  } else if (!args.value.duration) {
    errorRules.duration = "Duration  is required";
    return false;
  } else if (!args.value.documents.sixMonthsBankStatement) {
    errorRules.sixMonthsBankStatement = "Bank statement  is required";
    return false;
  }

  resetError();
  return true;
};

function resetError() {
  Object.keys(errorRules).map((key) => {
    errorRules[key] = false;
  });
}

function clearFields() {
  args.value.loanAmount = "";
  args.value.duration = "";
  args.value.purpose = "";
  args.value.documents = {
    sixMonthssixMonthsBankStatement: null,
  };
  args.value.termsAndConditionsAccepted = true;
}

function handleGoBack() {
  if (isPreviewLoan.value) return (isPreviewLoan.value = false);
  emit("goBack");
}

function handleClose() {
  isPreviewLoan.value = false;
  emit("close");
  clearFields();
  resetError();
}

function closeAlert() {
  isAlert.value = false;
  handleClose();
}

const handlePreview = async () => {
  if (!validateFields()) return;
  const payload = { ...args.value };
  payload.duration = +payload.duration.split(" ")[0];

  try {
    loading.value = true;
    //// upload documents //
    payload.documents.sixMonthsBankStatement = await uploadFileToServer(
      payload.documents.sixMonthsBankStatement
    );

    //save the uploaded docs so it can be used when creating loan
    uploadedDocs.value = payload.documents;

    const res = await previewLoan(payload);

    if (res.success) {
      previewData.value = { ...res.data, duration: args.value.duration };
      console.log(previewData.value);
      isPreviewLoan.value = true;
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    toast.error(error.message);
  } finally {
    loading.value = false;
  }
};

const submit = async () => {
  if (!validateFields()) return;
  const payload = { ...args.value };
  payload.duration = +payload.duration.split(" ")[0];
  payload.documents = uploadedDocs.value;

  try {
    loading.value = true;

    console.log(payload);
    const res = await createLoan(payload);
    if (res.success) {
      isAlert.value = true;
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    toast.error(error.message);
  } finally {
    loading.value = false;
  }
};
</script>

<style></style>
