<template>
  <div class="grid grid-cols-1 gap-5 px-4">
    <div>
      <h4 class="text-sm">How much are you requesting?</h4>
      <easiTextInput
        v-model="count"
        :error="errorRules.count"
        readonly
        type="number"
        inputmode="numeric"
        label="Select instalment count"
        placeholder="Select instalment count"
        @click="showSelectCountButtons = true"
        :prependSvg="chevronIcon"
      />
    </div>

    <LoanAmountCard
      v-if="count && !errorRules.count && !showSelectCountButtons"
      plain
      title="Loan amount <br/> to be paid"
      :value="loanAmountToBePaid"
    />

    <div v-if="count && !errorRules.count && !showSelectCountButtons">
      <div
        :class="[
          errorRules.balance
            ? 'border-newError bg-newErrorLight text-newError'
            : 'border-successStatus bg-successLight text-successStatus',
        ]"
        class="flex items-center justify-between rounded-2xl border p-4 py-5 text-sm"
      >
        <span>Your Wallet Balance</span>
        <span>{{ formatAmount(walletBalance) }}</span>
      </div>
      <span v-if="errorRules.balance" class="text-xs text-error">{{
        errorRules.balance
      }}</span>
    </div>

    <easiButton
      block
      v-if="!showSelectCountButtons"
      @click="submit"
      :disabled="!count"
    >
      {{ errorRules.balance ? "Fund Wallet & Pay" : "Make payment" }}
    </easiButton>
  </div>

  <div
    v-if="showSelectCountButtons"
    style="box-shadow: 0px -20px 48px 0px rgba(170, 170, 170, 0.19)"
    class="w-full rounded-t-3xl p-4 pb-0"
  >
    <h3 class="mb-4 text-center text-lg">Select instalment count</h3>

    <div class="grid grid-cols-3 gap-3">
      <span
        @click="selectCount(num)"
        :class="[
          installmentsLeft < num ? 'pointer-events-none opacity-50' : '',
          count === num
            ? 'border-newError bg-newErrorLight text-newError'
            : 'border-outlineGray',
        ]"
        class="cursor-pointer rounded-xl border p-2 py-2.5 text-center text-sm"
        v-for="num in 12"
        :key="num"
        >{{ num }}
      </span>
    </div>
  </div>
</template>

<script setup>
import LoanAmountCard from "../LoanAmountCard.vue";
import chevronIcon from "@/assets/icons/chevron-right-dark.svg";
import { computed, reactive, ref, watch } from "vue";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
const { formatAmount } = helperFunctions;

const store = useDataStore();
const props = defineProps({
  loan: Object,
});

const emit = defineEmits(["submit", "fundWallet"]);

const walletBalance = computed(
  () => (store.getWalletBalance && store.getWalletBalance.balance) || 0
);

const installmentsLeft = computed(() => {
  if (!props.loan) return 12;
  return props.loan.installment.summary.totalUnpaidInstallments;
});

const showSelectCountButtons = ref(false);
const count = ref();
const errorRules = reactive({
  count: false,
  balance: false,
});

const loanAmountToBePaid = computed(() => {
  if (!count.value || !props.loan) return 0;

  const installAmount = props.loan.installment.summary.nextInstallmentAmount;
  return count.value * installAmount;
});

const checkAmount = () => {
  console.log(loanAmountToBePaid.value, walletBalance.value);
  errorRules.balance =
    loanAmountToBePaid.value > walletBalance.value
      ? "Insufficient balance"
      : false;
};

watch([loanAmountToBePaid, walletBalance], checkAmount);

const selectCount = (arg) => {
  count.value = arg;
  showSelectCountButtons.value = false;
};

const submit = async () => {
  if (errorRules.balance) {
    //Fund wallet
    emit("fundWallet");
    return;
  }

  const payload = {
    clearAll: null,
    installmentCount: count.value,
    loanId: props.loan._id,
  };

  emit("submit", payload);
};
</script>

<style></style>
