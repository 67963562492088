const laptop_data = [
  {
    name: "HP",
    model: "Elitebook 840 g3",
    specs: "8gig/256gig\nCore i7",
    Source: "pearl City (+2348073044467)",
    "Price (New)": 800000,
    "Price( Refurbished)": 250000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/laptops/HP Elitebook 840 G3.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/hp-840-g3-core-i5-8gb-256gb/",
  },

  {
    name: "HP",
    model: "Victus 16",
    specs: "512GB, 16GB RAM, Core i5, 4GB Nvidia, Keyboard light",
    Source: "Chris Gadgets",
    "Price (New)": 1200000,
    "Cost Plus Mark up": 1260000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/laptops/HP Victus 16 512GB.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/hp-victus/",
  },
  {
    name: "HP",
    model: "Victus 16",
    specs: "1TB SSD, 16GB RAM, Core i7, 6GB Nvidia, Keyboard light",
    Source: "Chris Gadgets",
    "Price (New)": 1850000,
    "Cost Plus Mark up": 1942500,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/laptops/HP Victus 16 1TB.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/hp-victus-16/",
  },
  {
    name: "HP",
    model: "Elitebook 1040 G9",
    specs: "512GB SSD, 8GB RAM, Core i5, 14-inch display",
    Source: "Chris Gadgets",
    "Price (New)": 1220000,
    "Cost Plus Mark up": 1281000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/laptops/HP Elitebook 1040 G9.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/hp-elitebook-1040-g9/",
  },
  {
    name: "HP",
    model: "Probook 440",
    specs: "512GB SSD, 8GB RAM, Core i5, 14-inch display",
    Source: "Chris Gadgets",
    "Price (New)": 1050000,
    "Cost Plus Mark up": 1102500,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/laptops/HP Probook 440.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/hp-probook-440/",
  },
  {
    name: "HP",
    model: 14,
    specs: "512GB SSD, 8GB RAM, Core i3",
    Source: "Chris Gadgets",
    "Price( Refurbished)": 620000,
    "Cost Plus Mark up": 651000,
    image: () =>
      import("@/views/admin/hr-stores/assets/gadgets/laptops/HP 14 512GB.webp"),
    link: "https://hrstores.eazipay.ng/product/hp-14/",
  },
  //   {
  //     "name": "HP",
  //     "model": "EliteBook 840 G3",
  //     specs: "256GB SSD, 8GB RAM, Core i5, 14-inch display",
  //     Source: "Chris Gadgets",
  //     "Price( Refurbished)": 360000,
  //     "Cost Plus Mark up": 378000,
  //   },
  {
    name: "LG",
    model: "LG 32GN600-B",
    specs:
      "32-inch UltraGear QHD (2560 x 1440) 165Hz HDR10 Monitor with FreeSync™ Premiumm 3-Side Virtually Borderless Display",
    "Price (New)": 620000,
    "Cost Plus Mark up": 651000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/laptops/LG 32GN600-B.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/lg-32gn600-b/",
  },
  {
    name: "MSI",
    model: "Optix G271CQR",
    specs: "27-inch WQHD 2560 x 1440 165Hz AMD FreeSync Curved Gaming Monitor",
    "Price (New)": 520000,
    "Cost Plus Mark up": 546000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/laptops/MSI Optix G271CQR.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/msi-optix-g271cqr/",
  },
  {
    name: "Samsung",
    model: "LS27CG552ENXZA",
    specs:
      "27-inch Odyssey G55C QHD Resolution (2560 x 1440) 165Hz 1ms(MPRT) Curved Gaming Monitor AMD FreeSync",
    "Price (New)": 640000,
    "Cost Plus Mark up": 672000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/laptops/Samsung LS27CG552ENXZA.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/samsung-ls27cg552enxza/",
  },
];

const phone_data = [
  {
    name: "Samsung",
    model: "Note 20",
    specs: "128GB",
    Source: "Chris Gadgets",
    "Price (New)": 400000,
    "Cost Plus Mark up": 420000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/phones/Samsung-Galaxy-Note-20.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/samsung-note-20-2/",
  },
  {
    name: "Samsung",
    model: "Note 20",
    specs: "256gb",
    Source: "Chris Gadgets",
    "Price (New)": 400000,
    "Cost Plus Mark up": 420000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/phones/Samsung-Galaxy-Note-20.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/samsung-note-20-3/",
  },
  {
    name: "Samsung",
    model: "Galaxy Tab S7 FE",
    specs: "64GB",
    Source: "Chris Gadgets",
    "Price (New)": 600000,
    "Cost Plus Mark up": 630000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/phones/Samsung-Galaxy-Tab-S7-Fe.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/samsung-galaxy-tab-s7-fe/",
  },
  {
    name: "Samsung",
    model: "Galaxy Tab S Lite",
    specs: "",
    Source: "Chris Gadgets",
    "Price (New)": 530000,
    "Cost Plus Mark up": 556500,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/phones/Samsung-Galaxy-Tab-S-Lite.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/samsung-galaxy-tab-s-lite/",
  },
  {
    name: "Samsung",
    model: "Galaxy Tab A9 Plus",
    specs: "64GB",
    Source: "Chris Gadgets",
    "Price (New)": 380000,
    "Cost Plus Mark up": 399000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/phones/Samsung-Galaxy-Tab-A9-+.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/samsung-galaxy-tab-a9-plus/",
  },
  {
    name: "Samsung",
    model: "Galaxy Tab A8",
    specs: "64GB",
    Source: "Chris Gadgets",
    "Price (New)": 420000,
    "Cost Plus Mark up": 441000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/phones/SAMSUNG-Galaxy-Tab-A8.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/samsung-galaxy-tab-a8/",
  },
  {
    name: "Samsung",
    model: "Galaxy Tab S9",
    specs: "128GB",
    Source: "Chris Gadgets",
    "Price (New)": 1120000,
    "Cost Plus Mark up": 1176000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/phones/Samsung-Galaxy-Tab-S9.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/samsung-galaxy-tab-s9/",
  },
  {
    name: "Samsung",
    model: "Galaxy 24",
    specs: "128GB",
    Source: "Chris Gadgets",
    "Price (New)": 1100000,
    "Cost Plus Mark up": 1155000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/phones/SAMSUNG-Galaxy-24.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/samsung-galaxy-s24/",
  },
  {
    name: "Samsung",
    model: "Galaxy S24 Ultra",
    specs: "256GB",
    Source: "Chris Gadgets",
    "Price (New)": 1485000,
    "Cost Plus Mark up": 1559250,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/phones/Samsung-Galaxy-S24-Ultra.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/samsung-galaxy-s24-ultra/",
  },
  {
    name: "Samsung",
    model: "Galaxy Fold 6",
    specs: "256GB",
    Source: "Chris Gadgets",
    "Price (New)": 2250000,
    "Cost Plus Mark up": 2362500,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/phones/SAMSUNG-Galaxy-Fold-6.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/samsung-galaxy-z-fold-6/",
  },
  {
    name: "Samsung",
    model: "Galaxy A55",
    specs: "256GB",
    Source: "Chris Gadgets",
    "Price (New)": 620000,
    "Cost Plus Mark up": 651000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/phones/Samsung-Galaxy-A55.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/samsung-galaxy-a55/",
  },
  {
    name: "Samsung",
    model: "Galaxy A35",
    specs: "128GB",
    Source: "Chris Gadgets",
    "Price (New)": 430000,
    "Cost Plus Mark up": 451500,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/phones/Samsung-Galaxy-A35.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/samsung-a35/",
  },
  {
    name: "Samsung",
    model: "Galaxy A25",
    specs: "",
    Source: "Chris Gadgets",
    "Price (New)": 380000,
    "Cost Plus Mark up": 399000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/phones/SAMSUNG-Galaxy-A25.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/samsung-galaxy-a25/",
  },
  {
    name: "Samsung",
    model: "Galaxy A24",
    specs: "",
    Source: "Chris Gadgets",
    "Price (New)": 320000,
    "Cost Plus Mark up": 336000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/phones/Samsung-Galaxy-A24.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/samsung-a24/",
  },
  {
    name: "Samsung",
    model: "Galaxy A15",
    specs: "",
    Source: "Chris Gadgets",
    "Price (New)": 280000,
    "Cost Plus Mark up": 294000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/phones/Samsung-Galaxy-A15.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/samsung-galaxy-a15/",
  },
  {
    name: "Samsung",
    model: "Galaxy A05",
    specs: "",
    Source: "Chris Gadgets",
    "Price (New)": 200000,
    "Cost Plus Mark up": 210000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/phones/Samsung-Galaxy-A05.webp"
      ),
    link: "https://hrstores.eazipay.ng/product/samsung-galaxy-a05/",
  },

  {
    name: "iPhone",
    model: "iPhone 15 Pro Max",
    specs: "128gb",
    Source: "Chris Gadgets",
    "Price (New)": 1780000,
    "Cost Plus Mark up": 1869000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/phones/Apple-iPhone-15-Pro-Max.webp"
      ),
  },
  {
    name: "Iphone",
    model: 15,
    specs: "265gb",
    Source: "Jiji",
    "Price (New)": 1480000,
    image: () =>
      import(
        "@/views/admin/hr-stores/assets/gadgets/phones/Apple-iPhone-15.webp"
      ),
  },
];

const merged = phone_data.concat(laptop_data);

export default merged;
