<template>
  <div class="w-full flex flex-col justify-center mx-auto">
    <div class="mt-8 self-center">
      <Stepper
        @changeActive="setActive($event)"
        :optionData="data"
        :activeNum="activeOption"
      />
    </div>
    <div class="md:my-5">
      <span class="text-dark-800 text-left text-sm">
        Kindly answer the following questions (all fields are required)
      </span>
    </div>

    <businessForm
      @business="
        ($event) => {
          args.businessInformation = $event;
          updateStoreValue($event, 'businessInformation');
          setActive(1);
        }
      "
      v-if="activeOption === 0"
    />
    <uploadForm
      @docs="
        ($event) => {
          args.loanDocuments = $event;
          updateStoreValue($event, 'loanDocuments');
          setActive(2);
        }
      "
      @back="setActive(0)"
      v-if="activeOption === 1"
    />
    <loanForm
      @loan="
        ($event) => {
          args.paymentDetails = $event.paymentDetails;
          args.guarantorInformation = $event.guarantorInformation;
          args.input.loanAmount = $event.loanAmount;
          args.input.duration = $event.duration;
          args.input.durationPeriod = $event.durationPeriod;
          $emit('done', args);
        }
      "
      @back="setActive(1)"
      v-if="activeOption === 2"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useDataStore } from "@/stores/data.js";
import Stepper from "@/components/global/MobileStepper.vue";
import businessForm from "@/components/loan/forms/payrollCoverForms/businessInfo.vue";
import loanForm from "@/components/loan/forms/payrollCoverForms/loanInfo.vue";
import uploadForm from "@/components/loan/forms/payrollCoverForms/uploadDoc.vue";

const route = useRoute();
const emit = defineEmits(["done"]);
const activeOption = ref(0);
const store = useDataStore();

const data = ref([
  {
    title: "Business Information",
    complete: false,
    active: true,
    value: "business",
  },
  {
    title: "Upload Document",
    complete: false,
    active: false,
    value: "upload",
  },

  {
    title: "Loan Information",
    complete: false,
    active: false,
    value: "loan",
  },
]);

const args = ref({
  input: {
    type: route.params.id,
    termsAndConditionsAccepted: true,
    purpose: "",
    termsAndConditions: "",
    loanAmount: "",
    employeeIds: [],
    durationPeriod: "MONTH",
    duration: 0,
  },

  businessInformation: {
    yearsInLocation: 0,
    totalEmployees: 0,
    title: "",
    sixMonthsRevenueUrl: "",
    sixMonthsProjectedRevenueUrl: "",
    sixMonthsExpensesUrl: "",
    residentialAddress: "",
    receivedLoansInNGbefore: false,
    ongoingLoanFacility: false,
    monthAndYearOppCommenced: "",
    loanAmountReceived: 0,
    lastMonthRevenue: "0",

    lastMonthExpenses: "0",

    evidenceOfRevAndExpUrl: "",
    endDate: "",
    evidenceOfOwnershipUrl: "",
    dateReceived: "",
    companyRegisteredName: "",
    businessType: "",
    businessOwnership: "",
  },
  // paymentDetails: {
  //   bankName: "",
  //   bankCode: "",
  //   bankBranch: "",
  //   accountType: "",
  //   accountNumber: "",
  //   accountName: "",
  // },

  // guarantorInformation: [
  //   {
  //     relationship: "",
  //     phoneNumber: "",
  //     fullName: "",
  //     email: "",
  //     bvn: "",
  //   },
  //   {
  //     relationship: "",
  //     phoneNumber: "",
  //     fullName: "",
  //     email: "",
  //     bvn: "",
  //   },
  // ],
  loanDocuments: {
    sixMonthsBankStatement: "",

    proofOfResidentialAddress: "",

    proofOfOfficeAddress: "",

    loanApplicationLetter: "",

    financialAudit: "",

    constitutionalDocuments: [],

    collateral: "",

    cacRegisteredDocuments: [],

    cacDocument: "",

    boardOfDirectorsResolution: [],
  },
  // assetInformation: {
  //   totalPurchasePrice: "",
  //   pricePerUnit: "",
  //   modelNo: "",
  //   intendedUseOfAsset: "",
  //   inflow: "",
  //   description: "",
  //   brand: "",
  //   assetQuantity: "",
  // },
});

function setActive(e) {
  if (e == 1) {
    data.value[0].complete = true;
    data.value[0].active = false;
  }
  // if (e > 0 && !data.value[e - 1].complete) {
  //   return;
  // }
  if (e > 0) {
    data.value[e - 1].complete = true;
    data.value[e - 1].active = false;
  }

  data.value[e].active = true;
  activeOption.value = e;
}

function updateStoreValue(obj, key) {
  store.$patch({
    createLoanArgs: {
      [key]: obj,
    },
  });
}
</script>

<style scoped></style>
