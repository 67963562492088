<template>
  <main class="px-2 sm:px-4 py-4">
    <h4
      class="md:mt-3 md:text-sm md:text-left text-xs font-medium text-headerText"
    >
      Employee Management / Edit Employee
    </h4>

    <div
      v-if="!isSearch && getAllEmployees && !getAllEmployees.data.length"
      class="mt-16 mb-4"
    >
      <easiEmptyState
        :hiddenButton="false"
        text=" You have not added any employee yet."
      ></easiEmptyState>
    </div>

    <div v-else class="my-6 md:my-10 w-full">
      <div
        class="flex md:flex-row flex-col md:justify-between w-full md:gap-y-0 gap-y-8 md:items-center"
      >
        <div class="flex md:items-center mt-4 w-full md:w-4/5 grow-0">
          <!-- /////// Search Component ///// -->
          <div class="w-full md:w-10/12">
            <easiSearch
              placeholder="Search employee by name"
              v-model="searchText"
              @search="searchFN"
            />
          </div>

          <!-- /////// Filter Component Start /////// -->
          <!-- <div class="ml-4">
            <easiFilter @update="filterFN" />
          </div> -->

          <div class="ml-4">
            <easiFilter @update="filterFN" ref="viewEmployeeFilterRef" />
          </div>
          <!-- /////// Filter Component Ends /////// -->
        </div>
        <div class="">
          <easiButton
            @click="$router.push({ name: 'EmployeeBulkEdit' })"
            color="primary"
            class="flex items-center justify-center gap-3 py-4"
          >
            <img src="@/assets/icons/account-plus.svg" alt="" />
            Bulk Edit
          </easiButton>
        </div>
      </div>

      <div class="mt-4">
        <easiActiveFilter @close="removeFilter" :filters="activeFilters" />
      </div>
    </div>

    <div class="mt-4">
      <easiEmployeeTable
        @selected="updateSelectedEmployees"
        :is-search="isSearch"
        :employees="getAllEmployees && getAllEmployees.data"
      />
    </div>

    <!-- <easiRoundLoader v-if="loading" /> -->
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";

const store = useDataStore();
const { query, set } = store;
const { getAllEmployees, checkLoading } = storeToRefs(store);

let loading = ref(false);
const searchText = ref("");
const activeFilters = ref([]);
const viewEmployeeFilterRef = ref(null);

let isSearch = ref(false);
const filterOptions = ref({
  sort: { firstName: "ASC" },
  filter: [],
});
let selectedEmployees = ref([]);

const payload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: {
      firstName: "ASC",
    },
  },
  filter: [],
});

watch(searchText, async (val) => {
  if (val.trim() === "") {
    payload.value.paging.search = "";
    await queryEmployees();
    isSearch.value = false;
  }
});

onMounted(() => {
  queryEmployees();
});

const searchFN = async (suggested) => {
  // console.log(suggested, searchText.value);
  searchText.value = suggested ? suggested : searchText.value;
  isSearch.value = true;
  payload.value.paging.search = searchText.value;
  await queryEmployees();
};

const filterFN = ({ filter, active }) => {
  isSearch.value = true;
  filterOptions.value = filter;

  payload.value.paging.sort = filterOptions.value.sort;
  payload.value.filter = filterOptions.value.filter;

  console.log("Payload");
  log(payload.value);
  queryEmployees();
  activeFilters.value = active;
};

const removeFilter = (arg) => {
  // console.log(arg);
  console.log(arg);
  if (viewEmployeeFilterRef.value && viewEmployeeFilterRef.value.removeFilter) {
    viewEmployeeFilterRef.value.removeFilter(arg);
  }
};

const queryEmployees = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "ListEmployees",
      payload: payload.value,
      service: "EMP",
      storeKey: "listEmployees",
    });

    loading.value = false;
    console.log("Employees");
    log(getAllEmployees.value.data);
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};

function updateSelectedEmployees(arg) {
  selectedEmployees.value = [];
  selectedEmployees.value = arg;
}

function empty() {
  getAllEmployees.value.data = [];
  console.log(getAllEmployees.value.data);
}
// Get rid of the proxy when console.log
function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}
</script>

<style></style>
