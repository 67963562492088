<template>
  <div class="w-full flex flex-col justify-center mx-auto">
    <span class="text-sm text-newGray capitalize"
      >Maximum loan amount eligible</span
    >
    <span class="text-lg mt-1 text-dark-800 font-bold">{{
      formatAmount(eligibleAmount.max || 0)
    }}</span>

    <form
      @submit.prevent="checkArgs"
      class="md:grid flex flex-col grid-cols-2 md:items-center md:gap-y-2 gap-x-8"
    >
      <div class="mt-3">
        <easiTextInput
          placeholder="Enter amount needed"
          v-model="argument.loanAmount"
          type="text"
          :format="true"
          :max="eligibleAmount.max"
          name="loanAmount"
          required
        ></easiTextInput>
      </div>

      <span class="col-span-2 mt-4 text-xs text-newGray">
        Specify your company's bank account that will finance this
        repayment</span
      >
      <div class="mt-5">
        <easiBankSelectInput
          @update="bankName = $event"
          :value="bankName"
          :autoHeight="true"
        >
        </easiBankSelectInput>
      </div>

      <div class="">
        <easiTextInput
          placeholder="Account Number"
          v-model="argument.paymentDetails.accountNumber"
          type="number"
          name="accountnumber"
          required
        ></easiTextInput>
      </div>

      <div class="mt-4">
        <easiSelectInput2
          :options="[
            { label: 'Savings', value: 'SAVINGS' },
            { label: 'Current', value: 'CURRENT' },
          ]"
          @update="argument.paymentDetails.accountType = $event"
          :value="argument.paymentDetails.accountType"
          placeholder="Account Type"
        ></easiSelectInput2>

        <span
          v-if="argument.paymentDetails.accountName"
          class="text-xs text-dark-800 font-bold"
        >
          {{ argument.paymentDetails.accountName }} ({{
            argument.paymentDetails.bankName
          }})
        </span>
      </div>

      <div class="">
        <easiTextInput
          v-model="argument.paymentDetails.bankBranch"
          placeholder="Bank Branch"
          type="text"
          name="address"
          required
        ></easiTextInput>
      </div>
      <div class="mt-5 col-span-2">
        <span class="mt-4 col-span-2 text-xs text-newGray">
          Repayment duration</span
        >
        <div class="mt-3 col-span-2 grid grid-cols-4 gap-4">
          <div
            v-for="period in loanPeriod"
            :key="period.value"
            :class="
              argument.duration === period.value.duration
                ? 'bg-primary text-white'
                : 'bg-white text-dark-800'
            "
            @click="
              () => {
                argument.duration = period.value.duration;
                argument.durationPeriod = period.value.period;
              }
            "
            class="border border-outlineGray cursor-pointer rounded-lg shadow-lg text-xs p-3 text-center"
          >
            {{ period.label }}
          </div>
        </div>

        <div
          v-if="argument.duration && argument.loanAmount"
          class="bg-lightGreyBg mt-8 rounded-xl p-3 col-span-2 grid grid-cols-3 justify-between"
        >
          <div class="flex flex-col">
            <span class="text-xs text-newGray"> Loan amount</span>
            <span class="text-lg mt-1 text-dark-800 font-bold">{{
              formatAmount(argument.loanAmount || 0)
            }}</span>
          </div>

          <div class="flex flex-col">
            <span class="text-xs text-newGray"> Interest rate</span>
            <span class="text-lg mt-1 text-dark-800 font-bold"
              >{{ interestPercent }}%</span
            >
          </div>

          <div class="flex flex-col">
            <span class="text-xs text-newGray"
              >Total amount payable monthly</span
            >
            <span class="text-lg mt-1 text-dark-800 font-bold">{{
              formatAmount(
                argument.loanAmount / Number(argument.duration) +
                  calculatePercentage(argument.loanAmount, interestPercent) || 0
              )
            }}</span>
          </div>
        </div>
      </div>
      <span class="mt-4 col-span-2 text-xs text-newGray">
        Guarantor Information 1</span
      >
      <div class="-mt-3">
        <easiTextInput
          v-model="argument.guarantorInformation[0].fullName"
          placeholder="Name"
          type="text"
          name="address"
          required
        ></easiTextInput>
      </div>

      <div class="-mt-3">
        <easiTextInput
          v-model="argument.guarantorInformation[0].relationship"
          placeholder="Relationship"
          type="text"
          name="relationship"
          required
        ></easiTextInput>
      </div>

      <div class="">
        <easiTextInput
          v-model="argument.guarantorInformation[0].phoneNumber"
          placeholder="Phone Number"
          type="number"
          name="phone"
          required
        ></easiTextInput>
      </div>

      <div class="">
        <easiTextInput
          v-model="argument.guarantorInformation[0].email"
          placeholder="Email Address"
          type="email"
          name="email"
          required
        ></easiTextInput>
      </div>

      <div class="">
        <easiTextInput
          v-model="argument.guarantorInformation[0].bvn"
          placeholder="Bank Verification Number (BVN)"
          type="text"
          name="bvn"
          required
        ></easiTextInput>
      </div>

      <span class="mt-4 col-span-2 text-xs text-newGray">
        Guarantor Information 2</span
      >
      <div class="-mt-3">
        <easiTextInput
          v-model="argument.guarantorInformation[1].fullName"
          placeholder="Name"
          type="text"
          name="address"
          required
        ></easiTextInput>
      </div>

      <div class="-mt-3">
        <easiTextInput
          v-model="argument.guarantorInformation[1].relationship"
          placeholder="Relationship"
          type="text"
          name="relationship"
          required
        ></easiTextInput>
      </div>

      <div class="">
        <easiTextInput
          v-model="argument.guarantorInformation[1].phoneNumber"
          placeholder="Phone Number"
          type="number"
          name="phone"
          required
        ></easiTextInput>
      </div>

      <div class="">
        <easiTextInput
          v-model="argument.guarantorInformation[1].email"
          placeholder="Email Address"
          type="email"
          name="email"
          required
        ></easiTextInput>
      </div>

      <div class="">
        <easiTextInput
          v-model="argument.guarantorInformation[1].bvn"
          placeholder="Bank Verification Number (BVN)"
          type="text"
          name="bvn"
          required
        ></easiTextInput>
      </div>

      <div
        class="flex items-end col-span-2 justify-end self-end gap-4 mb-4 mt-8"
      >
        <easiButton
          type="button"
          class="md:w-32 w-40"
          size="medium"
          variant="text"
          @click="$router.go(-1)"
          >Cancel</easiButton
        >
        <easiButton
          @click="$emit('back')"
          type="button"
          class="md:w-32 w-40"
          size="medium"
          variant="outlined"
          >Back</easiButton
        >
        <easiButton size="medium" class="md:w-32 w-40">Continue</easiButton>
      </div>
    </form>

    <easiLoading v-if="verify" @close="verify = false">
      <template v-slot:heading>
        <h1>Verifying Bank information</h1>
      </template>

      <template v-slot:message>
        <div class="w-full rounded-xl">
          <vue3-progress-bar></vue3-progress-bar>
        </div>
      </template>
    </easiLoading>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { helperFunctions } from "@/composable/helperFunctions";
import nigerianBanks from "@/utils/banks";

import { useToast } from "vue-toastification";
import { useDataStore } from "@/stores/data.js";

const store = useDataStore();
const toast = useToast();
const route = useRoute();

const { query, mutate } = store;

const { formatAmount, calculatePercentage } = helperFunctions;
// const props = defineProps({
//   totalPurchasePrice: {
//     type: Number,
//     default: 0,
//   },
// });
const emit = defineEmits(["loan", "back"]);
const loanArgsInStore = computed(() => store.getLoanArgs);
const eligibleAmount = computed(() => store.getEligibleLoanAmount);
// const interestPercent = ref(3.5); //Hardcoded, pending when an endpoint is created to return dynamic values

const bankName = ref("");

const verify = ref(false);

const argument = ref({
  loanAmount: "",
  duration: 0,
  durationPeriod: "",
  paymentDetails: {
    bankName: "",
    bankCode: "",
    bankBranch: "",
    accountType: "",
    accountNumber: "",
    accountName: "",
  },
  guarantorInformation: [
    {
      relationship: "",
      phoneNumber: "",
      fullName: "",
      email: "",
      bvn: "",
    },
    {
      relationship: "",
      phoneNumber: "",
      fullName: "",
      email: "",
      bvn: "",
    },
  ],
});
const interestPercent = computed(() => {
  if (argument.value.duration <= 3) {
    return 4;
  }
  if (argument.value.duration <= 6) {
    return 4.5;
  }
  if (argument.value.duration <= 9) {
    return 4.8;
  }
  if (argument.value.duration > 9) {
    return 5;
  }
});
const accNum = computed(
  () =>
    argument.value &&
    argument.value.paymentDetails &&
    argument.value.paymentDetails.accountNumber
);

const loanPeriod = ref([
  { label: "3 Month", value: { period: "MONTH", duration: 3 } },
  { label: "6 Months", value: { period: "MONTH", duration: 6 } },
  { label: "9 Months", value: { period: "MONTH", duration: 9 } },
  { label: "12 Months", value: { period: "MONTH", duration: 12 } },
]);

async function verifyAccountName() {
  let endpointData = {
    accountNumber: argument.value.paymentDetails.accountNumber,
    bankCode: argument.value.paymentDetails.bankCode,
  };
  verify.value = true;
  try {
    let res = await mutate({
      endpoint: "VerifyAccountDetails",
      data: { input: endpointData },
      service: "EMP",
    });
    if (res.success === true) {
      argument.value.paymentDetails.accountName = res.data.data.account_name;
      toast.success("Account name verified.");
    }
  } catch (e) {
    toast.error(e.message);
  } finally {
    verify.value = false;
  }
}

watch(accNum, (newval, oldval) => {
  if (
    argument.value &&
    argument.value.paymentDetails &&
    argument.value.paymentDetails.accountNumber &&
    argument.value.paymentDetails.accountNumber.length === 10
  ) {
    // await verifyAccountName();
    verifyAccountName();
  }
});
watch(bankName, (val) => {
  let bankDetails = [];
  bankDetails = nigerianBanks.filter((bank) => bank.name === val);
  for (let bank of bankDetails) {
    argument.value.paymentDetails.bankName = bank.name;
    argument.value.paymentDetails.bankCode = bank.code;
  }
});
function updateDoubleStoreValue(eve) {
  const paymentDetails = eve.paymentDetails;
  const guarantorInformation = eve.guarantorInformation;
  const loanAmount = eve.loanAmount;
  const duration = eve.duration;
  const durationPeriod = eve.durationPeriod;
  const input = {
    loanAmount,
    duration,
    durationPeriod,
    type: route.params.id,
    termsAndConditionsAccepted: true,
    purpose: "SMALL BUSINESS",
    termsAndConditions: "",
    employeeIds: [],
  };

  store.$patch({
    createLoanArgs: {
      paymentDetails,
      guarantorInformation,
      input,
    },
  });
}
function checkArgs() {
  updateDoubleStoreValue(argument.value);
  emit("loan", argument.value);
}

onMounted(() => {
  if (
    loanArgsInStore.value &&
    loanArgsInStore.value.paymentDetails &&
    loanArgsInStore.value.guarantorInformation &&
    loanArgsInStore.value.input
  ) {
    argument.value.paymentDetails = Object.assign(
      argument.value.paymentDetails,
      loanArgsInStore.value.paymentDetails
    );
    bankName.value = argument.value.paymentDetails.bankName;

    argument.value.guarantorInformation = Object.assign(
      argument.value.guarantorInformation,
      loanArgsInStore.value.guarantorInformation
    );

    argument.value.loanAmount =
      loanArgsInStore.value.input.loanAmount ||
      loanArgsInStore.value.assetInformation.totalPurchasePrice;
    argument.value.duration = loanArgsInStore.value.input.duration;
    argument.value.durationPeriod = loanArgsInStore.value.input.durationPeriod;
  }
});
</script>

<style scoped>
.custom-date-input {
  display: inline-block;
  position: relative;
}

.custom-date-input input[type="text"] {
  padding: 5px;
  width: 150px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.custom-date-input::before {
  content: attr(placeholder);
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #999;
}
</style>
