<template>
  <div class="w-full flex flex-col justify-center mx-auto">
    <span class="md:mt-8 mt-2 text-sm text-dark-800">
      Please upload the following documents for vetting</span
    >
    <form
      @submit.prevent="handleDocs"
      class="grid grid-cols-2 items-center mt-4 gap-y-2 gap-x-8"
    >
      <div
        v-for="(file, index) in formFile"
        :key="index"
        class="col-span-2 mb-3 flex gap-4 items-center"
      >
        <div
          style="background-color: #f3f3f3"
          class="rounded-2xl p-3 w-9/12 text-dark-800 text-xs"
        >
          <span class="text-dark-800 text-xs"> {{ file.label }}</span>

          <div class="mt-2" v-if="file.subText">
            <span
              v-html="file.subText"
              class="text-xs leading-none text-dark-800"
            ></span>
          </div>
        </div>

        <button
          v-if="file.uploadFile || documents.loanDocuments[file.docKey]"
          class="bg-none outline-none focus:outline-none"
        >
          <img src="@/assets/icons/uploaded-check.svg" alt="" />
        </button>

        <button v-else class="bg-none outline-none focus:outline-none">
          <img src="@/assets/icons/upload-pending.svg" alt="" />
        </button>

        <UploadComponent
          @fileUrl="file.uploadFile = $event"
          :label="'Upload'"
          :imageLink="documents.loanDocuments[file.docKey]"
          :loop="true"
        />
      </div>
      <div
        class="flex items-end col-span-2 justify-end self-end gap-4 mb-4 mt-8"
      >
        <easiButton
          type="button"
          class="md:w-32 w-40"
          size="medium"
          variant="text"
          @click="$router.go(-1)"
          >Cancel</easiButton
        >
        <easiButton
          @click="$emit('back')"
          type="button"
          class="md:w-32 w-40"
          size="medium"
          variant="outlined"
          >Back</easiButton
        >
        <easiButton size="medium" class="md:w-32 w-40">Next</easiButton>
      </div>
    </form>

    <!-- <easiModal
        v-if="summary"
        @close="summary = false"
        :isHeader="false"
        :isBorder="false"
        rounded="xl"
      ></easiModal> -->

    <easiRoundLoader v-if="loading" />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import UploadComponent from "@/components/global/UploadComponent";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";

const { uploadFileToServer } = helperFunctions;
const emit = defineEmits(["docs", "back"]);

const store = useDataStore();
const route = useRoute();

const { mutate } = store;

const loanArgsInStore = computed(() => store.getLoanArgs);

const documents = ref({
  loanDocuments: {
    sixMonthsBankStatement: "",

    proofOfResidentialAddress: "",

    proofOfOfficeAddress: "",

    loanApplicationLetter: "",

    financialAudit: "",

    constitutionalDocuments: [],

    collateral: "",

    cacRegisteredDocuments: [],

    cacDocument: "",

    boardOfDirectorsResolution: [],
  },
});

onMounted(() => {
  if (loanArgsInStore.value && loanArgsInStore.value.loanDocuments) {
    documents.value.loanDocuments = Object.assign(
      documents.value.loanDocuments,
      loanArgsInStore.value.loanDocuments
    );
  }
});
const formFile = ref([
  {
    label: "6 month bank statement",
    uploadFile: null,
    docKey: "sixMonthsBankStatement",
  },
  {
    label:
      "Audited Financial statements (e.g., income statement, balance sheet)",
    uploadFile: null,
    docKey: "financialAudit",
  },
  {
    label: "Proof of company Address",
    uploadFile: null,
    docKey: "proofOfOfficeAddress",
  },

  {
    label: "proof of residential address (Utility bill & rent receipt)",
    uploadFile: null,
    docKey: "proofOfResidentialAddress",
  },
  { label: "Original CAC document", uploadFile: null, docKey: "cacDocument" },
  {
    label: "CAC registered document",
    uploadFile: null,
    docKey: "cacRegisteredDocuments",
  },
  {
    label: "Security cover/Collateral",
    subText:
      "<strong>For example:</strong> Upload either receipts of assets, C of O of real estate properties, personal guarantor letter from the CEO of a company, etc.",
    uploadFile: null,
    docKey: "collateral",
  },
  {
    label:
      "All constitutional documents (and all such amendments thereto that are effective as at the date hereof)",
    uploadFile: null,
    docKey: "constitutionalDocuments",
  },
]);
const arrayFiles = ["cacRegisteredDocuments", "constitutionalDocuments"];

async function processItemsWithAsyncFunction(items) {
  for (const item of items) {
    try {
      if (item.uploadFile && item.docKey) {
        if (arrayFiles.includes(item.docKey)) {
          documents.value.loanDocuments[item.docKey].push(
            await uploadFileToServer(item.uploadFile)
          );
        } else {
          documents.value.loanDocuments[item.docKey] = await uploadFileToServer(
            item.uploadFile
          );
        }
      }
    } catch (error) {
      console.error("Error processing item:", error);
    }
  }
}
const loading = ref(false);

const getLoanAmount = async (type) => {
  try {
    let res = await mutate({
      endpoint: "FetchLoanAmount",
      data: { type },
      service: "LOAN",
    });

    if (res && res.success) {
      store.$patch({
        eligibleLoanAmount: res.data,
      });

      return true;
    } else {
      toast.error(res.message);
      // router.push({ name: "Loan" });
    }
  } catch (e) {
    console.log(e.message);
    toast.error(e.message);
    // router.push({ name: "Loan" });
  }
};

async function handleDocs() {
  loading.value = true;
  try {
    await processItemsWithAsyncFunction(formFile.value);
    await getLoanAmount(route.params.id);
    emit("docs", documents.value.loanDocuments);
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
}
</script>

<style scoped>
.custom-date-input {
  display: inline-block;
  position: relative;
}

.custom-date-input input[type="text"] {
  padding: 5px;
  width: 150px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.custom-date-input::before {
  content: attr(placeholder);
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #999;
}
</style>
