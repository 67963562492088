<template>
  <div class="w-full flex flex-col justify-center mx-auto">
    <div class="mt-8 self-center">
      <Stepper
        @changeActive="setActive($event)"
        :optionData="data"
        :activeNum="activeOption"
      />
    </div>
    <div class="md:my-5">
      <span class="text-dark-800 text-left text-sm">
        Kindly answer the following questions (all fields are required)
      </span>
    </div>

    <businessForm
      @business="
        ($event) => {
          args.businessInformation = $event;
          updateStoreValue($event, 'businessInformation');
          setActive(1);
        }
      "
      v-if="activeOption === 0"
    />
    <assetForm
      @asset="
        ($event) => {
          args.assetInformation = $event;
          updateStoreValue($event, 'assetInformation');
          setActive(2);
        }
      "
      @back="setActive(0)"
      v-if="activeOption === 1"
    />
    <loanForm
      @loan="
        ($event) => {
          args.paymentDetails = $event.paymentDetails;
          args.guarantorInformation = $event.guarantorInformation;
          args.input.loanAmount = $event.loanAmount;
          args.input.duration = $event.duration;
          args.input.durationPeriod = $event.durationPeriod;
          updateDoubleStoreValue($event);
          setActive(3);
        }
      "
      @back="setActive(1)"
      v-if="activeOption === 2"
    />
    <uploadForm
      @docs="
        ($event) => {
          args.loanDocuments = $event;
          updateStoreValue($event, 'loanDocuments');
          $emit('done', args);
        }
      "
      @back="setActive(2)"
      v-if="activeOption === 3"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useDataStore } from "@/stores/data.js";

import Stepper from "@/components/global/MobileStepper.vue";
import businessForm from "@/components/loan/forms/assetForms/businessInfo.vue";
import assetForm from "@/components/loan/forms/assetForms/assetInfo.vue";
import loanForm from "@/components/loan/forms/assetForms/loanInfo.vue";
import uploadForm from "@/components/loan/forms/assetForms/uploadAsset.vue";

const route = useRoute();
const emit = defineEmits(["done"]);
const activeOption = ref(0);
const store = useDataStore();

const data = ref([
  {
    title: "Business Information",
    complete: false,
    active: true,
    value: "business",
  },
  {
    title: "Asset Information",
    complete: false,
    active: false,
    value: "asset",
  },
  {
    title: "Loan Information",
    complete: false,
    active: false,
    value: "loan",
  },
  {
    title: "Upload Document",
    complete: false,
    active: false,
    value: "upload",
  },
]);

function setActive(e) {
  console.log(e, "index");
  if (e == 1) {
    data.value[0].complete = true;
    data.value[0].active = false;
  }
  // if (e > 0 && !data.value[e - 1].complete) {
  //   return;
  // }
  if (e > 0) {
    data.value[e - 1].complete = true;
    data.value[e - 1].active = false;
  }

  data.value[e].active = true;
  activeOption.value = e;
}

const args = ref({
  input: {
    type: route.params.id,
    termsAndConditionsAccepted: true,
    purpose: "",
    termsAndConditions: "",
    loanAmount: "",
    employeeIds: [],
    durationPeriod: "MONTH",
    duration: 0,
  },

  businessInformation: {
    yearsInLocation: 0,
    totalEmployees: 0,
    title: "",
    sixMonthsRevenueUrl: "",
    sixMonthsProjectedRevenueUrl: "",
    sixMonthsExpensesUrl: "",
    residentialAddress: "",
    receivedLoansInNGbefore: false,
    ongoingLoanFacility: false,
    monthAndYearOppCommenced: "",
    lastMonthRevenue: "0",

    lastMonthExpenses: "0",

    evidenceOfRevAndExpUrl: "",
    loanAmountReceived: 0,
    endDate: "",
    evidenceOfOwnershipUrl: "",
    dateReceived: "",
    companyRegisteredName: "",
    businessType: "",
    businessOwnership: "",
  },
  paymentDetails: {
    repaymentType: "",
    bankName: "",
    bankCode: "",
    bankBranch: "",
    accountType: "",
    accountNumber: "",
    accountName: "",
  },

  guarantorInformation: [
    {
      relationship: "",
      phoneNumber: "",
      fullName: "",
      email: "",
      bvn: "",
    },
    {
      relationship: "",
      phoneNumber: "",
      fullName: "",
      email: "",
      bvn: "",
    },
  ],
  loanDocuments: {
    sixMonthsBankStatement: "",

    proofOfResidentialAddress: "",

    proofOfOfficeAddress: "",

    loanApplicationLetter: "",

    financialAudit: "",

    constitutionalDocuments: [],

    collateral: "",

    cacRegisteredDocuments: [],

    cacDocument: "",

    boardOfDirectorsResolution: [],
  },
  assetInformation: {
    totalPurchasePrice: "",
    pricePerUnit: "",
    modelNo: "",
    intendedUseOfAsset: "",
    inflow: "",
    description: "",
    brand: "",
    assetQuantity: "",
  },
});

function updateStoreValue(obj, key) {
  store.$patch({
    createLoanArgs: {
      [key]: obj,
    },
  });
}

function updateDoubleStoreValue(eve) {
  const paymentDetails = eve.paymentDetails;
  const guarantorInformation = eve.guarantorInformation;
  const loanAmount = eve.loanAmount;
  const duration = eve.duration;
  const durationPeriod = eve.durationPeriod;
  const input = {
    loanAmount,
    duration,
    durationPeriod,
    type: route.params.id,
    termsAndConditionsAccepted: true,
    purpose: "ASSET PURCHASING",
    termsAndConditions: "",
    employeeIds: [],
  };

  store.$patch({
    createLoanArgs: {
      paymentDetails,
      guarantorInformation,
      input,
    },
  });
}
</script>

<style scoped></style>
