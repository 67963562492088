<template>
  <main class="md:px-2 px-3 py-4">
    <h4
      class="md:mt-3 capitalize md:uppercase md:text-sm md:text-left text-xs font-medium text-headerText"
    >
      Employee Management / View Employee
    </h4>
    <div
      class="w-full flex justify-end items-center -mb-2 mt-2 sm:mb-0 sm:mt-0"
    >
      <div
        @click="$router.push({ name: 'Archived' })"
        class="text-secondary w-fit cursor-pointer text-xs my-3 md:my-0 sm:text-sm font-medium items-center flex justify-start"
      >
        <img src="@/assets/icons/delete-red.svg" alt="" />
        <span class="ml-2">View Archive</span>
      </div>
    </div>

    <div
      v-if="
        !isSearch &&
        getAllEmployees &&
        getAllEmployees.data &&
        !getAllEmployees.data.length
      "
      class="mt-16 mb-8"
    >
      <easiEmptyState
        :hiddenButton="false"
        text=" You have not added any employee yet"
      >
        <easiButton
          @click="$router.push({ name: 'AddEmployee' })"
          variant="outlined"
          color="secondary"
          class=""
        >
          Add new employee
        </easiButton>
      </easiEmptyState>
    </div>

    <div v-else class="my-6 md:my-10">
      <div class="mt-8 mb-16 md:mb-6 md:mt-4 flex flex-col gap-1 sm:gap-3">
        <div class="flex w-full flex-wrap gap-2 items-center md:justify-start">
          <!-- /////// Search Component ///// -->
          <div class="w-9/12 md:w-9/12">
            <easiSearch
              placeholder="Search employee by name"
              v-model="searchText"
              @search="searchFN"
            />
          </div>

          <!-- /////// Filter Component Start /////// -->
          <div class="md:ml-0">
            <easiFilter @update="filterFN" ref="viewEmployeeFilterRef" />
          </div>
          <!-- /////// Filter Component Ends /////// -->

          <div class="md:hidden ml-4 my-3 flex gap-6 items-center">
            <easiButton
              :loading="loading.moreAction"
              @click="selectOption('edit')"
              size="small"
              color="primary"
              variant="text"
              class="flex items-center justify-center gap-2 md:gap-3 py-3 px-2 md:py-2"
            >
              <img
                class="w-4 md:w-5 -ml-2 sm:-ml-0"
                src="@/assets/icons/primary-edit.svg"
                alt=""
              />
              <span
                class="text-sm md:text-sm font-medium text-primary whitespace-nowrap -ml-1 sm:-ml-0"
                >Edit
              </span>
            </easiButton>

            <easiButton
              :loading="loading.moreAction"
              @click="selectOption('archive')"
              size="small"
              color="primary"
              variant="text"
              class="flex items-center justify-center gap-2 md:gap-3 py-3 px-2 md:py-2"
            >
              <img
                class="w-4 md:w-5 -ml-2 sm:-ml-0"
                src="@/assets/icons/archive-action.svg"
                alt=""
              />
              <span
                class="text-sm md:text-sm font-medium text-primary whitespace-nowrap -ml-1 sm:-ml-0"
                >Archive
              </span>
            </easiButton>
          </div>
          <!-- <easiButton
            @click="sendInvite"
            :loading="loading.sendInvite"
            color="primary"
            :disabled="selectedEmployees.length ? false : true"
            class="flex items-center w-full justify-center gap-2 md:gap-3 py-3 my-3 md:my-0 ml-4 px-2 md:py-4"
          >
            <img class="w-4 md:w-5" src="@/assets/icons/send.svg" alt="" />
            <span
              class="text-sm text-white font-bold md:text-sm whitespace-nowrap"
              >Send Invite</span
            >
          </easiButton> -->
        </div>
        <easiActiveFilter @close="removeFilter" :filters="activeFilters" />
      </div>

      <div
        v-show="selectedEmployees.length"
        class="flex md:justify-start justify-center items-start mt-0 mb-2 gap-2 sm:gap-4"
      >
        <div class="hidden md:flex gap-6 items-center">
          <easiButton
            :loading="loading.moreAction"
            @click="selectOption('edit')"
            size="small"
            color="primary"
            variant="outlined"
            class="flex items-center justify-center gap-2 md:gap-3 py-3 px-2 md:py-2"
          >
            <img
              class="w-4 md:w-5 -ml-2 sm:-ml-0"
              src="@/assets/icons/primary-edit.svg"
              alt=""
            />
            <span
              class="text-sm md:text-sm font-medium text-primary whitespace-nowrap -ml-1 sm:-ml-0"
              >Edit
            </span>
          </easiButton>

          <easiButton
            :loading="loading.moreAction"
            @click="selectOption('archive')"
            size="small"
            color="primary"
            variant="outlined"
            class="flex items-center justify-center gap-2 md:gap-3 py-3 px-2 md:py-2"
          >
            <img
              class="w-4 md:w-5 -ml-2 sm:-ml-0"
              src="@/assets/icons/archive-action.svg"
              alt=""
            />
            <span
              class="text-sm md:text-sm font-medium text-primary whitespace-nowrap -ml-1 sm:-ml-0"
              >Archive
            </span>
          </easiButton>
        </div>
      </div>
    </div>

    <div :class="selectedEmployees.length ? '-mt-16' : '-mt-2'">
      <easiEmployeeTable
        @selected="updateSelectedEmployees"
        :is-search="isSearch"
        :employees="getAllEmployees && getAllEmployees.data"
      />
    </div>
    <div
      @click="showOptions = false"
      :class="!showOptions ? 'pointer-events-none' : ''"
      class="bg-transparent fixed inset-0"
    ></div>

    <easiWarn
      v-if="archiveWarning"
      @close="archiveWarning = false"
      :icon="archiveIcon"
      showIcon
    >
      <template v-slot:message>
        <span>Are you sure you want to archive Employee?</span>
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="flex justify-center items-center w-full gap-x-4 mt-6">
            <easiButton
              class="w-40 min-w-full bg-white"
              @click="archiveWarning = false"
              variant="outlined"
              >Cancel</easiButton
            >
            <easiButton
              class="w-40 min-w-full"
              :loading="loading.archive"
              @click="archiveEmp"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>

    <!-- <easiRoundLoader v-if="loading.table" /> -->
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";

import archiveIcon from "@/assets/icons/archived.svg";

const router = useRouter();

const toast = useToast();
const store = useDataStore();
const { query, mutate, set } = store;
const { getAllEmployees, checkLoading, listArchivedEmployees } =
  storeToRefs(store);

let loading = ref({
  table: false,
  sendInvite: false,
  moreAction: false,
  archive: false,
});

let archiveWarning = ref(false);
let showOptions = ref(false);
let searchText = ref("");
let isSearch = ref(false);

const viewEmployeeFilterRef = ref(null);
const activeFilters = ref([]);
const filterOptions = ref({
  sort: { firstName: "ASC" },
  filter: [],
});

let selectedEmployees = ref([]);

const payload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

watch(searchText, async (val) => {
  // console.log(val.trim(), isSearch.value);

  if (val.trim() === "") {
    payload.value.paging.search = "";
    await queryEmployees();
    isSearch.value = false;
  }
});

onMounted(() => {
  queryEmployees();
  queryArchiveEmployees();
  queryBranch();
  queryDepartment();
});

const selectOption = (type) => {
  type = type.toLowerCase();
  if (type === "edit") {
    editEmp();
  } else if (type === "delete") {
    console.log("Delete Emloyee");
  } else if (type === "archive") {
    archiveWarning.value = true;
  }
  showOptions.value = false;
};

const searchFN = async (suggested) => {
  // console.log(suggested, searchText.value);
  searchText.value = suggested ? suggested : searchText.value;
  isSearch.value = true;
  payload.value.paging.search = searchText.value;
  await queryEmployees();
};

const filterFN = async ({ filter, active }) => {
  isSearch.value = true;
  filterOptions.value = filter;

  payload.value.paging.sort = filterOptions.value.sort;
  payload.value.filter = filterOptions.value.filter;

  console.log("Payload");
  log(payload.value);
  await queryEmployees();

  activeFilters.value = active;
};

const removeFilter = (arg) => {
  console.log(viewEmployeeFilterRef.value);
  if (viewEmployeeFilterRef.value && viewEmployeeFilterRef.value.removeFilter) {
    viewEmployeeFilterRef.value.removeFilter(arg);
  }
};

const queryEmployees = async () => {
  loading.value.table = true;
  try {
    await query({
      endpoint: "ListEmployees",
      payload: payload.value,
      service: "EMP",
      storeKey: "listEmployees",
    });

    loading.value.table = false;
    console.log("Employees");
    log(getAllEmployees.value.data);
  } catch (err) {
    loading.value.table = false;
    console.log(err);
  }
};
const queryArchiveEmployees = async () => {
  loading.value.moreAction = true;
  try {
    await query({
      endpoint: "ListEmployees",
      payload: {
        archived: true,
        paging: {
          limit: 1000,
          skip: 0,
          search: "",
          sort: {},
        },
        filter: [],
      },
      service: "EMP",
      storeKey: "listArchivedEmployees",
    });

    loading.value.moreAction = false;
    console.log("Employees");
  } catch (err) {
    loading.value.moreAction = false;
    console.log(err);
  }
};
const queryBranch = async () => {
  try {
    await query({
      endpoint: "ListBranches",
      service: "SETTINGS",
      storeKey: "listBranches",
    });
  } catch (err) {
    console.log(err);
  }
};

const queryDepartment = async () => {
  try {
    await query({
      endpoint: "ListDepartments",
      service: "SETTINGS",
      storeKey: "listDepartments",
    });
  } catch (err) {
    console.log(err);
  }
};

// queryBranch();
// queryDepartment();
const updateSelectedEmployees = (arg) => {
  selectedEmployees.value = [];
  selectedEmployees.value = arg;
};

const sendInvite = async () => {
  loading.value.sendInvite = true;

  let res;

  try {
    res = await mutate({
      endpoint: "SendLinkForEmployeeOnboarding",
      data: { input: { employeeIds: selectedEmployees.value } },
      service: "EMP",
    });
  } catch (err) {
    log(err);
    loading.value.sendInvite = false;
  }
  loading.value.sendInvite = false;
  if (res.success) {
    toast.success("Onboarding Link Successfully Sent.");
  }
};

const editEmp = () => {
  if (selectedEmployees.value.length < 1) {
    toast.error("You have to select an employee");
    return false;
  } else if (selectedEmployees.value.length > 1) {
    toast.error(`You can only edit one employee's details at a time`);
    return false;
  }
  router.push({
    name: "EditPersonalInfo",
    params: { id: selectedEmployees.value[0] },
  });
};

const archiveEmp = async () => {
  if (selectedEmployees.value.length < 1) {
    toast.error("You have to select an employee");
    return false;
  }

  loading.value.archive = true;

  let res;

  try {
    res = await mutate({
      endpoint: "ArchiveEmployee",
      data: { employeeIds: selectedEmployees.value },
      service: "EMP",
    });
  } catch (err) {
    log(err);
    loading.value.archive = false;
  }
  loading.value.archive = false;
  archiveWarning.value = false;

  console.log(res);
  if (res.success) {
    await queryArchiveEmployees();
    router.push({ name: "Archived" });
    toast.success(res.message);
  }
};

// Get rid of the proxy when console.log
function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}
</script>

<style></style>
