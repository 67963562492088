<template>
  <main class="md:p-4 p-0">
    <div
      class="flex flex-row-reverse items-center justify-between sm:flex-col sm:items-start mt-4 md:mt-0 mb-6 sm:mb-0"
    >
      <h4
        class="md:mt-3 md:text-sm md:text-left text-xs font-medium text-newLightgrey sm:text-headerText capitalize sm:uppercase"
      >
        Quick Loans / Manage Loan / Details
      </h4>
      <div class="text-left sm:mt-4 sm:mb-6">
        <easiBackButton></easiBackButton>
      </div>
    </div>

    <div
      class="flex flex-col gap-6 border border-dark-100 shadow-md w-full md:w-10/12 lg:w-8/12 px-2.5 py-4 md:p-8 bg-white rounded-xl md:mx-auto"
    >
      <div class="flex items-start md:items-center justify-between">
        <h2 class="text-xl md:text-2xl font-bold">Loan Details</h2>

        <div
          v-if="getLoanByID && getLoanByID.status !== 'COMPLETED'"
          class="md:flex grid grid-cols-1 items-center justify-end md:justify-start gap-4"
        >
          <div
            :class="getLoanByID && getColor(getLoanByID.status, 'text')"
            class="text-xs sm:text-sm flex items-center gap-2"
          >
            <i class="fas fa-circle text-xs"></i>
            <span
              v-if="
                getLoanByID &&
                getLoanByID.status !== 'CUSTOMER_CANCELED' &&
                getLoanByID &&
                getLoanByID.status !== 'REJECTED' &&
                getLoanByID &&
                getLoanByID.status !== 'DECLINED'
              "
              class="font-medium"
              >{{
                getLoanByID && getLoanByID.status == "PENDING"
                  ? "Pending Approval"
                  : "Active"
              }}</span
            >

            <span v-else class="font-medium">{{
              getLoanByID.status.replace(/_/g, " ")
            }}</span>
            <!-- <span
              v-if="getLoanByID && getLoanByID.status === 'REJECTED'"
              class="font-medium"
              >Rejected</span
            > -->
          </div>

          <easiButton
            size="small"
            v-if="getLoanByID && getLoanByID.status == 'ACTIVE'"
            color="primary"
            class="py-3 rounded-lg"
            @click="payNow = true"
          >
            <span>Pay Now</span>
          </easiButton>

          <easiButton
            size="small"
            color="secondary"
            @click="cancelModal = true"
            v-if="getLoanByID && getLoanByID.status == 'PENDING'"
            block
            >Cancel Application</easiButton
          >

          <easiButton
            size="small"
            color="primary"
            v-if="
              (getLoanByID && getLoanByID.status == 'COMPLETED') ||
              (getLoanByID && getLoanByID.status == 'REJECTED') ||
              (getLoanByID && getLoanByID.status == 'CUSTOMER_CANCELED') ||
              (getLoanByID && getLoanByID.status == 'DECLINED')
            "
            block
            @click="reapplyModal = true"
            >Re-apply</easiButton
          >
        </div>
        <div v-else>
          <img src="@/assets/icons/complete.svg" class="w-20 md:w-28" alt="" />
        </div>
      </div>
      <!-- <div
        v-if="
          getLoanByID &&
          getLoanByID.status !== 'COMPLETED' &&
          getLoanByID.status !== 'CUSTOMER_CANCELED' &&
          getLoanByID.status !== 'REJECTED'
        "
        class="px-2 py-2 text-xs bg-secondary-crepe text-error flex items-center gap-2 justify-center text-left"
      >
        <img src="@/assets/icons/warning.svg" class="w-4" alt="" />
        Repay on time, you can have the opportunity to increase the amount.
      </div> -->
      <div class="flex items-center gap-4">
        <span class="w-10 h-10">
          <img
            v-if="
              companyData && companyData.company && companyData.company.logo
            "
            :src="
              companyData && companyData.company && companyData.company.logo
            "
            alt=""
            class="object-cover w-10 h-10 rounded-full"
          />

          <img
            v-else
            src="@/assets/img/c-logo.png"
            class="object-cover object-center w-10 h-10"
            alt=""
          />
        </span>
        <span class="font-medium text-sm sm:text-base">{{
          companyData && companyData.company && companyData.company.companyName
        }}</span>
      </div>
      <hr class="border border-blueHoverColor text-blueHoverColor" />

      <div class="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4">
        <div
          :class="
            getLoanByID && getLoanByID.loanAmountApplied
              ? `${
                  getLoanByID.status === 'COMPLETED' ? 'amtcolor' : 'amtcolor'
                }`
              : 'bg-dark-50'
          "
          class="px-4 py-2 flex flex-col items-start rounded-lg"
          style="min-width: 150px"
        >
          <span
            class="text-xs md:text-sm font-medium text-newLightgrey text-left"
            >Loan Amount</span
          >
          <span
            :class="
              getLoanByID && getLoanByID.loanAmountApplied
                ? `${
                    getLoanByID.status === 'COMPLETED'
                      ? 'text-primary'
                      : 'text-primary'
                  }`
                : 'text-primary'
            "
            class="text-base md:text-lg font-bold"
            >{{
              getLoanByID && getLoanByID.loanAmountApplied
                ? formatAmount(parseInt(getLoanByID.loanAmountApplied))
                : "N/A"
            }}</span
          >
        </div>
        <div
          class="px-4 py-2 flex flex-col items-start rounded-lg"
          style="min-width: 150px; background-color: #f5f3f3"
        >
          <span
            class="text-xs md:text-sm font-medium text-newLightgrey text-left"
          >
            Amount Paid</span
          >
          <span
            :class="
              getLoanByID && getLoanByID.loanAmountPaid
                ? 'text-easiGreen'
                : 'text-dark-800'
            "
            class="text-base md:text-lg font-bold"
            >{{
              getLoanByID && getLoanByID.loanAmountPaid
                ? formatAmount(parseInt(getLoanByID.loanAmountPaid))
                : "N/A"
            }}</span
          >
        </div>
        <div
          class="px-4 py-2 flex flex-col items-start rounded-lg"
          style="min-width: 150px"
          :class="
            getLoanByID.status === 'COMPLETED'
              ? 'bg-paleGreen'
              : 'bg-lightSecondary'
          "
        >
          <span
            class="text-xs md:text-sm font-medium text-newLightgrey text-left"
          >
            Amount To Be Repaid</span
          >
          <span
            :class="
              getLoanByID && getLoanByID.totalRepaymentAmount
                ? `${
                    getLoanByID.status === 'COMPLETED'
                      ? 'text-easiGreen'
                      : 'text-secondary'
                  }`
                : 'text-dark-800'
            "
            class="text-base md:text-lg font-bold"
            >{{
              getLoanByID && getLoanByID.totalRepaymentAmount
                ? formatAmount(Number(getLoanByID.totalRepaymentAmount))
                : "N/A"
            }}</span
          >
        </div>
      </div>

      <hr class="border border-blueHoverColor text-blueHoverColor" />

      <div
        class="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-x-2 gap-y-4 md:place-items-start"
      >
        <div class="flex flex-col items-start text-left">
          <span class="text-xs sm:text-sm text-newLightgrey"
            >Interest Rate</span
          >
          <span class="text-sm text-dark-800"
            >{{ getLoanByID && interestRate ? interestRate : 0 }}%</span
          >
        </div>
        <div class="flex flex-col items-start text-left">
          <span class="text-xs sm:text-sm text-newLightgrey">Loan Type</span>
          <span class="text-sm capitalize text-dark-800">{{
            getLoanByID && getLoanByID.type.replace(/_/g, " ").toLowerCase()
          }}</span>
        </div>
        <div class="flex flex-col items-start text-left">
          <span class="text-xs sm:text-sm text-newLightgrey"
            >Application Date</span
          >
          <span class="text-sm text-dark-800">{{
            getLoanByID && getLoanByID.createdAt
              ? formatDateString(getLoanByID.createdAt)
              : "N/A"
          }}</span>
        </div>
        <div class="flex flex-col items-start text-left">
          <span class="text-xs sm:text-sm text-newLightgrey">Due Date</span>
          <span class="text-sm sm:text-base text-dark-800">{{
            getLoanByID && getLoanByID.durationEndedAt
              ? formatDateString(getLoanByID.durationEndedAt)
              : "N/A"
          }}</span>
        </div>
        <div class="flex flex-col items-start text-left">
          <span class="text-xs sm:text-sm text-newLightgrey"
            >Next Repayment Date</span
          >
          <span class="text-sm text-dark-800">{{
            getLoanByID &&
            getLoanByID.repaymentBreakDown &&
            formatDateString(
              getEarliestDate(getLoanByID.repaymentBreakDown) &&
                getEarliestDate(getLoanByID.repaymentBreakDown).dueDate
            )
              ? formatDateString(
                  getEarliestDate(getLoanByID.repaymentBreakDown) &&
                    getEarliestDate(getLoanByID.repaymentBreakDown).dueDate
                )
              : "N/A"
          }}</span>
        </div>
      </div>
      <hr class="border border-blueHoverColor text-blueHoverColor" />
      <div>
        <div class="text-xs sm:text-sm text-newLightgrey text-left font-medium">
          Loan Purpose
        </div>
        <div
          class="border-2 mt-2 text-left font-medium h-28 overflow-y-auto border-blueHoverColor rounded-xl p-4 text-dark-800"
        >
          {{ getLoanByID.purpose }}
        </div>
      </div>
      <div class="flex md:flex-row flex-col gap-y-4 items-center gap-x-4">
        <div
          class="bg-white w-full border border-outlineGray flex flex-col gap-y-2 rounded-lg p-3"
        >
          <span class="text-newGray text-xs">Repayment duration</span>
          <span class="text-dark-800 font-bold text-xs"
            >{{ getLoanByID.duration }} Months</span
          >
        </div>

        <div
          class="bg-white w-full border border-outlineGray flex flex-col gap-y-2 rounded-lg p-3"
          :class="!getLoanByID.repaymentType ? 'hidden' : undefined"
        >
          <span class="text-newGray text-xs">Repayment type</span>
          <span class="text-dark-800 font-bold text-xs"
            >Interest + Capital Monthly</span
          >
        </div>
      </div>
      <div class="flex flex-col gap-4 md:gap-6">
        <p class="text-left text-sm font-bold">Repayment Breakdown</p>

        <div
          class="rounded-2xl border border-outlineGray p-4 flex md:grid grid-cols-2 flex-wrap flex-col gap-y-4 items-center gap-x-4"
        >
          <div
            v-for="(payment, i) in getLoanByID.repaymentBreakDown"
            :key="payment._id"
            class="bg-lightGreyBg p-3 rounded-2xl w-full"
          >
            <span class="text-newGray font-bold text-sm capitalize"
              >{{ getPositionString(i) }} payment</span
            >
            <div class="flex flex-col text-newGray py-2">
              <div
                class="flex items-center justify-between text-sm border-b border-outlineGray py-2"
              >
                <span class="flex items-center gap-2">
                  <i class="fas fa-circle text-xs text-newGray"></i>
                  <span class="capitalize text-sm">Interest</span>
                </span>

                <span class="text-xs">
                  {{ formatAmount(payment.intrest || 0) }}
                </span>
              </div>

              <div
                class="flex items-center justify-between text-sm border-b border-outlineGray py-2"
              >
                <span class="flex items-center gap-2">
                  <i class="fas fa-circle text-xs text-newGray"></i>
                  <span class="capitalize text-sm">Total amount due</span>
                </span>

                <span class="text-xs">
                  {{ formatAmount(payment.totalAmount || 0) }}
                </span>
              </div>

              <div
                class="flex items-center justify-between text-sm border-b border-outlineGray py-2"
              >
                <span class="flex items-center gap-2">
                  <i class="fas fa-circle text-xs text-newGray"></i>
                  <span class="capitalize text-sm">Due date</span>
                </span>

                <span class="text-xs"
                  >{{ formatDateString(payment.dueDate) }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- <p class="text-center text-sm font-medium">Repayment Breakdown</p>

        <div class="grid grid-cols-6 gap-4">
          <div
            :class="
              getLoanByID && getLoanByID.type === 'PAYROLL_COVER'
                ? 'col-span-6'
                : 'col-span-6 md:col-span-3'
            "
            class="flex flex-col gap-2 py-2"
            v-for="(stage, i) in getLoanByID && getLoanByID.repaymentBreakDown"
            :key="i"
          >
            <div class="text-center text-sm font-medium flex">
              Stage {{ i + 1 }}/{{
                getLoanByID && getLoanByID.repaymentBreakDown.length
              }}
            </div>
            <div class="flex items-center justify-between text-xs">
              <span class="flex items-center gap-2">
                <i
                  :class="
                    stage.status.toUpperCase() === 'COMPLETED'
                      ? 'text-lightGreen'
                      : 'text-secondary-hover'
                  "
                  class="fas fa-circle"
                ></i>
                <span class="capitalize">Interest</span>
              </span>

              <span>
                {{
                  stage.intrest ? formatAmount(stage.intrest) : formatAmount(0)
                }}
              </span>
            </div>

            <div class="flex items-center justify-between text-xs">
              <span class="flex items-center gap-2">
                <i
                  :class="
                    stage.status.toUpperCase() === 'COMPLETED'
                      ? 'text-lightGreen'
                      : 'text-secondary-hover'
                  "
                  class="fas fa-circle"
                ></i>
                <span class="capitalize">total amount due</span>
              </span>

              <span>
                {{
                  stage.totalAmount
                    ? formatAmount(stage.totalAmount)
                    : formatAmount(0)
                }}
              </span>
            </div>

            <div class="flex items-center justify-between text-xs">
              <span class="flex items-center gap-2">
                <i
                  :class="
                    stage.status.toUpperCase() === 'COMPLETED'
                      ? 'text-lightGreen'
                      : 'text-secondary-hover'
                  "
                  class="fas fa-circle"
                ></i>
                <span class="capitalize">Due date</span>
              </span>

              <span>
                {{ stage.dueDate ? formatDateString(stage.dueDate) : "" }}
              </span>
            </div>
          </div>
        </div> -->
        <div
          @click="queryLoanContract"
          class="flex items-center justify-center gap-2"
        >
          <img
            src="@/assets/icons/loanMenu.svg"
            class="w-4 cursor-pointer"
            alt=""
          />
          <span class="text-info-light text-sm italic cursor-pointer"
            >Loan Contract</span
          >
        </div>
      </div>
    </div>

    <easiModal
      v-if="payNow"
      @close="payNow = false"
      :isHeader="false"
      :isBorder="false"
      persistence
      rounded="3xl"
    >
      <h1
        class="capitalize text-center text-dark-800 text-2xl font-bold pt-6 pb-4"
      >
        Repay Loan Now
      </h1>

      <div
        class="bg-lightGreyBg p-3 flex flex-col gap-y-1 py-8 items-center mx-auto justify-center rounded-2xl w-9/12"
      >
        <span class="text-newGray text-sm font-bold"
          >Total amount to be paid</span
        >
        <span class="text-dark-800 text-2xl font-bold">{{
          formatAmount(getLoanByID.totalRepaymentAmount)
        }}</span>
      </div>

      <div class="p-3 flex flex-col gap-y-1 w-full rounded-2xl">
        <form
          @submit.prevent="payLoan"
          class="w-9/12 flex flex-col gap-y-4 self-center"
          action=""
        >
          <easiTextInput
            :format="true"
            placeholder="Enter amount you want to pay"
            v-model="repaymentAmount"
            required
          ></easiTextInput>

          <div
            @click="payFromWallet = !payFromWallet"
            class="border flex items-center justify-between rounded-2xl p-3"
            :class="payFromWallet ? 'border-secondary' : 'border-dark-300'"
          >
            <div class="flex flex-col gap-1">
              <span class="text-dark-800 text-xs font-medium"
                >Pay From Wallet</span
              >
              Wallet Balance: {{ formatAmount(walletBalance.balance) }}
            </div>

            <img
              v-if="!payFromWallet"
              src="@/assets/icons/radio-null.svg"
              alt=""
            />
            <img v-else src="@/assets/icons/radio-check.svg" alt="" />
          </div>

          <easiButton
            v-if="creditBalance"
            class="mt-3"
            :loading="loading"
            @click="cancelLoan"
            block
            >Pay now</easiButton
          >
          <easiButton
            v-else
            class="mt-3"
            type="button"
            :loading="loading"
            @click="topup = true"
            block
            >Top up wallet</easiButton
          >
        </form>
      </div>
    </easiModal>

    <easiModal
      :isBorder="false"
      @close="cancelModal = false"
      v-if="cancelModal"
    >
      <div class="ion-padding text-center grid grid-cols-1 gap-2 items-center">
        <div
          class="pbg-background rounded-full grid grid-flow-col auto-cols-auto items-center justify-center"
        >
          <img src="@/assets/img/cancel-reason.png" alt="" />
        </div>
        <!-- <h3 class="text-primary font-extrabold text-xl">
          Cancel Loan Application
        </h3> -->
        <p class="text-lg text-center px-8 text-bodyDark">
          In our bid to serve you better, <br />
          please tell us <br />
          why you are cancelling this <br />
          loan request.
        </p>
      </div>

      <div class="flex flex-col gap-3 mt-4 w-10/12 mx-auto">
        <easiSelectInput2
          placeholder="Please select a reason"
          :autoHeight="false"
          :value="cancelReason"
          @update="cancelReason = $event"
          :options="[
            {
              label: 'Hight interest rate',
              value: 'Hight interest rate',
            },
            {
              label: 'Loan amount too small',
              value: 'Loan amount too small',
            },
            {
              label: 'Just checking my eligible amount',
              value: 'Just checking my eligible amount',
            },
            {
              label: 'I do not need the loan again',
              value: 'I do not need the loan again',
            },
            {
              label: 'Short repayment period',
              value: 'Short repayment period',
            },
          ]"
        />
        <easiButton class="mt-3" :loading="loading" @click="cancelLoan" block
          >Submit</easiButton
        >
      </div>
    </easiModal>

    <easiModal
      v-if="reapplyModal"
      @close="reapplyModal = false"
      :isBorder="true"
    >
      <template v-slot:header>
        <span class="capitalize">Re-apply</span>
      </template>

      <div class="my-6 text-center grid grid-cols-1 gap-2 items-center">
        <div
          class="pbg-background rounded-full grid grid-flow-col auto-cols-auto items-center justify-center"
        >
          <img src="@/assets/icons/noteligible.svg" alt="" />
        </div>
        <!-- <h3 class="text-primary font-extrabold text-xl">
          Cancel Loan Application
        </h3> -->
        <p class="text-lg text-center px-8 text-bodyDark">
          Are you sure you want to apply for this same loan?
        </p>
      </div>

      <div
        class="rounded-2xl bg-background px-10 grid grid-cols-1 gap-4 pb-8 pt-4"
      >
        <div
          class="border-b border-bodyLight grid grid-flow-col auto-cols-auto items-center justify-between text-sm pb-2"
        >
          <span class="text-bodyNormal">Loan Amount</span>
          <span class="font-bold text-bodyDark">{{
            formatAmount(getLoanByID.loanAmountApplied)
          }}</span>
        </div>
        <div
          class="border-b border-bodyLight grid grid-flow-col auto-cols-auto items-center justify-between text-sm pb-2"
        >
          <span class="text-bodyNormal">Loan Period</span>
          <span class="font-bold text-bodyDark"
            >{{ getLoanByID.duration }} {{ getLoanByID.durationPeriod }}</span
          >
        </div>
        <div
          class="border-b border-bodyLight grid grid-flow-col auto-cols-auto items-center justify-between text-sm pb-2"
        >
          <span class="text-bodyNormal">Interest</span>
          <span class="font-bold text-bodyDark">{{
            getLoanByID.totalIntrest.toLocaleString()
          }}</span>
        </div>

        <div class="mt-4 grid grid-cols-2 gap-2">
          <easiButton fill="outline" block @click="reapplyModal = false"
            >No</easiButton
          >
          <easiButton block :loading="loading" @click="reapplyLoan"
            >Yes, Reapply</easiButton
          >
        </div>
      </div>
    </easiModal>

    <Topup
      v-if="topup"
      @close="topup = false"
      title="Payment will reflect soon as the money is received"
      btnText="Back To Manage Loan"
    />

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span class="capitalize">Repayment Successful</span>
      </template>

      <template v-slot:action>
        <easiButton @click="updateSuccess = false"
          >Back to loan details</easiButton
        >
      </template>
    </easiSuccess>

    <easiModal :compliance="true" v-show="preview" @close="preview = false">
      <div
        style="height: calc(100vh - 250px)"
        class="w-full flex flex-col items-center h-auto overflow-y-scoll"
      >
        <iframe
          ref="pdfPreview"
          :src="pdfSrc"
          style="width: 98%; height: 100%"
        ></iframe>
      </div>
      <div class="w-full mt-8 grid grid-cols-2 gap-4 px-4 items-center">
        <easiButton
          @click="preview = false"
          :block="true"
          class="w-full"
          variant="outlined"
          >Cancel</easiButton
        >
        <easiButton
          @click="downloadBase64AsPDF(contract.base64, contract.fileName)"
          :block="true"
          class="w-full"
          >Download</easiButton
        >
      </div>
    </easiModal>

    <easiLoading v-if="loadingProgress" @close="loadingProgress = false">
      <template v-slot:heading>
        <!-- <h1>Retrieving loan contract</h1> -->
      </template>

      <template v-slot:message>
        <div class="w-full rounded-xl">
          <vue3-progress-bar></vue3-progress-bar>
        </div>

        <div>Hold on this won´t take long</div>
      </template>
    </easiLoading>
  </main>
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch } from "vue";

import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";
import { useRoute, useRouter } from "vue-router";

import Topup from "@/components/global/TopupModal";
import reapplyIcon from "@/assets/icons/noteligible.svg";

const store = useDataStore();
const toast = useToast();
const route = useRoute();
const router = useRouter();
const { formatAmount } = helperFunctions;
const { mutate, query } = store;

const companyData = computed(() => store.getCompanyDetails);
const walletBalance = computed(() => store.getWalletBalance);

const topup = ref(false);
const updateSuccess = ref(false);
const loadingProgress = ref(false);
const isChangeAmount = ref(false);
const payAll = ref(false);
const payFromWallet = ref(false);
const payNow = ref(false);

const dueAmount = ref(83333.33);
const args = reactive({
  amount: "",
});
const allLoans = computed(() => store.getAllLoans && store.getAllLoans.data);
const repaymentAmount = ref(0);

const contract = computed(
  () => store.getLoanContract && store.getLoanContract.data
);

// const sufficient = computed(
//   () => Number(walletBalance.balance) > Number(args.amount)
// );
const creditBalance = computed(() => {
  const amount =
    repaymentAmount.value && typeof repaymentAmount.value === "number"
      ? repaymentAmount.value
      : 0;

  return (
    Number(walletBalance.value ? walletBalance.value.balance : 0) >= amount
  );
});
const reapplyModal = ref(false);
const cancelModal = ref(false);
const cancelReason = ref("");
const queryWalletBalance = async () => {
  try {
    const id = "";

    let res = await query({
      endpoint: "ViewWalletBalance",
      payload: {
        input: {
          companyId: id,
        },
      },
      service: "PAYROLL",
      storeKey: "walletBalance",
    });

    return res.balance;
  } catch (err) {
    console.log(err);
  }
};
onMounted(async () => {
  args.amount = !isChangeAmount.value
    ? getEarliestDate(getLoanByID.value.repaymentBreakDown) &&
      getEarliestDate(getLoanByID.value.repaymentBreakDown).totalAmount
    : 0;
  await queryWalletBalance();
  // args.amount = dueAmount.value;
});

function getPositionString(index) {
  const positions = [
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
    "eleventh",
    "twelfth",
  ];

  if (index >= 0 && index < positions.length) {
    return positions[index];
  } else {
    return `${index + 1}th`; // Fallback for higher indices
  }
}

async function queryLoan() {
  await query({
    endpoint: "ListLoans",
    payload: {
      paging: {
        limit: 1000,
        skip: 0,
        search: "",
      },
      filter: {
        type: null,
        status: null,
      },
      sort: {
        createdAt: -1,
      },
    },
    service: "LOAN",
    storeKey: "loans",
  });
}

// function downloadBase64AsPDF(base64Data, fileName) {
//   const link = document.createElement("a");
//   link.href = "data:application/pdf;base64," + base64Data;
//   link.download = fileName;
//   link.click();
// }
function downloadBase64AsPDF(base64Data, fileName) {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: "application/pdf" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;

  // Handle Safari-specific behavior
  if (navigator.userAgent.includes("Safari")) {
    link.target = "_blank";
    // Open the PDF in a new window if download is not supported
  }

  link.click();
}

const pdfSrc = ref("");
const preview = ref(false);
const pdfPreview = ref(null);

function previewPDF(base64Data) {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: "application/pdf" });
  const url = URL.createObjectURL(blob);

  preview.value = true;
  pdfSrc.value = url;
}

// function previewPDF(base64Data) {
//   preview.value = true;
//   // iframe.src = "data:application/pdf;base64," + contract.value.base64;
//   pdfSrc.value = "data:application/pdf;base64," + base64Data;

//   // setTimeout(() => {
//   //   pdfSrc.value = "data:application/pdf;base64," + base64Data;
//   // }, 0);
// }

async function queryLoanContract() {
  loadingProgress.value = true;

  try {
    loadingProgress.value = true;
    await query({
      endpoint: "LoanContract",
      payload: {
        loanId: route.query.id,
      },
      service: "LOAN",
      storeKey: "loanContract",
    });
    previewPDF(contract.value.base64);
  } catch (e) {
    console.log(e);
  } finally {
    loadingProgress.value = false;
  }
}

const getLoanByID = computed(() => {
  const id = route.query.id;

  if (id && allLoans.value) {
    return allLoans.value.find((el) => el._id == id);
  }
});

const getColor = (status, type) => {
  const colors = {
    PENDING: {
      bg: "border-secondary bg-lightSec",
      text: "text-lightBorder",
      btn: "secondary",
    },
    ACTIVE: {
      bg: "border-info bg-blueHoverColor",
      text: "text-info",
      btn: "primary",
    },
    COMPLETED: {
      bg: "border-easiGreen bg-paleGreen",
      text: "text-easiGreen",
      btn: "easiGreen",
    },
    CUSTOMER_CANCELED: {
      bg: "border-secondary bg-lightSec",
      text: "text-secondary",
      btn: "secondary",
      status: "Cancelled",
    },
    REJECTED: {
      bg: "border-secondary bg-lightSec",
      text: "text-secondary",
      btn: "secondary",
      status: "Rejected",
    },
    DECLINED: {
      bg: "border-secondary bg-lightSec",
      text: "text-secondary",
      btn: "secondary",
      status: "Rejected",
    },
  };

  if (status) {
    return colors[status][type];
  }
};

const changeAmount = () => {
  isChangeAmount.value = !isChangeAmount.value;
  args.amount = !isChangeAmount.value
    ? getEarliestDate(getLoanByID.value.repaymentBreakDown).totalAmount
    : 0;
};

const interestRate = computed(() => {
  // const rate =
  //   (getLoanByID.value.totalIntrest / getLoanByID.value.totalRepaymentAmount) *
  //   100; //Temporary solution, pending when an endpoint is created to return dynamic values

  if (getLoanByID.value.duration <= 3) {
    return 4;
  }
  if (getLoanByID.value.duration <= 6) {
    return 4.5;
  }
  if (getLoanByID.value.duration <= 9) {
    return 4.8;
  }
  if (getLoanByID.value.duration > 9) {
    return 5;
  }

  //4% lesser than or equals to 3 months
  //4.5% lesser than or equals to 6 months
  //4.8% lesser than or equals to 9 months
  //5 % greater than
});

function formatDateString(dateStr) {
  if (dateStr) {
    const date = new Date(dateStr);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  }
  return "";
}

function getEarliestDate(arr) {
  if (arr && arr.length) {
    const currentDate = new Date(); // Get the current date
    const dueDates = arr;

    const earliestDate = dueDates
      .filter((dueDate) => new Date(dueDate.dueDate) > currentDate) // Filter out dates earlier than or equal to the current date
      .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))[0];
    console.log(arr, earliestDate);
    return earliestDate;
  }
}
const loading = ref(false);
const payLoan = async () => {
  try {
    loading.value = true;

    let res = await mutate({
      endpoint: "RePayLoan",
      data: {
        loanId: route.query.id,
        amount: Number(repaymentAmount.value),
      },
      service: "LOAN",
    });

    if (res && res.success) {
      loading.value = false;
      updateSuccess.value = true;
    } else {
      loading.value = false;
    }
  } catch (e) {
    loading.value = false;
    console.log(e.message);
    toast.error(e.message);
    // showToast(e.message);
  } finally {
    loading.value = false;
  }
};

const cancelLoan = async () => {
  try {
    loading.value = true;

    let res = await mutate({
      endpoint: "CancelLoan",
      data: {
        loanId: route.query.id,
        reason: cancelReason.value,
      },
      service: "LOAN",
    });

    if (res && res.success) {
      await queryLoan();
      (cancelModal.value = false), router.push({ name: "ManageLoans" });
      loading.value = false;
    } else {
      (cancelModal.value = false), router.push({ name: "ManageLoans" });
      loading.value = false;
    }
  } catch (e) {
    loading.value = false;
    console.log(e.message);
    toast.error(e.message);
  }
};

const reapplyLoan = async () => {
  try {
    loading.value = true;

    let res = await mutate({
      endpoint: "ReApplyLoan",
      data: {
        loanId: route.query.id,
      },
      service: "LOAN",
    });

    if (res && res.success) {
      await queryLoan();
      reapplyModal.value = false;
      router.push({ name: "ManageLoans" });
      loading.value = false;
    } else {
      reapplyModal.value = false;
      router.push({ name: "ManageLoans" });
      loading.value = false;
    }
  } catch (e) {
    loading.value = false;
    console.log(e.message);
    toast.error(e.message);
  }
};
</script>

<style scoped>
.amtcolor {
  background: rgba(240, 247, 235, 0.5);
}
</style>
