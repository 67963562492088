<template>
  <main v-if="!viewLoan" class="p-4 h-screen">
    <div class="flex justify-end sm:justify-start items-center">
      <h4
        class="md:mt-3 md:text-sm md:text-left text-xs font-medium text-headerText capitalize sm:uppercase"
      >
        Quick Loans / Loan History
      </h4>
    </div>

    <h2 class="text-left my-4 font-bold text-xl text-2xl">Loan History</h2>

    <div
      class="text-left flex flex-col items-center justify-center gap-6 mb-8"
      v-if="allLoans && !allLoans.length"
    >
      <div
        class="flex md:-mt-2 justify-center md:justify-end md:pr-20 my-10 md:my-0"
      >
        <img
          style="width: 600px; max-width: 100%"
          src="@/assets/img/moneybag.png"
          alt=""
        />
      </div>
      <h4 class="mb-4 text-headerText md:text-xl md:font-medium">
        Still empty! You have not applied for any loan
      </h4>
      <easiButton
        @click="router.push('/loan/application/view')"
        rounded="xl"
        size="large"
        class="text-xl hidden md:block"
        >Apply For New Loan</easiButton
      >
      <easiButton
        @click="router.push('/loan/application/view')"
        block
        class="block md:hidden mt-5"
        >Apply For New Loan</easiButton
      >
    </div>

    <div v-else>
      <div class="flex items-center justify-center md:justify-end md:-mt-10">
        <div
          style="background: linear-gradient(90deg, #11453b 0%, #b4a572 100%)"
          class="gradient w-full md:w-6/12 p-4 md:px-6 text-white font-medium rounded-xl grid grid-cols-3 gap-2"
        >
          <div class="flex flex-col items-center gap-2 py-2">
            <span class="text-xl font-bold md:text-2xl">{{
              allLoans.length
            }}</span>
            <span class="text-xs md:text-sm">Number of loans</span>
          </div>
          <div
            class="flex flex-col items-center border-l border-r border-white gap-2 px-1 py-2"
          >
            <span class="text-xl md:text-2xl font-bold">{{
              loansByStatus.complete
            }}</span>
            <span class="text-xs md:text-sm">Completed Loans</span>
          </div>
          <div class="flex flex-col items-center gap-2 py-2">
            <span class="text-xl md:text-2xl font-bold">{{
              loansByStatus.overdue
            }}</span>
            <span class="text-xs md:text-sm">Number of overdue</span>
          </div>
        </div>
      </div>
      <hr class="my-6 border-0.5 border-dividerColor text-dividerColor" />

      <div class="flex flex-col gap-4">
        <div v-for="(loan, i) in allLoans" :key="i">
          <TransactionCard
            @click="router.push({ query: { id: loan._id } }), (viewLoan = true)"
            :loan="loan"
          />
          <hr
            class="mt-4 last:hidden border-0.5 border-dividerColor text-dividerColor"
          />
        </div>
      </div>
    </div>
  </main>

  <LoanDetails v-else />
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";
import { useRoute, useRouter } from "vue-router";

import LoanDetails from "@/components/loan/loanDetails";
import TransactionCard from "@/components/loan/TransactionCard";

const store = useDataStore();
const toast = useToast();
const route = useRoute();
const router = useRouter();
const { formatAmount, formatDateString } = helperFunctions;

const viewLoan = computed(() => {
  return route.query && route.query.id ? true : false;
});

const { query } = store;

const allLoans = computed(() => store.getAllLoans && store.getAllLoans.data);

const loansByStatus = computed(() => {
  const complete = allLoans.value.filter((l) => l.status === "COMPLETED");
  const overdue = allLoans.value
    .filter((l) => {
      const now = new Date();

      const dateToCheck = new Date(l.durationEndedAt);

      if (dateToCheck < now) {
        return l;
      } else {
        return undefined;
      }
    })
    .filter((u) => u !== undefined);
  return {
    complete: complete.length,
    overdue: overdue.length,
  };
});

async function queryLoan() {
  await query({
    endpoint: "ListLoans",
    payload: {
      paging: {
        limit: 1000,
        skip: 0,
        search: "",
      },
      filter: {
        type: null,
        status: null,
      },
      sort: {
        createdAt: -1,
      },
    },
    service: "LOAN",
    storeKey: "loans",
  });
  console.log(allLoans.value);
}
onMounted(async () => {
  await queryLoan();

  if (route.query && route.query.id) {
    viewLoan.value = true;
  } else {
    viewLoan.value = false;
  }
});

const loans = ref([
  {
    amount: 550000,
    amountPaid: 0,
    balance: 0,
    purpose: "To pay employee for September 2022",
    dateApplied: "July 06, 2022",
    dateDue: null,
    nextRepayment: null,
    status: "PENDING",
    interestRate: 2.5,
    id: 1,
    type: "cover",
    stages: [
      {
        stage: 1,
        items: [
          {
            title: "interest",
            amount: 3506700,
          },
          {
            title: "Total Amount Due",
            amount: 565000000,
          },

          {
            title: "Due Date",
            date: "January 10, 2023",
          },
        ],
      },
    ],
  },
  {
    amount: 500000,
    amountPaid: 166666.66,
    balance: 333333.33,
    purpose: "To purchase inventory",
    dateApplied: "February 05, 2022",
    dateDue: "August 04, 2022",
    nextRepayment: "May 04, 2022",
    status: "ACTIVE",
    interestRate: 3.2,
    id: 2,
    type: "salary",
    stages: [
      {
        stage: 1,
        items: [
          {
            title: "interest",
            amount: 3506700,
          },
          {
            title: "Total Amount Due",
            amount: 565000000,
          },

          {
            title: "Due Date",
            date: "January 10, 2023",
          },
        ],
      },
      {
        stage: 2,
        items: [
          {
            title: "interest",
            amount: 3506700,
          },
          {
            title: "Total Amount Due",
            amount: 1250000,
          },

          {
            title: "Due Date",
            date: "February 09, 2023",
          },
        ],
      },
      {
        stage: 3,
        items: [
          {
            title: "interest",
            amount: 3506700,
          },
          {
            title: "Total Amount Due",
            amount: 1250000,
          },

          {
            title: "Due Date",
            date: "February 09, 2023",
          },
        ],
      },
    ],
  },
  {
    amount: 1350000,
    amountPaid: 166666.66,
    balance: 333333.33,
    purpose: "To expand business operations",
    dateApplied: "May 20, 2022",
    dateDue: "August 19, 2022",
    nextRepayment: "June 20, 2022",
    status: "COMPLETED",
    interestRate: 4,
    id: 3,
    type: "enterprise",
    stages: [
      {
        stage: 1,
        items: [
          {
            title: "interest",
            amount: 3506700,
          },
          {
            title: "Total Amount Due",
            amount: 565000000,
          },

          {
            title: "Due Date",
            date: "January 10, 2023",
          },
        ],
      },
      {
        stage: 2,
        items: [
          {
            title: "interest",
            amount: 3506700,
          },
          {
            title: "Total Amount Due",
            amount: 1250000,
          },

          {
            title: "Due Date",
            date: "February 09, 2023",
          },
        ],
      },
      {
        stage: 3,
        items: [
          {
            title: "interest",
            amount: 3506700,
          },
          {
            title: "Total Amount Due",
            amount: 1250000,
          },

          {
            title: "Due Date",
            date: "February 09, 2023",
          },
        ],
      },
      {
        stage: 4,
        items: [
          {
            title: "interest",
            amount: 3506700,
          },
          {
            title: "Total Amount Due",
            amount: 1250000,
          },

          {
            title: "Due Date",
            date: "February 09, 2023",
          },
        ],
      },
      {
        stage: 5,
        items: [
          {
            title: "interest",
            amount: 3506700,
          },
          {
            title: "Total Amount Due",
            amount: 1250000,
          },

          {
            title: "Due Date",
            date: "February 09, 2023",
          },
        ],
      },
      {
        stage: 6,
        items: [
          {
            title: "interest",
            amount: 3506700,
          },
          {
            title: "Total Amount Due",
            amount: 1250000,
          },

          {
            title: "Due Date",
            date: "February 09, 2023",
          },
        ],
      },
    ],
  },
]);
</script>

<style></style>
