<template>
  <div class="w-full flex flex-col justify-center mx-auto">
    <form
      @submit.prevent="sendArgs"
      class="md:grid flex flex-col grid-cols-2 md:items-center md:gap-y-2 md:gap-x-8"
    >
      <div class="mt-4 md:mt-0 col-span-2">
        <easiTextArea
          class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-1"
          name="address"
          id="address"
          cols="30"
          rows="3"
          placeholder="Describe the asset"
          v-model="argument.description"
        ></easiTextArea>
        <span class="text-xs text-dark-800">20 words max</span>
      </div>

      <div class="">
        <easiTextInput
          placeholder="Asset Manufacturer/Brand"
          type="text"
          name="brand"
          required
          v-model="argument.brand"
        ></easiTextInput>
      </div>

      <div class="">
        <easiTextInput
          placeholder="Model Number"
          v-model="argument.modelNo"
          type="text"
          name="num"
          required
        ></easiTextInput>
      </div>

      <div class="">
        <easiTextInput
          v-model="argument.assetQuantity"
          placeholder="Asset Quantity"
          type="number"
          name="brand"
          required
        ></easiTextInput>
      </div>

      <div class="">
        <easiTextInput
          placeholder="Price Per Unit"
          v-model="argument.pricePerUnit"
          type="text"
          :format="true"
          name="num"
          required
        ></easiTextInput>
      </div>

      <div class="">
        <easiTextInput
          placeholder="Total Purchase Price"
          v-model="argument.totalPurchasePrice"
          type="text"
          :format="true"
          :readonly="true"
          name="num"
          required
        ></easiTextInput>
      </div>

      <div class="">
        <easiTextInput
          v-model="argument.intendedUseOfAsset"
          placeholder="Intended Use of Asset"
          type="text"
          name="use"
          required
        ></easiTextInput>
      </div>

      <div class="col-span-2 flex flex-col mt-5 gap-0">
        <span class="text-xs text-newGray"
          >Please specify what inflows will finance the repayment of interest
          and capital.</span
        >
        <div class="-mt-4">
          <easiTextInput
            v-model="argument.inflow"
            type="text"
            name="use"
            required
          ></easiTextInput>
        </div>
      </div>

      <div
        class="flex items-end col-span-2 justify-end self-end gap-4 mb-4 mt-8"
      >
        <easiButton
          type="button"
          class="md:w-32 w-40"
          size="medium"
          variant="text"
          @click="$router.go(-1)"
          >Cancel</easiButton
        >
        <easiButton
          @click="$emit('back')"
          type="button"
          class="md:w-32 w-40"
          size="medium"
          variant="outlined"
          >Back</easiButton
        >
        <easiButton type="submit" size="medium" class="md:w-32 w-40"
          >Next</easiButton
        >
      </div>
    </form>
    <easiRoundLoader v-if="loading" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";

const emit = defineEmits(["asset", "back"]);
const store = useDataStore();
const route = useRoute();
const toast = useToast();

const { mutate } = store;

const loanArgsInStore = computed(() => store.getLoanArgs);
const loading = ref(false);

const argument = ref({
  totalPurchasePrice: "",
  pricePerUnit: "",
  modelNo: "",
  intendedUseOfAsset: "",
  inflow: "",
  description: "",
  brand: "",
  assetQuantity: "",
});
const qty = computed(() => argument.value.assetQuantity);
const price = computed(() => argument.value.pricePerUnit);
watch([qty, price], () => {
  argument.value.totalPurchasePrice = Number(qty.value) * Number(price.value);
});

const getLoanAmount = async (type) => {
  loading.value = true;
  try {
    let res = await mutate({
      endpoint: "FetchLoanAmount",
      data: { type },
      service: "LOAN",
    });

    if (res && res.success) {
      store.$patch({
        eligibleLoanAmount: res.data,
      });

      return true;
    } else {
      toast.error(res.message);
      // router.push({ name: "Loan" });
    }
  } catch (e) {
    console.log(e.message);
    toast.error(e.message);
    // router.push({ name: "Loan" });
  } finally {
    loading.value = false;
  }
};

async function sendArgs() {
  await getLoanAmount(route.params.id);
  emit("asset", argument.value);
}

onMounted(() => {
  if (loanArgsInStore.value && loanArgsInStore.value.assetInformation) {
    argument.value = Object.assign(
      argument.value,
      loanArgsInStore.value.assetInformation
    );
  }
});
</script>

<style scoped>
.custom-date-input {
  display: inline-block;
  position: relative;
}

.custom-date-input input[type="text"] {
  padding: 5px;
  width: 150px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.custom-date-input::before {
  content: attr(placeholder);
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #999;
}
</style>
