<template>
  <div v-if="data" class="grid grid-cols-1 gap-5">
    <div>
      <h4 class="text-sm text-dark-800 font-medium">How much do you need?</h4>
      <easiTextInput
        v-model="data.loanAmount"
        :error="error.loanAmount"
        format
        label="Enter amount"
      />
    </div>

    <div class="grid grid-cols-1 gap-3">
      <h4 class="text-sm text-dark-800 font-medium">
        Select repayment duration
      </h4>

      <easiSelectInput2
        placeholder="Select repayment duration"
        required
        hideCheckBox
        noSearch
        @update="data.duration = $event"
        :error="error.duration"
        :value="data.duration"
        :options="durationOptions"
      />
    </div>

    <div class="grid grid-cols-1 gap-3">
      <h4 class="text-sm text-dark-800 font-medium">Select repayment type</h4>

      <div class="grid grid-cols-2 gap-4">
        <RepaymentTypeCard
          v-for="item in paymentTypeOptions"
          :key="item.value"
          :text="item.label"
          :active="item.value === data.repaymentType"
          @click="data.repaymentType = item.value"
        />
      </div>

      <LoanAmountCard
        v-if="data.repaymentType === paymentTypeOptions[0].value"
        small
        plain
        title="Monthly repayment <br/> amount (interest + capital)"
        :value="monthlyRepaymentPlusCapital"
      />

      <div
        v-if="data.repaymentType === paymentTypeOptions[1].value"
        class="p-3 text-dark-800 rounded-2xl bg-newGrey3 text-sm"
      >
        You will pay back <strong>{{ interestMonthly }}</strong> interest
        monthly and <strong>{{ formatAmount(data.loanAmount) }}</strong> at the
        end of {{ data.duration }}
      </div>
      <span class="text-error text-xs -mt-2" v-if="error.repaymentType">
        {{ error.repaymentType }}
      </span>
    </div>

    <div>
      <h4 class="text-sm text-dark-800 mb-2 font-medium">
        Select loan purpose (optional)
      </h4>

      <easiSelectInput2
        placeholder="Select loan purpose"
        required
        hideCheckBox
        noSearch
        showOthers
        :options="purposeOptions"
        @update="data.purpose = $event"
        :value="data.purpose"
        :error="error.purpose"
      />
    </div>

    <div class="grid grid-cols-2 gap-3">
      <easiButton
        variant="text"
        background="#11453B1A"
        @click="emit('cancel')"
        block
        >Cancel</easiButton
      >
      <easiButton @click="emit('proceed')" block>
        <div class="flex items-center gap-2">
          Proceed
          <i class="pi pi-arrow-right" />
        </div>
      </easiButton>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import RepaymentTypeCard from "./RepaymentTypeCard.vue";
import LoanAmountCard from "../LoanAmountCard.vue";
import { helperFunctions } from "@/composable/helperFunctions";

const { formatDate, formatAmount } = helperFunctions;

const props = defineProps({
  data: Object,
  error: Object,
  eligibilityData: Object,
});

const emit = defineEmits(["cancel", "proceed"]);

const interestRate = 5.5;

const durationOptions = computed(() => {
  const durations = [];
  const length = props.eligibilityData ? props.eligibilityData.maxDuration : 12;
  for (let i = 1; i <= length; i++) {
    const str = `${i} month${i > 1 ? "s" : ""}`;
    durations.push({
      label: str,
      value: str,
    });
  }
  return durations;
});

const paymentTypeOptions = [
  {
    label: `Interest + <br/> Capital monthly`,
    value: "INTEREST_WITH_CAPITAL_MONTHLY",
  },
  {
    label: `Interest + Capital <br/> at the end of tenure`,
    value: "INTEREST_WITH_CAPITAL_TENURE_END",
  },
];

const purposeOptions = computed(() => {
  const purposes = [
    "Hire talents",
    "Business expansion",
    "Asset purchase",
    "Invoice financing",
  ];

  return purposes.map((el) => {
    return {
      label: el,
      value: el,
    };
  });
});

const interestMonthly = computed(() => {
  const { loanAmount, duration } = props.data;
  if (!loanAmount || !duration) return formatAmount(0);
  const interestAmount = (interestRate * loanAmount) / 100;
  const durationInt = +duration.split(" ")[0];

  return formatAmount(interestAmount / durationInt);
});

const monthlyRepaymentPlusCapital = computed(() => {
  const { loanAmount, duration } = props.data;
  if (!loanAmount || !duration) return 0;

  const interestAmount = (interestRate * loanAmount) / 100;
  const durationInt = +duration.split(" ")[0];

  return (interestAmount + loanAmount) / durationInt;
});
</script>

<style></style>
