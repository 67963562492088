<template>
  <div class="p-4 md:p-6 flex flex-col gap-5">
    <h4 class="text-center font-semibold mb-4">Loan preview</h4>
    <LoanAmountCard
      title="Total amount <br/> payable"
      :value="+data.eligibleAmount || 0"
    />

    <div v-if="data" class="p-4 bg-newGrey3 rounded-3xl grid grid-cols-1 gap-4">
      <easiTextCard
        small
        type="text"
        title="Total amount requested"
        :value="formatAmount(+data.loanAmount)"
      />

      <div class="grid grid-cols-2 gap-4">
        <easiTextCard
          small
          type="text"
          title="Repayment period"
          :value="data.duration"
        />
        <easiTextCard
          small
          type="text"
          :title="
            type === 'payroll' ? 'Loan interest' : 'Monthly interest rate'
          "
          :value="`${data.interestRate}%`"
        />
        <easiTextCard
          small
          type="text"
          v-if="type === 'operational'"
          :title="repayementTypes[data.repaymentType]"
          :value="formatAmount(+data.installmentAmount)"
        />
        <easiTextCard
          small
          type="text"
          v-if="type === 'operational'"
          title="First repayment date"
          :value="formatDate(data.firstRepaymentDate)"
        />
      </div>

      <easiTextCard
        small
        type="text"
        v-if="type === 'payroll'"
        title="Repayment date"
        :value="formatDate(data.repaymentDate)"
      />
      <easiTextCard
        small
        type="text"
        v-if="type === 'business'"
        title="Monthly repayment amount"
        :value="formatAmount(+data.installmentAmount)"
      />

      <div
        @click="breakdownModal = true"
        class="cursor-pointer text-xs text-primary px-3 py-2 bg-white rounded-full flex items-center justify-between"
      >
        View repayment breakdown
        <i class="pi pi-chevron-right text-xs text-primary" />
      </div>
    </div>

    <div class="flex items-center justify-between gap-4 text-dark-700">
      <span class="text-sm">
        I have read and i accept the
        <span @click="termsModal = true" class="text-info cursor-pointer"
          >Terms and Conditions for this loan </span
        >.
      </span>

      <easiSwitch v-model="acceptTerms" />
    </div>

    <easiButton
      :loading="loading"
      block
      :disabled="!acceptTerms"
      @click="handleTakeLoan"
      >Take loan</easiButton
    >
  </div>

  <LoanBreakdown
    :data="data.loanBreakdown"
    :isOpen="breakdownModal"
    @close="breakdownModal = false"
  />
  <TermsAndCondition :isOpen="termsModal" @close="termsModal = false" />
</template>

<script setup>
import LoanAmountCard from "./LoanAmountCard.vue";
import LoanBreakdown from "./LoanBreakdown.vue";
import TermsAndCondition from "./TermsAndCondition.vue";

import { computed, ref } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
const { formatAmount, formatDate } = helperFunctions;

const props = defineProps({
  data: Object,
  loading: Boolean,
  type: {
    type: String,
    default: "operational",
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["submit"]);

const termsModal = ref(false);
const breakdownModal = ref(false);
const acceptTerms = ref(false);

const repayementTypes = {
  INTEREST_WITH_CAPITAL_MONTHLY: "Interest +  Capital monthly",
  INTEREST_WITH_CAPITAL_TENURE_END: "Interest + Capital  at the end of tenure",
};

const handleClose = () => {
  emit("close");
};

const handleTakeLoan = () => {
  emit("submit");
};
</script>

<style></style>
