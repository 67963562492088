<template>
  <main class="px-2 md:px-6 py-6 flex flex-col gap-5">
    <h4
      class="uppercase md:uppercase md:text-sm md:text-left text-xs font-medium text-headerText"
    >
      Quick Loans
    </h4>

    <LoanBanner />

    <div
      style="box-shadow: 0px 6px 12px 0px #aaaaaa1c"
      class="w-full py-4 md:py-4 rounded-3xl bg-white flex flex-col gap-3"
    >
      <div
        class="w-full md:w-11/12 mx-auto p-4 grid grid-cols-1 md:grid-cols-2 gap-4"
      >
        <div class="p-2 sm:p-3 border border-outlineGray rounded-xl">
          <LoanAmountCard
            title="Total loan <br/> payable"
            plain
            rounded="rounded-lg"
            :value="activeLoan && activeLoan.amountPayable"
          />
        </div>
        <div
          class="p-2 sm:p-3 border border-outlineGray rounded-xl grid grid-cols-2 gap-3 sm:gap-4"
        >
          <div
            v-for="item in currentLoanOptions"
            :key="item.title"
            style="background-color: #f8f8f8"
            class="p-3 rounded-lg flex flex-col"
          >
            <span class="text-xs sm:text-sm">{{ item.title }}</span>
            <span
              :class="[item.class, 'font-bold text-base sm:text-xl capitalize']"
              >{{ item.value }}</span
            >
          </div>
        </div>
      </div>

      <hr class="border-4 border-lighterGray" />

      <div class="w-full md:w-11/12 mx-auto p-4">
        <h2 class="text-2xl sm:text-4xl font-bold text-dark-900">
          Get Eazi loans in minutes
        </h2>

        <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 mt-5">
          <LoanCard
            @click="startApplication(item)"
            v-for="item in loanTypes"
            :key="item.title"
            :data="item"
          />
        </div>
      </div>
    </div>

    <LoanApplication :isOpen="isOpen" @close="closeApplication" />

    <!-- An alert is being teleported from other loan components -->
    <div id="loan-success-alert"></div>

    <easiLoading v-if="loading" />
  </main>
</template>

<script setup>
import LoanBanner from "@/components/loan-new/LoanBanner.vue";
import LoanAmountCard from "@/components/loan-new/LoanAmountCard.vue";
import LoanCard from "@/components/loan-new/LoanCard.vue";
import LoanApplication from "@/components/loan-new/LoanApplication.vue";

import operationalIcon from "@/assets/icons/operational-icon.svg";
import payrollIcon from "@/assets/icons/payroll-icon.svg";
import businessIcon from "@/assets/icons/small-business-icon.svg";

import { computed, onMounted, ref } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useRouter } from "vue-router";

import { getRecentLoan } from "@/services/loan";
const { formatDate, formatAmount } = helperFunctions;
import { useDataStore } from "@/stores/data.js";
const router = useRouter();
const store = useDataStore();

const loading = ref(false);
const isOpen = ref(false);

const activeLoan = computed(() => store.getCurrentLoan);

const currentLoanOptions = computed(() => {
  let title = "Next repayment date";
  let value = "N/A";
  let amountDue = formatAmount(0);

  const isPending = activeLoan.value && activeLoan.value.status === "PENDING";
  const isApproved = activeLoan.value && activeLoan.value.installment;

  if (isApproved) {
    value = formatDate(
      activeLoan.value.installment.summary.nextInstallmentDate
    );

    amountDue = formatAmount(
      activeLoan.value.installment.summary.totalUnpaidAmount
    );
  }

  if (isPending) {
    title = "Loan request status";
    value = activeLoan.value.status.toLowerCase();
  }

  return [
    {
      title,
      value,
      class: "text-dark-800",
    },
    {
      title: "Amount due",
      value: amountDue,
      class: "text-primary",
    },
  ];
});

const loanTypes = [
  {
    title: "Operational Loan",
    slug: "Operational_Loans",
    description:
      "Fuel your business operations with ease through our Operational Loans.",
    icon: operationalIcon,
  },
  {
    title: "Payroll cover",
    slug: "Payroll_Cover",
    description:
      "Meet up with staff obligations with up to 60% of your salary budget.",
    icon: payrollIcon,
  },
  {
    title: "Small Business loan",
    slug: "Small_Business_Loan",
    description:
      "You're looking to starting a new venture or  expand your business.",
    icon: businessIcon,
  },
];

const startApplication = (arg) => {
  router.push({
    name: "LoanApplication",
    query: {
      type: arg.slug,
    },
  });
  isOpen.value = true;
};

const closeApplication = () => {
  router.go(-1);
  isOpen.value = false;
};

onMounted(async () => {
  console.log(activeLoan.value);
  try {
    loading.value = activeLoan.value === null;
    await getRecentLoan();
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
});
</script>

<style></style>
