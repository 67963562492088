<template>
  <div class="grid grid-cols-1 gap-5 px-4">
    <LoanAmountCard
      plain
      title="Loan amount <br/> to be paid"
      :value="loanAmountToBePaid"
    />

    <div>
      <div
        :class="[
          errorRules.balance
            ? 'border-newError bg-newErrorLight text-newError'
            : 'border-successStatus bg-successLight text-successStatus',
        ]"
        class="flex items-center justify-between rounded-2xl border p-4 py-5 text-sm"
      >
        <span>Your Wallet Balance</span>
        <span>{{ formatAmount(walletBalance) }}</span>
      </div>
      <span v-if="errorRules.balance" class="text-xs text-error">{{
        errorRules.balance
      }}</span>
    </div>

    <easiButton block @click="submit">
      {{ errorRules.balance ? "Fund Wallet & Pay" : "Pay now" }}
    </easiButton>
  </div>
</template>

<script setup>
import LoanAmountCard from "../LoanAmountCard.vue";
import { reactive, ref, watch, computed } from "vue";
import { useDataStore } from "@/stores/data.js";

import { helperFunctions } from "@/composable/helperFunctions";
const { formatAmount } = helperFunctions;

const store = useDataStore();
const props = defineProps({
  loan: Object,
});

const emit = defineEmits(["submit", "fundWallet"]);
const walletBalance = computed(
  () => (store.getWalletBalance && store.getWalletBalance.balance) || 0
);

const errorRules = reactive({
  balance: false,
});

const loanAmountToBePaid = computed(() => {
  if (!props.loan) return 0;
  return props.loan.installment.summary.totalUnpaidAmount;
});

const checkAmount = () => {
  console.log(loanAmountToBePaid.value, walletBalance.value);
  errorRules.balance =
    loanAmountToBePaid.value > walletBalance.value
      ? "Insufficient balance"
      : false;
};

watch([loanAmountToBePaid, walletBalance], checkAmount, { immediate: true });

const submit = async () => {
  if (errorRules.balance) {
    //Fund wallet
    emit("fundWallet");
    return;
  }

  const payload = {
    clearAll: true,
    installmentCount: null,
    loanId: props.loan._id,
  };

  emit("submit", payload);
};
</script>

<style></style>
