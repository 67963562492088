<template>
  <main class="md:mx-4 p-1 md:p-4 md:px-3">
    <div class="relative flex items-center gap-0">
      <span class="text-primary-hover capitalize font-bold my-4 text-2xl"> compliance </span>
      <!-- <div
        @mouseenter="showTip = true"
        @mouseleave="showTip = false"
        class="cursor-pointer w-8 h-8 border border-newAsh flex justify-center items-center bg-white rounded-lg"
      >
        <img src="@/assets/icons/info-icon.svg" alt="" />
      </div> -->

      <!-- <div v-show="showTip" class="absolute z-50 rounded-lg top-1 left-40 mt-12 w-80 flex flex-col ml-5 p-3 bg-white">
        <span class="text-primary-hover capitalize font-bold text-sm"> compliance </span>
        <span class="text-xs text-dark-800 leading-5"
          >Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis ipsa quam, nisi maiores optio quas. Deleniti
          est optio inventore minima, nostrum cum nisi obcaecati saepe, animi, repudiandae explicabo. Quidem,
          architecto!</span
        >
      </div> -->
    </div>
    <div class="w-full rounded-3xl bg-white p-4">
      <div class="relative flex items-center justify-between text-newGray">
        <h2 class="font-medium">Overview of compliances fulfilled</h2>
        <div class="flex items-center gap-3">
          <img src="@/assets/icons/filter.svg" alt="" />
          <span class="hidden text-sm md:block">Duration</span>
          <div @click="timeSelect = !timeSelect" class="cursor-pointer gap-3 flex items-center">
            <span class="text-grey text-sm capitalize">{{ selectedTimeline }}</span>
            <div class="text-newLightgrey text-sm font-bold">></div>
          </div>
        </div>

        <div
          v-show="timeSelect"
          class="w-36 absolute group-hover:text-primary flex right-0 flex-col bg-white rounded-lg top-5 shadow-md"
          style="z-index: 1"
        >
          <div class="flex flex-col z-50 text-left items-start text-dark-800 text-xs cursor-pointer pt-2">
            <span
              v-for="timeline in ['month', 'year']"
              :key="timeline"
              class="hover:bg-newLimeGreen text-sm cursor-pointer py-2 px-4 capitalize w-full"
              @click="
                () => {
                  selectedTimeline = timeline;
                }
              "
              @mouseover="selectedTimeline = timeline"
            >
              {{ timeline }}
            </span>
          </div>
        </div>

        <div
          v-show="selectedTimeline && selectedTimeline.length && timeSelect"
          class="z-50 absolute md:right-40 right-0 md:top-10 top-20 mt-5 md:mt-0 md:-mr-3"
        >
          <MonthAndYear :type="selectedTimeline" @update="updateFilterData($event, selectedTimeline)" />
        </div>
      </div>

      <div class="w-full flex md:grid grid-cols-3 flex-col mt-5 gap-3">
        <div v-for="total in totalDetails" :key="total.type" class="bg-newLimeGreen w-full rounded-2xl p-2 flex gap-3">
          <div class="bg-white flex flex-col items-center justify-center rounded-xl w-10 h-10">
            <img class="w-6 contain" :src="total.icon" alt="" />
          </div>

          <div class="flex flex-col justify-center gap-1">
            <p class="capitalize text-newGray text-xs">
              total
              <span :class="['Pension', 'pension'].includes(total.type) ? 'capitalize' : 'uppercase'">{{
                total.type
              }}</span>
            </p>
            <span class="text-dark-800 font-medium text-sm">{{ formatAmount(total.amount) }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <h4
        class="md:mt-3 md:text-sm md:text-left text-right text-xs font-medium text-dark-800"
      >
        Compliance / Register Compliance
      </h4> -->

    <!-- <div class="md:block hidden text-left my-6">
        <easiBackButton></easiBackButton>
      </div> -->

    <!-- <div
        class="text-left md:mt-7 mt-3 font-medium text-sm flex flex-col gap-y-1"
      >
        <p class="text-dark-800 text-lg md:text-xl font-bold">Our services</p>
        <p class="text-dark-800">
          Please select the compliance you want to register or make payment
        </p>
     
      </div> -->

    <!-- <div
        class="grid z-50 grid-cols-3 gap-y-4 md:gap-y-4 md:gap-x-4 gap-x-2 my-4 md:my-5"
      >
        <div v-for="ded in statutoryDeds" :key="ded.name" class="flex flex-col">
          <div
            :class="hoverType === ded.name ? 'bg-darkColor' : ded.style"
            class="rounded-3xl z-10 w-28 md:w-auto md:h-28 shadow-xl flex cursor-pointer md:flex-col justify-center items-center p-8 md:p-1 relative"
            @mouseenter="hoverType = ded.name"
            @mouseleave="hoverType = ''"
            @click.self="
              $router.push({
                name: 'ViewSingleCompliance',
                params: { id: ded.name.toLowerCase() },
              })
            "
          >
            <img
              :class="[
                ded.name === 'Pension'
                  ? 'md:w-16 h-full w-full'
                  : 'md:w-24 w-full h-full',
                hoverType == ded.name ? 'invisible' : undefined,
              ]"
              :src="`${ded.icon}`"
              alt=""
            />
            <div
              @click="
                $router.push({
                  name: 'ViewSingleCompliance',
                  params: { id: ded.name.toLowerCase() },
                })
              "
              :class="hoverType === ded.name ? 'top-4' : undefined"
              class="absolute flex items-center justify-center w-full"
            >
              <span
                :class="hoverType === ded.name ? 'md:ml-7' : undefined"
                class="w-full text-center text-white font-bold text-lg"
                >{{ ded.name }}</span
              >
              <div
                v-show="hoverType === ded.name"
                class="cursor-pointer flex justify-items-end mr-4 justify-self-end"
              >
                <img src="@/assets/icons/right-arrow.svg" alt="" />
              </div>
            </div>
  
            <div
              v-show="hoverType === ded.name"
              class="absolute flex items-center w-11/12 top-16 md:-mb-5 gap-4 justify-around"
            >
              <div
                @click="openRegistrationModal(ded.name)"
                class="bg-primary-hover hover:bg-lightHover hover:text-mint text-newLightGray w-full cursor-pointer text-center text-xs rounded-full p-2"
              >
                Register
              </div>
              <div
                @click="openPaymentModal(ded.key)"
                class="bg-primary-hover hover:bg-lightHover hover:text-mint text-newLightGray w-full cursor-pointer text-center text-xs rounded-full p-2"
              >
                Make Payment
              </div>
            </div>
          </div>
        </div>
      </div> -->

    <!-- <img
        class="absolute right-12 -z-1"
        src="@/assets/icons/money-icon-4.svg"
        alt=""
      /> -->

    <div
      class="flex text-center w-full mt-9 border border-outlineGray rounded-xl tracking-wide justify-start gap-x-2 p-2 text-xs font-medium cursor-pointer bg-white"
    >
      <span
        v-for="opt in filterOpts"
        :key="opt.value"
        @click="filterHistory(opt.value)"
        class="w-full text-xs md:text-sm capitalize font-bold md:uppercase rounded-lg md:px-5 md:py-2 px-3 py-2"
        :class="activeFilter === opt.value ? 'bg-primary shadow-2xl text-white' : 'bg-newGrey'"
        >{{ breakPoints("md") ? opt.label : opt.mobileLabel }}
      </span>
    </div>

    <div class="md:my-4 my-3 w-full bg-white rounded-2xl border border-outlineGray px-2 md:px-4 py-3">
      <div v-if="loading" class="w-full">
        <easiLoader />
      </div>
      <div
        v-show="activeFilter === 'registration'"
        class="hidden text-center w-full mb-3 rounded-xl tracking-wide justify-start gap-x-2 p-1 text-xs font-medium cursor-pointer bg-newGrey"
      >
        <span
          v-for="opt in registrationFilterOpts"
          :key="opt.value"
          @click="filterRegHistory(opt.value)"
          class="w-full text-xs capitalize md:uppercase rounded-lg md:px-5 md:py-2 px-3 py-2"
          :class="activeRegFilter === opt.value ? 'bg-white shadow-2xl text-grey' : ''"
          >{{ opt.label }} <span class="hidden md:inline-block">(1)</span>
        </span>
      </div>

      <easiActiveFilter @close="removeFilter" :filters="activeFilters" />

      <!-- <hr class="border-1 border-outlineGray mb-2" /> -->

      <ComplianceTransactionHistory v-if="activeFilter == 'transaction'" />
      <ComplianceRegistrationHistory v-if="activeFilter == 'registration'" :data="regDetails" />

      <!-- <ComplianceTable
          class="w-full"
          v-if="visibleData && visibleData.length"
          :data="visibleData"
          :header="headers"
          :readonly="true"
          :routable="true"
          :is-search="isSearch"
          @deleted="queryEmployeeCompliance"
        /> -->
      <!-- <hr class="border-0.5 border-dividerColor w-full mt-4 mb-2" />
        <div class="flex w-full justify-end mt-3 items-center">
          <easiPagin
            :data="complianceData.length"
            @page:update="updatePage"
            :currentPage="currentPage"
            :visibleData="visibleData"
            :pageSize="pageSize"
            class="w-full md:mt-4"
          />
  
          <p
            @click="$router.push({ name: 'ViewComplianceHistory' })"
            class="cursor-pointer text-primary text-xs"
          >
            VIEW ALL COMPLIANCES HISTORY <span class="text-lg">></span>
          </p>
        </div> -->
    </div>
  </main>

  <easiModal
    v-if="openModal"
    :isHeader="false"
    :showBackButton="['paye', 'nhf', 'pension'].includes(activeModal) ? true : false"
    @back="goBack = !goBack"
    :persistence="false"
    :size="['pension', 'nsitf'].includes(activeModal) ? 'sm' : ''"
    @close="closeAll"
  >
    <PayeComponent
      @close="closeAll"
      @registered="closeAll"
      :goBack="goBack"
      @back="($event) => (goBack = $event)"
      v-if="activeModal === 'paye'"
    />
    <HmoComponent
      @close="closeAll"
      @registered="closeAll"
      :goBack="goBack"
      @back="($event) => (goBack = $event)"
      v-if="activeModal === 'hmo'"
    />
    <PensionComponent
      @close="closeAll"
      @registered="closeAll"
      :goBack="goBack"
      @back="($event) => (goBack = $event)"
      v-if="activeModal === 'pension'"
    />
    <NhfComponent
      @close="closeAll"
      @registered="closeAll"
      :goBack="goBack"
      @back="($event) => (goBack = $event)"
      v-if="activeModal === 'nhf'"
    />
    <ItfComponent
      @close="closeAll"
      @registered="closeAll"
      :goBack="goBack"
      @back="($event) => (goBack = $event)"
      v-if="activeModal === 'itf'"
    />
    <NsitfComponent
      @close="closeAll"
      @registered="closeAll"
      :goBack="goBack"
      @back="($event) => (goBack = $event)"
      v-if="activeModal === 'nsitf'"
    />
  </easiModal>

  <easiEligible v-if="ineligible" @close="ineligible = false">
    <template v-slot:message>
      <span v-if="validEmployeeLength" class="text-center"
        >Sorry this is only available for employers with <strong> more than five(5) employees.</strong>or an annual
        turnover of 60 million in revenue</span
      >

      <span v-if="validTurnover" class="text-center"
        >Sorry this is only available for employers <strong> with more than five(5) employees </strong>or an annual
        turnover of 60 million in revenue</span
      >
    </template>

    <template v-slot:action>
      <div class="px-4 sm:px-10 lg:px-6 xl:px-8">
        <button
          @click="ineligible = false"
          class="focus:outline-none text-sm mt-2 py-3 w-full px-4 md:mx-auto justify-center bg-primary rounded-full text-white"
        >
          Back To Compliance
        </button>
      </div></template
    >
  </easiEligible>

  <easiModal v-if="checkEligibility" :isBorder="false" @close="checkEligibility = false">
    <template v-slot:header><h1 class="text-bodyDark font-bold">ITF Registration</h1></template>

    <h2 class="text-primary mt-4 text-xl font-bold text-center">
      Is your turnover up to 60 million <br />
      in a year?
    </h2>

    <hr class="mb-5 mt-3 border-0.5 w-11/12 mx-auto border-dividerColor text-dividerColor" />

    <div class="flex w-full gap-x-8 items-center justify-center px-3 md:px-12 lg:px-8 xl:px-10">
      <button
        @click="openItf(false)"
        class="focus:outline-none text-sm mt-2 py-3 w-full md:w-full px-4 bg-secondary rounded-full text-white"
      >
        No
      </button>

      <button
        @click="openItf(true)"
        class="focus:outline-none text-sm mt-2 py-3 w-full md:w-full px-4 bg-success rounded-full text-white"
      >
        Yes
      </button>
    </div>
  </easiModal>

  <!--Payment step one start-->
  <easiModal
    v-if="paymentStepOne"
    :isHeader="false"
    :showBackButton="['paye', 'nhf'].includes(activeModal) ? true : false"
    @back="goBack = !goBack"
    @close="closeAll"
  >
    <div class="p-2 w-full text-center flex flex-col gap-y-2">
      <h1 class="font-medium uppercase font-bold">Pay Compliances</h1>
      <span class="text-center text-bodyDark text-xs">Kindly set the month and year for payment</span>

      <div
        class="cursor-pointer border border-outlineGray p-3 w-11/12 rounded-xl flex flex-col gap-y-3 mt-5 self-center"
      >
        <div
          v-for="type in ['month', 'year']"
          :key="type"
          @click.self="handleType(type)"
          class="cursor-pointer relative bg-highlight border border-outlineGray flex justify-between rounded-lg p-2 items-center"
        >
          <span class="capitalize text-sm font-bold">
            {{ !updated ? `choose ${type}` : type === "month" ? months[args["month"]] : args[type] }}</span
          >

          <div class="pointer-events-none rotate-45">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.875 16.25L13.125 10L6.875 3.75"
                stroke="#0F172A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div v-show="showDropdown && selectedType === type" class="z-50 absolute top-10">
            <MonthAndYear :type="type" @update="updateVal($event, type)" />
          </div>
        </div>
      </div>

      <easiButton
        @click="
          () => {
            paymentStepOne = false;
            paymentStepTwo = true;
          }
        "
        class="w-11/12 my-2"
        >Continue</easiButton
      >
    </div>
  </easiModal>
  <!--Payment step one end-->

  <!--Payment step two start-->
  <easiModal
    v-if="paymentStepTwo"
    :isHeader="false"
    :showBackButton="true"
    :compliance="true"
    @back="
      () => {
        paymentStepOne = true;
        paymentStepTwo = false;
      }
    "
    @close="closeAll"
  >
    <div class="p-2 w-full text-center flex flex-col gap-y-2">
      <h1 class="font-medium capitalize font-bold">Select Beneficiary</h1>
      <span class="text-center text-bodyDark text-xs"
        >Add employees you want to pay from beneficiary list and click done</span
      >

      <div class="flex justify-center gap-4 mt-4">
        <div
          v-for="type in ['month', 'year']"
          :key="type"
          @click.self="handleType(type)"
          class="pointer-events-none relative bg-highlight w-32 border border-outlineGray text-grey flex justify-between rounded-lg p-2"
        >
          <span class="capitalize text-sm font-medium">
            {{ type === "month" ? months[args["month"]] : args.year }}</span
          >

          <div class="pointer-events-none rotate-45">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.75 6.875L10 13.125L16.25 6.875"
                stroke="#515251"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div v-show="showDropdown && selectedType === type" class="z-50 absolute top-10">
            <MonthAndYear :type="type" @update="updateVal($event, type)" />
          </div>
        </div>
      </div>
      <div
        v-for="type in staticStatutories"
        :key="type"
        class="relative cursor-pointer border border-outlineGray p-3 w-11/12 rounded-xl"
        :class="activeModal != type ? 'hidden' : 'flex flex-col mt-5 self-center'"
      >
        <div
          @click="handleBreakdown(type)"
          class="bg-highlight border border-outlineGray flex justify-between rounded-lg p-2 items-center"
        >
          <span
            v-show="!showStatutoryList"
            @click.self="showStatutoryList = !showStatutoryList"
            class="text-sm font-bold"
            :class="['pension'].includes(type) ? 'capitalize' : 'uppercase'"
          >
            {{ type }}</span
          >

          <main
            v-show="showStatutoryList"
            class="z-10 top-5 absolute bg-white rounded-xl p-2 grid grid-cols-1 gap-2 w-52 items-center justify-between"
          >
            <div class="h-44 overflow-y-scroll">
              <div
                v-for="ded in statutoryDeds"
                :key="ded.key"
                @click.self="handleTypeUpdate(type, ded.key)"
                class="relative border p-2 mb-2 w-full rounded-lg cursor-pointer border-outlineGray text-center text-sm text-newGray"
              >
                {{ ded.name }}
              </div>
            </div>
            <div class="pointer-events-none flex justify-center rotate-45">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.75 6.875L10 13.125L16.25 6.875"
                  stroke="#515251"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </main>

          <div class="rotate-45">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.75 6.875L10 13.125L16.25 6.875"
                stroke="#515251"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div
          v-show="showBreakdown && breakdownType === type"
          class="bg-lightGreyBg mt-3 border border-outlineGray rounded-xl p-3 flex justify-between"
        >
          <div class="flex flex-col">
            <span class="text-xs text-newGray"> Added beneficiaries</span>
            <span class="text-sm mt-1 text-dark-800 font-medium text-left">{{
              trackPaymentDetails[type] && trackPaymentDetails[type].employees
                ? trackPaymentDetails[type].employees.length
                : 0
            }}</span>
          </div>

          <div class="flex flex-col">
            <span class="text-xs text-newGray">Total amount to be paid</span>
            <span class="text-sm mt-1 text-dark-800 font-medium text-left">{{ formatAmount(0) }}</span>
          </div>
        </div>

        <div v-show="showBreakdown && breakdownType === type" class="mt-3">
          <p for="email" class="text-bodyDark w-full text-left text-xs font-medium">Add Employees</p>
          <easiEmployeeBonus
            :single="false"
            :directorView="true"
            @update="updateEmp($event, type)"
            :value="trackPaymentDetails[type] && trackPaymentDetails[type].employees"
            :options="createEmpList()"
            class="mt-1 mb-5"
          />
        </div>
      </div>

      <div class="flex gap-4 justify-center w-11/12 mx-auto items-center">
        <div class="w-full">
          <easiButton @click="saveAndAddNew(false)" variant="outlined" class="w-full my-2"
            >Save & Add New Compliance</easiButton
          >
        </div>
        <div class="w-full">
          <easiButton :loading="previewLoading" @click="saveAndAddNew(true)" class="w-full my-2">Done</easiButton>
        </div>
      </div>
    </div>
  </easiModal>

  <!--Payment step two end-->

  <!-- Payment step three start-->
  <!-- <easiModal
    v-if="paymentStepThree"
    :isHeader="false"
    :showBackButton="true"
    @back="
      () => {
        paymentStepThree = false;
        paymentStepTwo = true;
      }
    "
    @close="closeAll"
  >
    <div class="p-2 w-full text-center flex flex-col gap-y-2">
      <h1 class="font-medium uppercase font-bold">Pay Compliances</h1>
      <span class="text-center text-bodyDark text-xs">Kindly click compliance to edit/add staff beneficiaries</span>

      <div
        class="cursor-pointer border border-outlineGray p-3 w-11/12 rounded-xl flex flex-col gap-y-3 mt-5 self-center"
      >
        <div
          v-for="(type, i) in previewArr"
          :key="i"
          @click="showPaymentDetails(type.name)"
          class="relative bg-highlight border border-outlineGray flex flex-wrap justify-between gap-3 rounded-lg p-2 items-center"
          @mouseenter="hoverType = type.name"
          @mouseleave="hoverType = ''"
        >
          <div class="flex items-center gap-3">
            <span class="capitalize text-sm font-bold">
              <span :class="type.name !== 'pension' ? 'uppercase' : 'capitalize'"> {{ type.name }}</span>
              ({{ months[type.month] }}/{{ type.year }})</span
            >

            <button
              v-show="hoverType === type.name"
              @click="handleActions(type, 'remove')"
              style="background: rgba(239, 68, 68, 0.08)"
              class="focus:outline-none text-secondary text-sm rounded-lg p-2"
            >
              Remove
            </button>

            <button
              v-show="hoverType === type.name"
              @click="handleActions(type, 'edit')"
              style="background: rgba(17, 69, 59, 0.08)"
              class="focus:outline-none text-primary text-sm rounded-lg py-2 px-4"
            >
              Edit
            </button>
          </div>

          <div v-show="!showPaymentDropdown[type.name]" style="background-color: " class="rounded-xl p-2 rotate-45">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.5 3L10.5 8L5.5 13"
                stroke="#515251"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div
            v-show="showPaymentDropdown[type.name]"
            style="background-color: rgba(17, 69, 59, 0.08)"
            class="rounded-xl p-2 rotate-45"
          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13 5.5L8 10.5L3 5.5"
                stroke="#515251"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <hr class="border-0.5 border-dividerColor w-full" />

          <div
            v-show="selectedType == type.name && showPaymentDropdown[type.name]"
            class="w-full flex items-center gap-4"
          >
            <div class="flex flex-col bg-white items-start rounded-lg w-full gap-2 p-2">
              <p class="text-xs text-newGray">Total beneficiaries</p>
              <p class="text-base text-grey">
                {{
                  trackPaymentDetails[type.name] && trackPaymentDetails[type.name].employees
                    ? trackPaymentDetails[type.name].employees.length
                    : 0
                }}
              </p>
            </div>

            <div class="flex flex-col bg-white items-start rounded-lg w-full gap-2 p-2">
              <p class="text-xs text-newGray">Total amount to be paid</p>
              <p class="text-base text-grey">{{ formatAmount(type.total) }}</p>
            </div>
          </div>
        </div>

        <hr class="border-0.5 border-dividerColor w-full" />
      </div>

      <div class="flex flex-col gap-1">
        <easiButton
          variant="outlined"
          @click="
            () => {
              paymentStepThree = false;
              paymentStepTwo = true;
            }
          "
          class="w-11/12 my-2"
          >Add new compliance</easiButton
        >
        <easiButton :loading="loadFinalStep" @click="finalStep" class="w-11/12 my-2"
          >Proceed to make payment</easiButton
        >
      </div>
    </div>
  </easiModal> -->
  <!--Payment step three end -->

  <!--Payment step three start-->
  <easiModal
    v-if="paymentStepThree"
    :isHeader="false"
    :showBackButton="true"
    @back="
      () => {
        paymentStepThree = false;
        paymentStepTwo = true;
      }
    "
    @close="closeAll"
  >
    <div class="p-2 w-full text-center flex flex-col gap-y-2">
      <h1 class="font-medium uppercase font-bold">Pay Compliances</h1>
      <span class="text-center text-bodyDark text-xs">Kindly click compliance to edit/add staff beneficiaries</span>

      <div
        class="cursor-pointer border-outlineGray p-3 w-11/12 rounded-xl flex flex-col gap-y-3 mt-5 self-center"
        :class="isOpened ? 'border' : undefined"
      >
        <div
          v-for="(type, i) in previewArr"
          :key="i"
          @click="showPaymentDetails(type.name)"
          class="relative bg-highlight border border-outlineGray flex flex-wrap justify-between gap-3 rounded-lg p-2 items-center shadow-sm"
          @mouseenter="hoverType = type.name"
          @mouseleave="hoverType = ''"
        >
          <div class="flex items-center gap-3">
            <span class="capitalize text-sm font-bold">
              <span :class="type.name !== 'pension' ? 'uppercase' : 'capitalize'"> {{ type.name }}</span>
              ({{ months[type.month] }}/{{ type.year }})</span
            >

            <button
              v-show="hoverType === type.name"
              @click="handleActions(type, 'remove')"
              style="background: rgba(239, 68, 68, 0.08)"
              class="focus:outline-none text-secondary text-xs rounded-lg p-2"
            >
              Remove
            </button>

            <button
              v-show="hoverType === type.name"
              @click="handleActions(type, 'edit')"
              style="background: rgba(17, 69, 59, 0.08)"
              class="focus:outline-none text-primary text-xs rounded-lg py-2 px-4"
            >
              Edit
            </button>
          </div>

          <div v-show="!showPaymentDropdown[type.name]" style="background-color: " class="rounded-xl p-2 rotate-45">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.5 3L10.5 8L5.5 13"
                stroke="#515251"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div
            v-show="showPaymentDropdown[type.name]"
            style="background-color: rgba(17, 69, 59, 0.08)"
            class="rounded-xl p-2 rotate-45"
          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13 5.5L8 10.5L3 5.5"
                stroke="#515251"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <hr v-show="isOpened" class="border-0.5 border-dividerColor w-full" />

          <div
            v-show="selectedType == type.name && showPaymentDropdown[type.name]"
            class="w-full flex items-center gap-4"
          >
            <div class="flex flex-col bg-white items-start rounded-lg w-full gap-2 p-2">
              <p class="text-xs text-newGray">Total beneficiaries</p>
              <p class="text-base text-grey">
                {{
                  trackPaymentDetails[type.name] && trackPaymentDetails[type.name].employees
                    ? trackPaymentDetails[type.name].employees.length
                    : 0
                }}
              </p>
            </div>

            <div class="flex flex-col bg-white items-start rounded-lg w-full gap-2 p-2">
              <p class="text-xs text-newGray">Total amount to be paid</p>
              <p class="text-base text-grey">{{ formatAmount(type.total) }}</p>
            </div>
          </div>
        </div>
      </div>
      <hr class="border-0.5 border-dividerColor w-11/12 my-3 mx-auto" />

      <div class="flex flex-col w-11/12 self-center gap-1">
        <easiButton
          variant="outlined"
          @click="
            () => {
              paymentStepThree = false;
              paymentStepTwo = true;
            }
          "
          block
          class="w-full my-2"
          >Add new compliance</easiButton
        >
        <easiButton :loading="loadFinalStep" block @click="finalStep" class="w-full my-2"
          >Proceed to make payment</easiButton
        >
      </div>
    </div>
  </easiModal>
  <!--Payment step three end-->

  <!--Payment step four start-->
  <easiModal
    v-if="paymentStepFour"
    :isHeader="false"
    :showBackButton="true"
    @back="
      () => {
        paymentStepThree = true;
        paymentStepFour = false;
      }
    "
    @close="closeAll"
  >
    <div class="p-2 w-11/12 mx-auto text-center flex flex-col gap-y-0">
      <h1 class="font-medium text-xl uppercase font-bold">Make Payment Now</h1>

      <div>
        <PayCompliance :totalAmount="amountToBePaid" :payloadData="argsStack" @completed="closeAll" />
      </div>
    </div>
  </easiModal>
  <!--Payment step four end-->

  <div
    @click="timeSelect = false"
    :class="!timeSelect ? 'pointer-events-none' : ''"
    class="bg-transparent fixed inset-0"
  ></div>

  <easiLoading v-if="loading" />
</template>

<script setup>
import ComplianceTable from "@/components/global/ComplianceTable.vue";
import ComplianceTransactionHistory from "@/components/Compliance/ComplianceTransactionHistory.vue";
import ComplianceRegistrationHistory from "@/components/Compliance/ComplianceRegistrationHistory.vue";
import PayCompliance from "@/views/admin/compliance/PayCompliance.vue";
import Filter from "@/components/Filters/ComplianceFilter.vue";
import ComplianceSearch from "@/components/Search/ComplianceSearch.vue";
import ComplianceActions from "@/components/Compliance/ComplianceActions.vue";
import PayeComponent from "@/components/Compliance/Register/Paye.vue";
import HmoComponent from "@/components/Compliance/Register/Hmo.vue";
import PensionComponent from "@/components/Compliance/Register/Pension.vue";
import NhfComponent from "@/components/Compliance/Register/Nhf.vue";
import ItfComponent from "@/components/Compliance/Register/Itf.vue";
import NsitfComponent from "@/components/Compliance/Register/Nsitf.vue";
import MonthAndYear from "@/components/global/MonthAndYear.vue";
import { useRoute } from "vue-router";
import { ref, reactive, nextTick, watch, computed, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import payeIcon from "@/assets/icons/paye-icon.svg";
import hmoIcon from "@/assets/icons/hmo-icon.svg";
import itfIcon from "@/assets/icons/itf-icon.svg";
import nsitfIcon from "@/assets/icons/nsitf-icon.svg";
import pensionIcon from "@/assets/icons/pension-icon.svg";
import nhfIcon from "@/assets/icons/nhf-icon.svg";

import smallPaye from "@/assets/icons/paye-small-icon.svg";
import smallHmo from "@/assets/icons/hmo-small-icon.svg";
import smallPension from "@/assets/icons/pension-small-icon.svg";

import smallNhf from "@/assets/icons/smallNhf.svg";
import smallItf from "@/assets/icons/smallItf.svg";
import smallNsitf from "@/assets/icons/smallNsitf.svg";

const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees } = storeToRefs(store);
const toast = useToast();
const { formatDateString, formatAmount, breakPoints } = helperFunctions;

const route = useRoute();
const activeModal = ref("");
const hoverType = ref("");
const selectedTimeline = ref("month");
const openModal = ref(false);
const goBack = ref(false);
const showTip = ref(false);
const validEmployeeLength = ref(false);
const validTurnover = ref(false);
const ineligible = ref(false);
const checkEligibility = ref(false);
const timeSelect = ref(false);
const isSearch = ref(false);
const showStatutoryList = ref(false);
const searchText = ref("");
const transDetails = computed(() => store.getSingleComplianceTransaction);

const trackPaymentDetails = ref({});

const filterData = ref({
  to: null,
  names: null,
  from: null,
});

const employeeCompliance = computed(() => (store.getEmployeeCompliance ? store.getEmployeeCompliance : []));

const allEmployees = computed(() => getAllEmployees.value.data);

const createEmpList = () => {
  const list = allEmployees.value.map((emp) => {
    return {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
  });
  return list;
};
const transactionFilterData = ref({
  to: null,
  names: null,
  from: null,
});

const queryComplianceTransaction = async (arg) => {
  loading.value = true;
  try {
    await query({
      endpoint: "GetComplianceTransactions",
      payload: {
        input: transactionFilterData.value,
      },
      service: "PAYROLL",
      storeKey: "listComplianceTransaction",
    });
    // console.log(transDetails.value, "trans dets");
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
};

function fetchTotalAmount(name) {
  if (transDetails.value && transDetails.value.length) {
    // const obj = transDetails.value.find((tx)=>tx.name===name)
    // if(obj)return obj.totalAmountPaid
    // else return 0
    const total = transDetails.value.reduce((acc, cumm) => {
      acc += cumm.name === name ? cumm.totalAmountPaid : 0;
      return acc;
    }, 0);
    return total;
  }
  return 0;
}
onMounted(async () => {
  for (let ded of statutoryDeds.value) {
    trackPaymentDetails.value[ded.key] = { employees: [], totalAmount: 0 };
  }
  await queryComplianceTransaction();
});
function updateEmp(e, type) {
  if (type) {
    trackPaymentDetails.value[type].employees = e;
  }
}

const totalDetails = computed(() => [
  {
    type: "PAYE",
    amount: fetchTotalAmount("paye"),
    icon: smallPaye,
  },
  {
    type: "HMO",
    amount: fetchTotalAmount("hmo"),
    icon: smallHmo,
  },
  {
    type: "Pension",
    amount: fetchTotalAmount("pension"),
    icon: smallPension,
  },
  {
    type: "NSITF",
    amount: fetchTotalAmount("nsitf"),
    icon: smallNsitf,
  },
  {
    type: "NHF",
    amount: fetchTotalAmount("nhf"),
    icon: smallNhf,
  },

  {
    type: "ITF",
    amount: fetchTotalAmount("itf"),
    icon: smallItf,
  },
]);
const months = ref([
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]);
const complianceData = computed(() => {
  const data =
    employeeCompliance.value &&
    employeeCompliance.value.length &&
    employeeCompliance.value.map((comp) => {
      return {
        dateOfRegistration: comp.createdAt,
        typeOfRegistration: comp.registrationType,
        employee: comp.employee,
        employer: comp.employer,
        status: "pending",
      };
    });
  return data;
});

const visibleData = ref([]);

watch(complianceData, (val) => {
  visibleData.value = val;
});

function searchArrayObjects(arr, searchString) {
  return arr.filter((item) => {
    return item.employee.toLowerCase().includes(searchString);
  });
}

function searchEmployees(e) {
  searchText.value = e.toLowerCase();
  if (searchText.value && searchText.value !== undefined && searchText.value.length) {
    visibleData.value = complianceData.value;
    visibleData.value = searchArrayObjects(visibleData.value, searchText.value);
  } else {
    visibleData.value = complianceData.value;
  }
}

const headers = computed(() => {
  if (complianceData.value && complianceData.value.length > 0) {
    let headArr = [];
    const head = Object.keys(complianceData.value[0]);
    for (let i of head) {
      headArr.push({
        field: i,
        header: i,
      });
    }

    return headArr;
  }
});
const activeFilter = ref("transaction");
const activeRegFilter = ref("all");
const filterOpts = ref([
  {
    label: "All transaction history",
    mobileLabel: "transaction history",
    value: "transaction",
  },
  {
    label: "All registration history",
    mobileLabel: "registration history",
    value: "registration",
  },
]);

const registrationFilterOpts = ref([
  {
    label: "All",
    value: "all",
  },
  {
    label: "success",
    value: "success",
  },

  {
    label: "processing",
    value: "processing",
  },
  {
    label: "failed",
    value: "failed",
  },
]);

async function filterHistory(stat) {
  activeFilter.value = stat;
}

async function filterRegHistory(stat) {
  activeRegFilter.value = stat;
}

const currentPage = ref(0);
const pageSize = ref(15);
function closeAll() {
  openModal.value = false;
  paymentStepOne.value = false;
  paymentStepTwo.value = false;
  paymentStepThree.value = false;
  paymentStepFour.value = false;

  activeModal.value = "";
  argsStack.value = [];
  previewArr.value = [];
}

const showBreakdown = ref(true);
const breakdownType = ref("");

function handleBreakdown(type) {
  showBreakdown.value = !showBreakdown.value;
  breakdownType.value = type;
}

function openRegistrationModal(name) {
  if (name !== "itf") {
    activeModal.value = name.toLowerCase();
    openModal.value = true;
  } else {
    if (getAllEmployees.value && getAllEmployees.value.data && getAllEmployees.value.data.length < 5) {
      checkEligibility.value = true;
    } else {
      activeModal.value = name.toLowerCase();
      openModal.value = true;
    }
  }
}

const showDropdown = ref(false);
const showPaymentDropdown = ref({
  paye: false,
  pension: false,
  nhf: false,
  nsitf: false,
  itf: false,
  wht: false,
  lifeAssurance: false,
  hmo: false,
});
const selectedType = ref("");
function handleType(type) {
  selectedType.value = type;
  showDropdown.value = !showDropdown.value;
}
const isOpened = ref(false);

function showPaymentDetails(type) {
  selectedType.value = type;
  isOpened.value = !isOpened.value;

  if (showPaymentDropdown.value[type]) {
    showPaymentDropdown.value[type] = false;
  } else {
    showPaymentDropdown.value[type] = true;
  }
  // showPaymentDropdown.value[type] = !showPaymentDropdown.value[type];
}
function openItf(bool) {
  checkEligibility.value = false;
  if (bool) {
    activeModal.value = "itf";
    openModal.value = true;
  } else {
    ineligible.value = true;
    validTurnover.value = true;
  }
}

const statutoryDeds = ref([
  {
    name: "PAYE",
    style: "bg-primary border border-primary",
    icon: payeIcon,
    key: "paye",
  },

  {
    name: "HMO",
    style: "bg-primary border border-primary",
    icon: hmoIcon,
    key: "hmo",
  },
  {
    name: "Pension",
    style: "bg-primary border border-primary",
    icon: pensionIcon,
    key: "pension",
  },
  {
    name: "NSITF",
    style: "bg-primary border border-primary",
    icon: nsitfIcon,
    key: "nsitf",
  },

  {
    name: "NHF",
    style: "bg-primary border border-primary",
    icon: nhfIcon,
    key: "nhf",
  },
  {
    name: "ITF",
    style: "bg-primary border border-primary",
    icon: itfIcon,
    key: "itf",
  },
]);
const loading = ref(false);

const regDetails = computed(() => store.getSingleComplianceReg);
const queryEmployeeCompliance = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "GetComplianceRegistration",
      payload: {
        input: filterData.value,
      },
      service: "PAYROLL",
      storeKey: "listComplianceRegistration",
    });
    // console.log(regDetails.value, "rdt");

    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};
queryEmployeeCompliance();
onMounted(async () => {
  if (route.query) {
    if (route.query.action == "register") {
      openModal.value = true;
      activeModal.value = route.query.type;
    } else if (route.query.action == "pay") {
      paymentStepOne.value = true;
    }
  }
  await queryEmployeeCompliance();
});

function sortArray(arr, order) {
  let sortedArr = arr.slice(); // Make a copy of the array to avoid mutating the original
  sortedArr =
    order.toLowerCase() === "asc"
      ? sortedArr.sort((a, b) => a.employee.toLowerCase().localeCompare(b.employee.toLowerCase()))
      : sortedArr.sort((a, b) => b.employee.toLowerCase().localeCompare(a.employee.toLowerCase()));

  return sortedArr;
}

const filterByType = (firstArray, secondArray) => {
  const dateFilter = secondArray.find((obj) => obj.field === "comp.date");
  const typeFilter = secondArray.find((obj) => obj.field !== "comp.date");

  // Filtering the first array based on the criteria
  const filteredArray = firstArray.filter((item) => {
    // Filter based on date range if available

    if (dateFilter) {
      const { startDate, endDate } = dateFilter.value;
      const itemDate = new Date(item.dateOfRegistration);
      const start = new Date(`${startDate}T00:00:00.000Z`); // Appending time for ISO 8601 format
      const end = new Date(`${endDate}T23:59:59.999Z`); // Appending time for ISO 8601 format

      if (itemDate < start || itemDate > end) {
        return false; // Exclude items outside the date range
      }
    }

    // Filter based on typeOfRegistration if available
    if (typeFilter && item.typeOfRegistration !== typeFilter.value) {
      return false; // Exclude items with different typeOfRegistration
    }

    return true; // Include items that passed the filters
  });
  return filteredArray;
};
const viewEmployeeFilterRef = ref(null);

const activeFilters = ref([]);
const filterFN = async ({ filter, active }) => {
  // console.log(filter, active);
  if (active.length) {
    const isSortActive = Object.keys(filter.sort) && Object.keys(filter.sort).length ? true : false;
    activeFilters.value = active;
    if (isSortActive) {
      visibleData.value = sortArray(visibleData.value, filter.sort.firstName);
    }

    if (filter && filter.filter && filter.filter.length) {
      visibleData.value = complianceData.value;
      visibleData.value = filterByType(visibleData.value, filter.filter);
    }
  } else {
    visibleData.value = complianceData.value;
  }
};

const removeFilter = (arg) => {
  // console.log(viewEmployeeFilterRef.value);
  if (viewEmployeeFilterRef.value && viewEmployeeFilterRef.value.removeFilter) {
    viewEmployeeFilterRef.value.removeFilter(arg);
  }
};

const paymentStepOne = ref(false);
const paymentStepTwo = ref(false);
const paymentStepThree = ref(false);
const paymentStepFour = ref(false);
const updated = ref(false);
const dedsTable = {
  paye: "PAYE",
  pension: "Pension",
  nhf: "NHF",
  nsitf: "NSITF",
  itf: "ITF",
  wht: "WHT",
  lifeAssurance: "Employee_Life_Assurance",
  hmo: "HMO",
};
const staticStatutories = ref([]);
function openPaymentModal(type) {
  activeModal.value = type;
  trackPaymentDetails.value[type] = { employees: [], totalAmount: 0 };
  staticStatutories.value.push(type);
  paymentStepOne.value = true;
  breakdownType.value = type;
}
const argsStack = ref([]);
const dt = new Date();
const args = ref({
  month: dt.getMonth(),
  year: dt.getFullYear(),
  employeeIds: [],
  bonuses: [],
  deductions: [],
  timeline: "MONTHLY",
  statutories: [],
  paymentOptions: {
    salary: false,
    bonus: false,
    statutory: true,
    deduction: false,
  },
});

const previewDataForPayroll = computed(() => store.getPayrollPreview);
const amountToBePaid = ref(0);
const totalPayrollAmount = computed(() => store.getTotalPayroll);
const previewArr = ref([]);
const queryCalculatePayments = async (arg) => {
  try {
    let res = await query({
      endpoint: "CalculateMultiplePayment",
      payload: {
        input: arg,
      },
      service: "PAYROLL",
      storeKey: "totalPayroll",
    });
    amountToBePaid.value = res && typeof res.statutoryTotals === "number" ? Number(res.statutoryTotals) : 0;

    const preview = previewArr.value.map((obj) => {
      const resobj = res.statutoryDetails.find((r) => r.month === obj.month && r.year === obj.year);
      return { ...obj, total: resobj.statutoryDetails[obj.name] || 0 };
    });
    previewArr.value = preview;
  } catch (err) {
    console.log(err);
  }
};
const previewLoading = ref(false);
function deepCopyArray(originalArray) {
  // Convert the array to a JSON string and then parse it
  return JSON.parse(JSON.stringify(originalArray));
}
const queryPayroll = async () => {
  previewLoading.value = true;
  const payloadArr = argsStack.value;

  if (payloadArr.length <= 0) {
    toast.error("You have to choose the year and month to pay for.");
    return false;
  }

  try {
    loading.value = true;
    await query({
      endpoint: "PreviewPayrollMultiple",
      payload: {
        input: payloadArr,
      },
      service: "PAYROLL",
      storeKey: "previewPayroll",
    });
    // return true;
    // console.log(previewDataForPayroll.value, "preview");
    // const resData = convertResData(previewDataForPayroll.value);
    // tableData.value = resData;
    paymentStepTwo.value = false;
    paymentStepThree.value = true;
    await queryCalculatePayments(payloadArr);

    // loading.value = false;
    // if (tableData.value.length) {
    //   //   console.log(tableData.value, "FRES");
    //   //   log(tableData.value);
    //   previewModal.value = true;
    // }
  } catch (err) {
    toast.error(err.message);
    console.log(err);
  } finally {
    previewLoading.value = false;
  }
};

function handleTypeUpdate(type, key, toggle = true) {
  console.log(type, "prev-type", key);
  // let index = staticStatutories.value.indexOf(type);
  // if (index > -1) {
  //   staticStatutories.value[index] = key;
  // }
  console.log(staticStatutories.value, "ss-value");

  // staticStatutories.value.push(key);
  activeModal.value = key;
  if (toggle) showStatutoryList.value = !showStatutoryList.value;

  handleBreakdown(key);
}

const saveAndAddNew = async (done) => {
  let emps = [];
  let newlyAdded;
  let previous;
  for (const st in trackPaymentDetails.value) {
    if (trackPaymentDetails.value[st].employees.length) {
      emps.push(trackPaymentDetails.value[st].employees);
      previous = st;
      console.log(previous, "just added and has values");
    } else {
      if (!done) {
        staticStatutories.value.push(st);
        newlyAdded = st;
        break;
      }
    }
  }
  let employeeIds = [...new Set(emps.flat())];
  // const employeeIds = trackPaymentDetails.value[
  //   activeModal.value
  // ].employees.map((em) => em);
  const sameMonth = argsStack.value.find((arg) => arg.month === args.value.month && arg.year === args.value.year);
  if (sameMonth) {
    for (let item of argsStack.value) {
      if (item.month === args.value.month && item.year === args.value.year) {
        item.employeeIds = [];
        item = {
          employeeIds: item.employeeIds.push(...employeeIds.map((emp) => emp)),
          statutories: !item.statutories.includes(dedsTable[activeModal.value])
            ? item.statutories.push(dedsTable[activeModal.value])
            : item.statutories,

          ...item,
        };
      }
    }
    // console.log(argsStack.value, "new arg");
  } else {
    let obj = {
      // name: activeModal.value,
      month: args.value.month,
      year: args.value.year,
      employeeIds: employeeIds.flat(),
      bonuses: [],
      deductions: [],
      timeline: "MONTHLY",

      statutories: [dedsTable[activeModal.value]],
      paymentOptions: {
        salary: false,
        bonus: false,
        statutory: true,
        deduction: false,
      },
    };
    argsStack.value.push(obj);
  }
  const samePreviewMonth = previewArr.value.find(
    (arg) => arg.month === args.value.month && arg.year === args.value.year && arg.name === activeModal.value
  );
  if (samePreviewMonth) {
    for (let item of previewArr.value) {
      if (item.month === args.value.month && item.year === args.value.year) {
        item = {
          employeeIds:
            trackPaymentDetails.value[activeModal.value] && trackPaymentDetails.value[activeModal.value].employees,
          statutories: item.statutories.push(dedsTable[activeModal.value]),
          ...item,
        };
      }
    }
  } else {
    previewArr.value.push({
      name: activeModal.value,
      month: args.value.month,
      year: args.value.year,
      employeeIds:
        trackPaymentDetails.value[activeModal.value] && trackPaymentDetails.value[activeModal.value].employees,
      bonuses: [],
      deductions: [],
      timeline: "MONTHLY",

      statutories: [dedsTable[activeModal.value]],
      paymentOptions: {
        salary: false,
        bonus: false,
        statutory: true,
        deduction: false,
      },
    });
  }

  // trackPaymentDetails.value[activeModal.value] = {
  //   employees: [],
  //   totalAmount: 0,
  // };
  showBreakdown.value = false;
  if (done) {
    await queryPayroll();
  } else {
    handleTypeUpdate(previous, newlyAdded, false);
  }
};
function updateVal(val, typ) {
  args.value[typ] = val;
  updated.value = true;
  showDropdown.value = false;
}

function convertDateFormat(originalDate) {
  const [month, day, year] = originalDate.split("/");
  const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  return formattedDate;
}

function getStartAndEndDate(year, monthIndex) {
  // Start date (1st day of the selected month)
  const startDate = new Date(year, monthIndex, 1);
  const formattedStartDate = startDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  // End date (last day of the selected month)
  const endDate = new Date(year, monthIndex + 1, 0);
  const formattedEndDate = endDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return {
    startDate: convertDateFormat(formattedStartDate),
    endDate: convertDateFormat(formattedEndDate),
  };
}

const currentYear = new Date().getFullYear();

async function updateFilterData(val, typ) {
  if (typ === "year") {
    transactionFilterData.value.from = `${val}-01-01`;
    transactionFilterData.value.to = `${val}-12-31`;
  } else {
    let dates = {
      month: 0,
      year: currentYear,
    };
    dates[typ] = val;
    const { startDate, endDate } = getStartAndEndDate(dates.year, dates.month);
    transactionFilterData.value.from = startDate;
    transactionFilterData.value.to = endDate;
  }

  // console.log(startDate, endDate);

  timeSelect.value = false;
  await queryComplianceTransaction();
}

const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};
const updateVisibleData = () => {
  let datas = complianceData.value && complianceData.value;
  visibleData.value = datas.slice(
    currentPage.value * pageSize.value,
    currentPage.value * pageSize.value + pageSize.value
  );

  // if we have 0 visible todos, go back a page
  if (visibleData.value && visibleData.value.length == 0 && currentPage.value > 0) {
    updatePage(currentPage.value - 1);
  }
};
const actionableItem = ref("");
const handleActions = (obj, action) => {
  actionableItem.value = obj.name;
  if (action == "remove") {
    previewArr.value = previewArr.value
      .map((item) => {
        if (item.month === obj.month && item.year === obj.year) {
          if (item.name !== obj.name) return item;
        } else {
          return item;
        }
      })
      .filter((sub) => sub != undefined);

    let ind = staticStatutories.value.indexOf(obj.name);
    if (ind > -1) {
      staticStatutories.value.splice(ind, 1);
    }
    if (trackPaymentDetails.value[obj.name]) {
      trackPaymentDetails.value[obj.name].employees = [];
    }

    for (let arg of argsStack.value) {
      if (arg.month === obj.month && arg.year === arg.year) {
        let index = arg.statutories.indexOf(dedsTable[obj.name]);
        if (index > -1) {
          arg.statutories.splice(index, 1);
        }
      }
    }
  } else {
    // staticStatutories.value.push(obj.name);
    activeModal.value = obj.name;
    paymentStepThree.value = false;
    paymentStepTwo.value = true;
  }
};

const loadFinalStep = ref(false);
async function finalStep() {
  loadFinalStep.value = true;
  try {
    await queryPayroll();
    paymentStepThree.value = false;
    paymentStepFour.value = true;
  } catch (e) {
    console.log(e);
  } finally {
    loadFinalStep.value = false;
  }
}
</script>

<style scoped></style>
