<template>
  <div class="w-full flex flex-col justify-center mx-auto">
    <span class="mt-8 text-sm text-newGray capitalize"
      >Maximum loan amount eligible</span
    >
    <span class="text-lg mt-1 text-dark-800 font-bold">{{
      formatAmount(eligibleAmount.max || 0)
    }}</span>
    <!-- <span class="mt-6 text-xs text-newGray">
      Specify your company's bank account that will finance this repayment</span
    > -->
    <form
      @submit.prevent="checkArgs"
      class="md:grid flex flex-col grid-cols-2 md:items-center md:gap-y-2 gap-x-8"
    >
      <div class="">
        <easiTextInput
          placeholder="Enter amount needed"
          type="text"
          :format="true"
          v-model="argument.loanAmount"
          :max="eligibleAmount.max"
          name="amount"
          required
        ></easiTextInput>
      </div>

      <div class="">
        <easiTextInput
          placeholder="Enter invoice purpose"
          v-model="argument.purpose"
          type="text"
          name="purpose"
          required
        ></easiTextInput>
      </div>

      <!-- <span class="mt-4 col-span-2 text-xs text-newGray">
        Select Loan Purpose</span
      >

      <div class="col-span-2 grid grid-cols-4 gap-4">
        <div
          v-for="purpose in loanPurpose"
          :key="purpose.value"
          :class="
            selectedPurpose === purpose.value
              ? 'bg-primary text-white'
              : 'bg-white text-dark-800'
          "
          @click="selectedPurpose = purpose.value"
          class="border border-outlineGray cursor-pointer rounded-lg shadow-lg text-xs p-3 text-center"
        >
          {{ purpose.label }}
        </div>
      </div> -->
      <!-- <div v-if="selectedPurpose === 'OTHER'" class="col-span-2">
        <easiTextInput
          placeholder="Enter Loan Purpose"
          type="text"
          name="loan"
          required
        ></easiTextInput>
      </div> -->

      <span class="mt-7 md:mb-0 mb-2 col-span-2 text-xs text-newGray">
        Invoice repayment period</span
      >

      <div class="col-span-2 grid grid-cols-4 gap-4">
        <div
          v-for="period in loanPeriod"
          :key="period.value"
          :class="
            argument.duration === period.value.duration
              ? 'bg-primary text-white'
              : 'bg-white text-dark-800'
          "
          @click="
            () => {
              argument.duration = period.value.duration;
              argument.durationPeriod = period.value.period;
            }
          "
          class="border border-outlineGray cursor-pointer rounded-lg shadow-lg text-xs p-3 text-center"
        >
          {{ period.label }}
        </div>
      </div>

      <div v-if="argument.duration === 0" class="col-span-2">
        <easiTextInput
          placeholder="Enter number of months"
          v-model="argument.duration"
          type="number"
          name="period"
          required
        ></easiTextInput>
      </div>

      <span class="mt-7 md:mb-0 mb-2 col-span-2 text-xs text-newGray">
        Select repayment type</span
      >

      <div class="col-span-2 flex gap-4">
        <div
          v-for="typ in paymentType"
          :key="typ.value"
          :class="
            selectedType === typ.value
              ? 'bg-primary text-white'
              : 'bg-white text-dark-800'
          "
          @click="selectedType = typ.value"
          class="border border-outlineGray w-56 cursor-pointer rounded-lg shadow-lg text-xs p-3 text-center"
        >
          {{ typ.label }}
        </div>
      </div>

      <div
        class="flex items-end col-span-2 justify-end self-end gap-4 mb-4 mt-8"
      >
        <easiButton
          type="button"
          class="md:w-32 w-40"
          size="medium"
          variant="text"
          @click="$router.go(-1)"
          >Cancel</easiButton
        >
        <easiButton
          @click="$emit('back')"
          type="button"
          class="md:w-32 w-40"
          size="medium"
          variant="outlined"
          >Back</easiButton
        >
        <easiButton size="medium" class="md:w-32 w-40">Continue</easiButton>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useDataStore } from "@/stores/data.js";

import { helperFunctions } from "@/composable/helperFunctions";

const { formatAmount } = helperFunctions;
const route = useRoute();
const store = useDataStore();

const emit = defineEmits(["loan"]);
const loanArgsInStore = computed(() => store.getLoanArgs);

const selectedPurpose = ref("");
const selectedType = ref("");
const selectedPeriod = ref({});
const eligibleAmount = computed(() => store.getEligibleLoanAmount);

const argument = ref({
  type: route.params.id,
  termsAndConditionsAccepted: true,
  purpose: "",
  termsAndConditions: "",
  loanAmount: "",
  employeeIds: [],
  durationPeriod: "MONTH",
  duration: 0,
});
const loanPurpose = ref([
  { label: "Operational Expenses", value: "OPERATIONAL_EXPENSES" },
  { label: "Purchase Inventory", value: "PURCHASE_INVENTORY" },
  { label: "Business Expansion", value: "BUSINESS_EXPANSION" },
  { label: "Hire Talents", value: "HIRE_TALENTS" },
  { label: "Machinery/Equipment", value: "MACHINERY" },
  { label: "Other", value: "OTHER" },
]);
const paymentType = ref([
  { label: "Interest + Capital Monthly", value: "Interest + Capital Monthly" },
  {
    label: "Interest + Capital at Tenure End",
    value: "Interest + Capital at Tenure End",
  },
]);
const loanPeriod = ref([
  { label: "3 Month", value: { period: "MONTH", duration: 3 } },
  { label: "6 Months", value: { period: "MONTH", duration: 6 } },
  { label: "9 Months", value: { period: "MONTH", duration: 9 } },
  { label: "12 Months", value: { period: "MONTH", duration: 12 } },
  { label: "Other", value: { period: "MONTH", duration: 0 } },
]);
function updateStoreValue(obj, key) {
  store.$patch({
    createLoanArgs: {
      [key]: obj,
    },
  });
}
function checkArgs() {
  store.$patch({
    repaymentType: selectedType.value,
  });
  updateStoreValue(argument.value, "input");
  emit("loan", argument.value);
}

onMounted(() => {
  if (loanArgsInStore.value && loanArgsInStore.value.input) {
    argument.value = Object.assign(argument.value, loanArgsInStore.value.input);
  }
});
</script>

<style scoped>
.custom-date-input {
  display: inline-block;
  position: relative;
}

.custom-date-input input[type="text"] {
  padding: 5px;
  width: 150px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.custom-date-input::before {
  content: attr(placeholder);
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #999;
}
</style>
