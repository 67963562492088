<template>
  <easiRoundButton @click="handleGoBack" class="absolute z-20 top-4 left-4">
    <i class="pi pi-chevron-left text-sm" />
  </easiRoundButton>
  <h4
    class="text-center font-semibold pb-4 pt-6 w-full absolute top-0 left-0 bg-white"
  >
    Make payment
  </h4>
  <div class="p-4 md:p-6 flex flex-col gap-5 h-full overflow-auto mt-20">
    <h2 class="font-semibold md:text-lg text-dark900">
      How do you wish to make your loan payment?
    </h2>

    <easiTab dark :tabItems="tabs" v-model:active="activeTab" />

    <div class="flex flex-col gap-5">
      <keep-alive>
        <component
          @submit="submit"
          @fundWallet="fundWalletModal = true"
          :loan="loan"
          :is="display"
        />
      </keep-alive>
    </div>
  </div>

  <Teleport to="#loan-success-alert">
    <easiAlert v-if="isAlert" :caption="alertMessage">
      <div class="pb-4 px-6">
        <easiButton
          size="medium"
          variant="text"
          background="#11453B1A"
          @click="closeAlert"
          block
          >Dismiss!</easiButton
        >
      </div>
    </easiAlert>

    <easiLoading v-if="loading" />

    <Topup
      v-if="fundWalletModal"
      @close="closeFundWallet"
      :action="closeFundWallet"
      title="Payment will reflect soon as the money is received"
      btnText="Back to pay loan"
      runQuery
    />
  </Teleport>
</template>

<script setup>
import ByInstallment from "./ByInstallment.vue";
import ByBalance from "./ByBalance.vue";
import Topup from "@/components/global/TopupModal";

import { payLoan } from "@/services/loan";
import { ref, reactive, computed } from "vue";
import { useDataStore } from "@/stores/data";
import { useToast } from "vue-toastification";

const toast = useToast();
const store = useDataStore();
const emit = defineEmits(["close", "goBack"]);

const isAlert = ref(false);
const alertMessage = ref(
  "Great job! You have cleared this month’s loan instalment. Keep up the good work!"
);

const loan = computed(() => store.getCurrentLoan);

const fundWalletModal = ref(false);
const loading = ref(false);

const display = computed(() => {
  const component = {
    "Pay by Instalment": ByInstallment,
    "Pay loan Balance": ByBalance,
  };
  return component[activeTab.value] || component["Pay by Instalment"];
});

const activeTab = ref("Pay by Instalment");
const tabs = reactive(["Pay by Instalment", "Pay loan Balance"]);

function handleGoBack() {
  emit("goBack");
}

function closeAlert() {
  isAlert.value = false;
  emit("close");
}

function closeFundWallet() {
  fundWalletModal.value = false;
}

const submit = async (payload) => {
  try {
    loading.value = true;

    const res = await payLoan(payload);

    if (res.success && res.data) {
      //check if it's the last installment the change the alert message
      const loanRepaid =
        res.datares.data.installmentSummary.totalUnpaidInstallments == 0;

      if (loanRepaid) {
        alertMessage.value = `Congratulations! You have cleared your outstanding loan.`;
      }

      isAlert.value = true;
      handleClose();
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    console.log(error.messate);
  } finally {
    loading.value = false;
  }
};
</script>

<style></style>
