<template>
  <Sidebar
    v-model:visible="toggle"
    :dismissable="false"
    position="right"
    class="relative w-full sm:max-w-md"
  >
    <template #container>
      <easiRoundButton
        class="absolute top-4 right-4 z-20"
        @click="handleClose"
      />

      <div v-if="!startApplication" class="h-full overflow-auto no-scrollbar">
        <EligibilityCriteria v-if="showEligilityCriteria" />
        <PayLoan v-if="showPayloan" @goBack="showPayloan = false" />

        <div v-if="!showEligilityCriteria && !showPayloan">
          <div
            style="box-shadow: 0px 9px 18px 0px #aaaaaa26"
            class="p-4 md:p-6 flex flex-col gap-5 absolute top-0 z-10 left-0 w-full bg-white"
          >
            <h4 class="text-center font-semibold mb-5">{{ title }}</h4>

            <LoanAmountCard
              plain
              title="Loan amount <br/> payable"
              :value="activeLoan && activeLoan.amountPayable"
            />

            <easiButton v-if="!activeLoan" block @click="handleTakeLoan"
              >Take Loan</easiButton
            >
            <LoanProgress
              v-else
              :length="activeLoan.installment.summary.totalInstallmentMonths"
              :progress="activeLoan.installment.summary.totalInstallmentsPaid"
              showInfo
              label="Payment progress"
            />
          </div>

          <div
            :class="[activeLoan ? 'mt-64' : 'mt-60']"
            class="p-4 md:p-6 flex flex-col gap-5 font-medium"
          >
            <NextRepaymentCard
              v-if="nextInstallmentBreakdown"
              :amount="nextInstallmentBreakdown.amountDue"
              :dueDate="nextInstallmentBreakdown.dueDate"
              @click="showPayloan = true"
            />
            <h4>Loan breakdown</h4>

            <div v-if="activeLoan" class="flex flex-col gap-4">
              <BreakdownCard
                v-for="(item, index) in installments"
                :nextLoanInstallment="nextInstallmentBreakdown"
                :key="item"
                :data="item"
                :showLine="index !== installments.length - 1"
              />
            </div>

            <easiEmptyState
              v-else
              title="No Breakdown!"
              caption="You have not taken any loan yet. Click the button above to get a quick loan."
            />
          </div>
        </div>
      </div>

      <!-- ////// Start Application /////// -->
      <div v-else class="w-full h-full overflow-auto no-scrollbar">
        <Operational
          v-if="loanType == 'Operational_Loans'"
          @goBack="startApplication = false"
          @close="handleClose"
          :eligibilityData="eligibilityData"
        />
        <Payroll
          v-if="loanType == 'Payroll_Cover'"
          @goBack="startApplication = false"
          @close="handleClose"
          :eligibilityData="eligibilityData"
        />
        <Business
          v-if="loanType == 'Small_Business_Loan'"
          @goBack="startApplication = false"
          @close="handleClose"
          :eligibilityData="eligibilityData"
        />
      </div>
    </template>
  </Sidebar>

  <easiAlert
    v-if="isAlert"
    noIcon
    title="<h1 class='text-5xl mb-2'>😔</h1> <h3>Oops</h3>"
    :caption="`You have an ongoing loan and  can’t <br/> request for a new one yet.`"
  >
    <div class="pb-4 px-6 -mt-2">
      <easiButton
        size="medium"
        variant="text"
        background="#11453B1A"
        @click="isAlert = false"
        block
        >Dismiss!</easiButton
      >
    </div>
  </easiAlert>

  <easiRoundLoader v-if="loading" text="Confirming <br/> Eligibility " />
</template>

<script setup>
import LoanAmountCard from "./LoanAmountCard.vue";
import EligibilityCriteria from "./EligibilityCriteria.vue";
import LoanProgress from "./LoanProgress.vue";
import BreakdownCard from "./BreakdownCard.vue";
import NextRepaymentCard from "./NextRepaymentCard.vue";
import PayLoan from "./PayLoan/Index.vue";

import Operational from "./Operational/Index.vue";
import Payroll from "./Payroll/Index.vue";
import Business from "./Business/Index.vue";

import { checkEligibility } from "@/services/loan";

import { useRoute } from "vue-router";
import { ref, computed } from "vue";
import { useDataStore } from "@/stores/data";

const route = useRoute();
const store = useDataStore();

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["close"]);

const toggle = computed({
  get() {
    return props.isOpen;
  },
  set(val) {
    emit("close");
  },
});

const activeLoan = computed(() => {
  const loan = store.getCurrentLoan;
  return loan &&
    loan.status === "APPROVED" &&
    loan.type === loanType.value.toUpperCase()
    ? loan
    : null;
});

const installments = computed(() =>
  activeLoan.value ? activeLoan.value.installment.docs : []
);

const nextInstallmentBreakdown = computed(() => {
  if (!activeLoan.value) return null;
  const nextInstallmentDate =
    activeLoan.value.installment.summary.nextInstallmentDate;

  return installments.value.find((el) => el.dueDate === nextInstallmentDate);
});

const loanType = computed(() => route.query.type || "Title");
const title = computed(() => {
  return loanType.value.split("_").join(" ");
});

const showPayloan = ref(false);
const startApplication = ref(false);
const showEligilityCriteria = ref(false);
const eligibilityData = ref();

const isAlert = ref(false);
const loading = ref(false);

const handleClose = () => {
  startApplication.value = false;
  showEligilityCriteria.value = false;
  showPayloan.value = false;
  emit("close");
};

const handleTakeLoan = async () => {
  if (store.getCurrentLoan) {
    isAlert.value = true;
    return;
  }

  try {
    loading.value = true;
    const type = loanType.value.toUpperCase();
    const res = await checkEligibility(type);

    if (res.success && res.data) {
      eligibilityData.value = res.data;
      startApplication.value = true;
    } else {
      showEligilityCriteria.value = true;
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};
</script>

<style></style>
