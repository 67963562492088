<template>
  <main class="px-0 md:px-4 py-4">
    <div
      class="flex flex-row-reverse items-center justify-between sm:flex-col sm:items-start mb-6 sm:mb-0"
    >
      <h4
        class="md:mt-3 md:text-sm md:text-left text-xs font-medium text-dark-600 sm:text-headerText capitalize sm:uppercase"
      >
        QUICK LOANS / APPLICATION / {{ headerObj[$route.params.id] }}
      </h4>
      <div class="text-left sm:mt-4 sm:mb-6">
        <easiBackButton :link="'LoanApplication'"></easiBackButton>
      </div>
    </div>

    <div
      class="w-full border border-outlineGray rounded-2xl md:px-auto flex justify-center flex-col items-center px-4 md:px-32 py-6 bg-white"
    >
      <h3 class="text-dark-800 font-bold md:text-center text-lg">
        {{ headerObj[$route.params.id] }}
      </h3>

      <AssetLoan
        v-if="$route.params.id === 'ASSET_PURCHASING_LOAN'"
        @done="previewLoan"
      />
      <OperationalLoan
        @done="previewLoan"
        v-if="$route.params.id === 'OPERATIONAL_LOANS'"
      />
      <SmallBusiness
        @done="previewLoan"
        v-if="$route.params.id === 'SMALL_BUSINESS_LOAN'"
      />
      <InvoiceLoan
        @done="previewLoan"
        v-if="$route.params.id === 'INVOICE_FINANCING'"
      />
      <PayrollCoverLoan
        @done="previewLoan"
        v-if="$route.params.id === 'PAYROLL_COVER'"
      />
    </div>
    <!-- <easiRoundLoader v-if="loading.add" /> -->

    <easiModal
      v-if="summary"
      @close="summary = false"
      :isHeader="false"
      :isBorder="false"
      rounded="3xl"
    >
      <h1
        class="capitalize text-center text-dark-800 text-xl font-bold pt-6 pb-4"
      >
        {{ headerObj[$route.params.id] }} details
      </h1>

      <div class="px-4 py-6 flex flex-col gap-4 md:gap-6">
        <div class="flex items-center gap-4">
          <span class="w-10 h-10">
            <img
              v-if="
                companyData && companyData.company && companyData.company.logo
              "
              :src="
                companyData && companyData.company && companyData.company.logo
              "
              alt=""
              class="object-cover w-10 h-10 rounded-full"
            />

            <img
              v-else
              src="@/assets/img/c-logo.png"
              class="object-cover object-center w-10 h-10"
              alt=""
            />
          </span>
          <span class="font-bold text-grey text-sm md:text-base">{{
            companyData &&
            companyData.company &&
            companyData.company.companyName
          }}</span>
        </div>

        <div class="flex items-center gap-x-5">
          <div
            class="w-full flex flex-col gap-2 text-left text-newGray text-sm"
          >
            <span class="font-medium text-xs md:text-sm">
              Total Loan Requested</span
            >
            <span
              class="bg-lightGreyBg rounded-md p-3 text-xs md:text-sm text-grey font-bold"
            >
              {{ formatAmount(createdLoanDetails.loanAmountApplied || 0) }}
            </span>
          </div>
          <div
            class="flex flex-col gap-2 items-start text-left text-newGray text-sm w-full"
          >
            <span class="font-medium text-right text-xs md:text-sm">
              Interest Rate</span
            >
            <div
              class="bg-lightGreyBg rounded-md w-11/12 p-3 flex justify-between"
            >
              <span class="text-xs md:text-sm font-bold">
                {{
                  getInterest(
                    createdLoanDetails.loanAmountApplied,
                    createdLoanDetails.totalRepaymentAmount
                  )
                }}%
              </span>
            </div>
          </div>

          <div
            class="w-full flex flex-col gap-2 text-left text-newGray text-sm"
          >
            <span class="font-medium text-xs md:text-sm"
              >Total amount payable</span
            >
            <span
              class="bg-lightGreyBg rounded-md p-3 text-xs md:text-sm text-grey font-bold"
            >
              {{ formatAmount(createdLoanDetails.totalRepaymentAmount || 0) }}
            </span>
          </div>
        </div>

        <div
          class="bg-white w-36 border border-outlineGray flex flex-col gap-y-2 rounded-lg p-3"
        >
          <span class="text-newGray text-xs">Repayment duration</span>
          <span class="text-dark-800 font-bold text-xs"
            >{{ createdLoanDetails.duration }} Months</span
          >
        </div>

        <div
          v-if="createdLoanDetails && createdLoanDetails.repaymentBreakDown"
          class="flex items-center w-full justify-between"
        >
          <span class="text-left text-sm font-bold">Repayment Breakdown</span>
          <div>
            <Paginator
              :data="createdLoanDetails.repaymentBreakDown"
              @page:update="updatePage"
              :currentPage="currentPage"
              :pageSize="pageSize"
            />
          </div>
        </div>
        <div
          v-if="createdLoanDetails && createdLoanDetails.repaymentBreakDown"
          class="rounded-2xl border border-outlineGray p-4 flex md:flex-row flex-col gap-y-4 items-center gap-x-4"
        >
          <div
            v-for="payment in visibleData"
            :key="payment.dueDate"
            class="bg-lightGreyBg p-3 rounded-2xl w-full"
          >
            <span class="text-newGray font-bold text-sm capitalize"
              >{{ getPositionString(getIndex(payment.dueDate)) }} payment</span
            >
            <div class="flex flex-col text-newGray py-2">
              <div
                class="flex items-center justify-between text-sm border-b border-outlineGray py-2"
              >
                <span class="flex items-center gap-2">
                  <i class="fas fa-circle text-xs text-newGray"></i>
                  <span class="capitalize text-sm">Interest</span>
                </span>

                <span class="text-xs">
                  {{ formatAmount(payment.intrest || 0) }}
                </span>
              </div>

              <div
                class="flex items-center justify-between text-sm border-b border-outlineGray py-2"
              >
                <span class="flex items-center gap-2">
                  <i class="fas fa-circle text-xs text-newGray"></i>
                  <span class="capitalize text-sm">Total amount due</span>
                </span>

                <span class="text-xs">
                  {{ formatAmount(payment.totalAmount || 0) }}
                </span>
              </div>

              <div
                class="flex items-center justify-between text-sm border-b border-outlineGray py-2"
              >
                <span class="flex items-center gap-2">
                  <i class="fas fa-circle text-xs text-newGray"></i>
                  <span class="capitalize text-sm">Due date</span>
                </span>

                <span class="text-xs"
                  >{{ formatDateString(payment.dueDate, "name") }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <easiButton :disabled="!acceptTerms" @click="createLoan" block>
          <span class="text-sm">Take this loan</span>
        </easiButton>
        <div class="cursor-pointer flex items-center justify-center gap-2">
          <div
            @click="queryLoanContract"
            class="cursor-pointer gap-2 flex items-center"
          >
            <img
              src="@/assets/icons/loanMenu.svg"
              class="w-4 cursor-pointer"
              alt=""
            />
            <span class="text-info-light text-xs italic cursor-pointer"
              >Accept Loan Terms and Condition (view contract)</span
            >
          </div>
          <span class="">
            <img
              v-if="!acceptTerms"
              @click="acceptTerms = !acceptTerms"
              class="w-4 h-4"
              src="@/assets/icons/check3-null.svg"
            />
            <img
              v-else
              @click="acceptTerms = !acceptTerms"
              class="w-4 h-4"
              src="@/assets/icons/check3-active.svg"
            />
          </span>
        </div>

        <!-- <div
          class="px-4 sm:px-10 lg:px-6 xl:px-8 mb-4 flex flex-col gap-4 md:gap-6"
        >
          <p class="text-center text-base font-bold">Repayment Breakdown</p>

          <div class="grid grid-cols-6 gap-4">
            <div
              class="flex flex-col text-newGray col-span-6 md:col-span-3 gap-2 py-2"
            >
              <div
                class="flex items-center justify-between text-sm border-b border-outlineGray py-2"
              >
                <span class="flex items-center gap-2">
                  <i class="fas fa-circle text-newGray"></i>
                  <span class="capitalize">Interest</span>
                </span>

                <span>
                  {{ formatAmount(0) }}
                </span>
              </div>

              <div
                class="flex items-center justify-between text-sm border-b border-outlineGray py-2"
              >
                <span class="flex items-center gap-2">
                  <i class="fas fa-circle text-newGray"></i>
                  <span class="capitalize">Total amount due</span>
                </span>

                <span>
                  {{ formatAmount(0) }}
                </span>
              </div>

              <div
                class="flex items-center justify-between text-sm border-b border-outlineGray py-2"
              >
                <span class="flex items-center gap-2">
                  <i class="fas fa-circle text-newGray"></i>
                  <span class="capitalize">Due date</span>
                </span>

                <span> </span>
              </div>
            </div>
          </div>
          <div class="cursor-pointer flex items-center justify-center gap-2">
            <img
              src="@/assets/icons/loanMenu.svg"
              class="w-4 cursor-pointer"
              alt=""
            />
            <span class="text-info-light cursor-pointer">Loan Contract</span>
          </div>
          <easiButton block>
            <span class="text-sm">Take this loan</span>
          </easiButton>
        </div> -->
      </div>
    </easiModal>

    <easiModal :compliance="true" v-show="preview" @close="preview = false">
      <div
        style="height: calc(100vh - 250px)"
        class="w-full flex flex-col items-center h-auto overflow-y-scoll"
      >
        <iframe
          ref="pdfPreview"
          :src="pdfSrc"
          style="width: 98%; height: 100%"
        ></iframe>
      </div>
      <div class="w-full mt-8 grid grid-cols-2 gap-4 px-4 items-center">
        <easiButton
          @click="preview = false"
          :block="true"
          class="w-full"
          variant="outlined"
          >Cancel</easiButton
        >
        <easiButton
          @click="downloadBase64AsPDF(contract.base64, contract.fileName)"
          :block="true"
          class="w-full"
          >Download</easiButton
        >
      </div>
    </easiModal>
    <easiRoundLoader v-if="loading" />

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Application successful</span>
      </template>

      <template v-slot:action>
        <easiButton @click="$router.push({ name: 'ManageLoans' })"
          >Manage Loans</easiButton
        >
      </template>
    </easiSuccess>
  </main>
</template>

<script setup>
import { ref, computed, reactive } from "vue";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";

import AssetLoan from "@/components/loan/AssetLoan.vue";
import OperationalLoan from "@/components/loan/OperationalLoan.vue";
import SmallBusiness from "@/components/loan/SmallBusiness.vue";
import InvoiceLoan from "@/components/loan/InvoiceLoan.vue";
import PayrollCoverLoan from "@/components/loan/PayrollCoverLoan.vue";
import Paginator from "@/components/global/LoanPaginator.vue";

const store = useDataStore();
const toast = useToast();
const route = useRoute();

const { getAllEmployees } = storeToRefs(store);
const { mutate, query } = store;
const allEmployees = computed(() =>
  getAllEmployees.value ? getAllEmployees.value.data : []
);
const loanArgsInStore = computed(() => store.getLoanArgs);

const companyData = computed(() => store.getCompanyDetails);
const { formatAmount, formatDateString } = helperFunctions;

const summary = ref(false);
const acceptTerms = ref(false);
const loading = ref(false);
const updateSuccess = ref(false);

const contract = computed(
  () => store.getLoanContract && store.getLoanContract.data
);

const headerObj = ref({
  ASSET_PURCHASING_LOAN: "Asset Purchasing Loan",
  OPERATIONAL_LOANS: "Operational Loan",
  SMALL_BUSINESS_LOAN: "Small Business Loan",
  INVOICE_FINANCING: "Invoicing Financing Loan",
  PAYROLL_COVER: "Payroll Cover Loan",
});

function getPositionString(index) {
  const positions = [
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
    "eleventh",
    "twelfth",
  ];

  if (index >= 0 && index < positions.length) {
    return positions[index];
  } else {
    return `${index + 1}th`; // Fallback for higher indices
  }
}

function reviver(key, value) {
  const exc = [
    "phoneNumber",
    "accountNumber",
    "bankCode",
    "userName",
    "modelNo",
    "inflow",
    "taxId",
    "providerPin",
    "duration",
    "durationPeriod",
    "endDate",
    "bvn",
    "employeePensionPin",
  ];
  // Check if the value is a string that represents a number
  if (
    !exc.includes(key) &&
    typeof value === "string" &&
    /^\d+(\.\d+)?$/.test(value)
  ) {
    // Convert the string to a number
    return Number(value);
  }
  // Return the original value
  return value;
}
const contractArgs = ref({
  type: "",
  termsAndConditionsAccepted: true,
  purpose: "",
  termsAndConditions: "",
  loanAmount: 0,
  employeeIds: [],
  durationPeriod: "",
  duration: 0,
});
const createdLoanDetails = ref({});
const currentPage = ref(0);
const pageSize = ref(2);
const visibleData = ref([]);

function getIndex(dt) {
  const index = createdLoanDetails.value.repaymentBreakDown.findIndex(
    (item) => item.dueDate === dt
  );

  if (index !== -1) {
    return index;
  } else {
    console.log("Object not found in the array.");
  }
}
const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData(createdLoanDetails.value.repaymentBreakDown);
};
const updateVisibleData = (data) => {
  let datas = data;
  visibleData.value = datas.slice(
    currentPage.value * pageSize.value,
    currentPage.value * pageSize.value + pageSize.value
  );

  if (visibleData.value.length == 0 && currentPage.value > 0) {
    updatePage(currentPage.value - 1);
  }
};
const loanId = ref(null);
const repaymentType = computed(() => store.getRepaymentType);

async function createLoan() {
  if (acceptTerms.value == false) {
    toast.error("Accept terms and conditions to continue.");
    return;
  }
  let payload = Object.assign({}, loanArgsInStore.value);
  payload.input.type = route.params.id;

  loading.value = true;

  try {
    if (
      route.params.id === "INVOICE_FINANCING" ||
      route.params.id === "OPERATIONAL_LOANS"
    ) {
      payload.paymentDetails.repaymentType = repaymentType.value;
      // delete payload.paymentDetails;
      delete payload.guarantorInformation;
      delete payload.assetInformation;
    }

    if (
      route.params.id === "PAYROLL_COVER" ||
      route.params.id === "SMALL_BUSINESS_LOAN"
    ) {
      delete payload.assetInformation;
    }

    const payloadData = JSON.parse(JSON.stringify(payload), reviver);
    contractArgs.value = payloadData.input;
    let res = await mutate({
      endpoint: "CreateLoan",
      data: payloadData,
      service: "LOAN",
    });

    if (res) {
      summary.value = false;
      updateSuccess.value = true;
      createdLoanDetails.value = res;
      loanId.value = res._id;
      updateVisibleData(createdLoanDetails.value.repaymentBreakDown);
    }
  } catch (e) {
    toast.error(e.message);
  } finally {
    loading.value = false;
  }
}
function getInterest(principal, amountToBeRepaid) {
  const interest = amountToBeRepaid - principal;

  // Calculate the interest percentage
  const interestPercentage = (interest / principal) * 100;
  return interestPercentage.toFixed(1);
}
const previewLoan = async () => {
  try {
    loading.value = true;
    let payload = Object.assign({}, loanArgsInStore.value);
    payload.input.type = route.params.id;
    const payloadData = JSON.parse(JSON.stringify(payload), reviver);
    contractArgs.value = payloadData.input;

    loading.value = true;

    let res = await mutate({
      endpoint: "PreviewLoan",
      data: {
        input: contractArgs.value,
      },
      service: "LOAN",
    });
    if (res) {
      console.log(res);
      createdLoanDetails.value = res;
      // loanId.value = res._id;
      updateVisibleData(createdLoanDetails.value.repaymentBreakDown);
    }

    summary.value = true;
  } catch (e) {
    console.log(e.message);
    toast.error(e.message);
  } finally {
    loading.value = false;
  }
};

const pdfSrc = ref("");
const preview = ref(false);
const pdfPreview = ref(null);
function downloadBase64AsPDF(base64Data, fileName) {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: "application/pdf" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;

  // Handle Safari-specific behavior
  if (navigator.userAgent.includes("Safari")) {
    link.target = "_blank";
    // Open the PDF in a new window if download is not supported
  }

  link.click();
}

function previewPDF(base64Data) {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: "application/pdf" });
  const url = URL.createObjectURL(blob);
  pdfSrc.value = url;
  preview.value = true;
}
async function queryLoanContract() {
  loading.value = true;

  try {
    let res = await query({
      endpoint: "LoanContract",
      payload: {
        loanId: null,
        loanInput: contractArgs.value,
      },
      service: "LOAN",
      storeKey: "loanContract",
    });
    if (res && res.success) {
      previewPDF(res.data.base64);
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
}
</script>

<style></style>
